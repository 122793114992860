import React, { useRef, useState } from 'react';
import { GetMenuBar } from './MenubarComponent';
import LeftMenu from './LeftMenu';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from './AWS/UserPool';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const ChangePassword = () => {
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const history = useHistory();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const [error, setError] = useState('');

  function handleChangePassword(e) {
    e.preventDefault();
    const cognitoUser = UserPool.getCurrentUser();
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    } else {
      setError(null);
      const authenticationDetails = new AuthenticationDetails({
        Username: cognitoUser.username,
        Password: oldPassword,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          cognitoUser.changePassword(oldPassword, newPassword, function (err, result) {
            if (err) {
              const errorMessage = err.message.split(': ')[1];
              setError(errorMessage);
            } else {
              toast.success('Password Changed Successfully!');
              history.push('/resource-maker');
            }
          });
        },
        onFailure: function (err) {
          setError(err.message);
        },
      });
    }
  }

  return (
    <div>
      <Row>
        <Col xs lg='5'>
          <h4 className='text-center mb-4 title'>Change your password</h4>

          <Form onSubmit={handleChangePassword}>
            <Form.Group as={Row}>
              <Col sm='12'>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={showOldPassword ? 'text' : 'password'}
                    placeholder='Old Password'
                    ref={oldPasswordRef}
                    required
                    className='me-2'
                  />{' '}
                  <span
                    onClick={toggleShowOldPassword}
                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                  >
                    {showOldPassword ? <EyeSlashFill /> : <EyeFill />}
                  </span>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm='12' className='mt-3'>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder='New Password'
                    ref={newPasswordRef}
                    required
                    className='me-2 mt-3'
                  />
                  <span
                    onClick={toggleShowNewPassword}
                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                  >
                    {showNewPassword ? <EyeSlashFill /> : <EyeFill />}
                  </span>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm='12' className='mt-3'>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder='Confirm Password'
                    ref={confirmPasswordRef}
                    required
                    className='me-2 mt-3'
                  />
                  <span
                    onClick={toggleShowConfirmPassword}
                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                  >
                    {showConfirmPassword ? <EyeSlashFill /> : <EyeFill />}
                  </span>
                </div>
              </Col>
            </Form.Group>
            {error && <p className='text-danger mb-2 mt-1'>{error}</p>}
            <div className='sign-button d-flex mt-3 justify-content-end'>
              <Button className='w-100' type='submit'>
                RESET PASSWORD
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
