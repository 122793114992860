import { Collapse, Table, Spin } from "antd";
import axios from "axios";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { GetLoginCredentialsAsJsonObject } from "../../components/UserCredentials";
import {
  GetAwsResourceIconPath,
  GetEc2ScheduleInfoAndRecommendationsUrl,
} from "../../routes";
import { AutoScalingGroupRecommendationInfoFormat } from "./RecommedationTypes/AutoScalingGroupRecommendationInfo";
import { ECSServiceRecommendationsInfoFormat } from "./RecommedationTypes/ECSServiceRecommendationsInfo";
import { EbsVolumeRecommendationInfoFormat } from "./RecommedationTypes/EbsVolumeRecommendationInfo";
import { Ec2InstancesRecommendationInfoFormat } from "./RecommedationTypes/Ec2InstancesRecommendationInfo";
import { LambdaFunctionRecommendationInfoFormat } from "./RecommedationTypes/LambdaFunctionRecommendationInfo";
import { recommedations_test_data } from "./test_data";

const getRowClassName = (record, index) => {
  return index % 2 === 0 ? "ant-even-row" : "ant-odd-row";
};

export const aws_icon_url = GetAwsResourceIconPath("cloud/aws/aws.png");
const test_data = recommedations_test_data;

const recommedationsKeyMap = [
  {
    key: "Ec2InstancesRecommendationInfo",
    tableDataToShow: Ec2InstancesRecommendationInfoFormat,
    iconPath: GetAwsResourceIconPath(
      "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2_64.svg"
    ),
  },
  {
    key: "EbsVolumeRecommendationInfo",
    tableDataToShow: EbsVolumeRecommendationInfoFormat,
    iconPath: GetAwsResourceIconPath(
      "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Elastic-Block-Store_64.svg"
    ),
  },
  {
    key: "ECSServiceRecommendationsInfo",
    tableDataToShow: ECSServiceRecommendationsInfoFormat,
    iconPath: GetAwsResourceIconPath(
      "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Containers/64/Arch_Amazon-Elastic-Container-Service_64.svg"
    ),
  },
  {
    key: "AutoScalingGroupRecommendationInfo",
    tableDataToShow: AutoScalingGroupRecommendationInfoFormat,
    iconPath: GetAwsResourceIconPath(
      "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_Amazon-EC2-Auto-Scaling_64.svg"
    ),
  },
  {
    key: "LambdaFunctionRecommendationInfo",
    tableDataToShow: LambdaFunctionRecommendationInfoFormat,
    iconPath: GetAwsResourceIconPath(
      "cloud/aws/Asset-Package_07302021/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64.png"
    ),
  },
];

const Recommendations = ({ data, keyMap }) => {
  if (!keyMap || !Array.isArray(data)) {
    return null; // Handle invalid input gracefully
  }

  const columns = keyMap.tableDataToShow.map(
    ({
      column,
      key,
      subTableData,
      renderMethod,
      dataProcessFn = (i) => i,
      width,
    }) => ({
      title: column,
      dataIndex: key,
      key: key,
      width: width || "auto",
      className: subTableData ? "ant-subtable-column" : undefined,
      render: renderMethod
        ? renderMethod
        : (text, record) => {
            if (subTableData) {
              return (
                <div className="recommedations-container">
                  <Table
                    className="ant-nested-table"
                    dataSource={record[key]}
                    bordered
                    pagination={false}
                    scroll={{ x: "100%" }}
                  >
                    {subTableData.map(
                      ({
                        column: subColumn,
                        key: subKey,
                        width: subTblWidth,
                        renderMethod: subTableRenderMethod,
                        dataProcessFn: subDataProcessFn = (i) => i,
                      }) => (
                        <Table.Column
                          key={subColumn}
                          title={subColumn}
                          dataIndex={subKey}
                          // className="ant-nested-table"
                          render={
                            subTableRenderMethod
                              ? subTableRenderMethod
                              : (t, r) => {
                                  return (
                                    <div
                                      style={{
                                        maxWidth: subTblWidth || "unset",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {dataProcessFn(
                                        subDataProcessFn(get(r, subKey))
                                      ) || "N/A"}
                                    </div>
                                  );
                                }
                          }
                        />
                      )
                    )}
                  </Table>
                </div>
              );
            }
            return (
              <div
                style={{ maxWidth: width || "unset", textOverflow: "ellipsis" }}
              >
                {dataProcessFn(get(record, key) || "N/A")}
              </div>
            );
          },
    })
  );

  console.log({ data, columns });

  return (
    <div className="recommedations-container">
      <Table
        size="small"
        rowClassName={getRowClassName}
        className="ant-recommedations-table"
        dataSource={data}
        columns={columns}
        bordered
        pagination={false}
        scroll={{ x: "100%" }}
      />
    </div>
  );
};

const use_test_data = false;

const RecommedationsContainer = ({ recommedationData }) => {
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getScheduleInfoAndRecommendations = async () => {
    setIsLoading(true);
    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), //GetLoginCredentials(),
      query: {},
      command_to_execute: "get_ec2_recos",
      command_args: {
        required_recos: ["aws_compute_recommendations"],
      },
    };

    const url = GetEc2ScheduleInfoAndRecommendationsUrl();
    try {
      const resp = await axios.post(url, payload);
      console.log(resp);
      setScheduleInfo(resp.data);
      return resp;
    } catch (err) {
      console.log("schedulerTest01Handler to " + url + " failed");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (recommedationData) return setScheduleInfo(recommedationData);
    if (use_test_data) return setScheduleInfo(test_data);
    getScheduleInfoAndRecommendations();
  }, []);

  function groupByAccountAndRegion(data) {
    console.log({ data });
    if (!data) return {};
    const groupedData = {};
    console.log({ data });
    data?.forEach((item) => {
      const accountId = item.AwsComputeOptimizerRecommendations.AccountId;
      const regionId = item.AwsComputeOptimizerRecommendations.AwsRegion;

      if (!groupedData[accountId]) {
        groupedData[accountId] = {};
      }

      if (!groupedData[accountId][regionId]) {
        groupedData[accountId][regionId] = {};
      }

      groupedData[accountId][regionId] =
        item.AwsComputeOptimizerRecommendations;
    });

    return groupedData;
  }

  const recommendationsKeysToShow = recommedationsKeyMap.filter(
    ({ key }) => true
  );

  const groupedData = groupByAccountAndRegion(
    scheduleInfo.aws_compute_optimizer_recommendations_from_s3
  );
  const accountIds = Object.keys(groupedData);

  console.log({ groupedData, accountIds });
  return (
    <div>
      {isLoading ? (
        <Spin tip="Loading recommendations..." />
      ) : accountIds.length ? (
        <Collapse>
          {accountIds.map((accountId) => (
            <Collapse.Panel
              header={
                <div className="recommendations-title-container">
                  <div className="parent-accordion">
                    <div className="leaf-node-icon">
                      <img
                        style={{ height: "3.2vw" }}
                        src={aws_icon_url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div>Account : {accountId}</div>
                  <div></div>
                </div>
              }
              key={accountId}
            >
              <Collapse>
                {Object.keys(groupedData[accountId]).map((regionId) => (
                  <Collapse.Panel
                    header={
                      <div className="recommendations-title-container">
                        <div className="parent-accordion">
                          <div className="leaf-node-icon">
                            <img
                              style={{ height: "3.2vw" }}
                              src={aws_icon_url}
                              alt=""
                            />
                          </div>
                        </div>
                        <div>Region : {regionId}</div>
                        <div></div>
                      </div>
                    }
                    key={regionId}
                  >
                    <Collapse>
                      {recommendationsKeysToShow.map((eachKey, i) => {
                        const processedData = get(
                          groupedData[accountId][regionId],
                          `${eachKey.key}.Recommendations`
                        );
                        const processedTitle = (
                          <div className="recommendations-title-container">
                            <div className="parent-accordion">
                              <div className="leaf-node-icon">
                                <img src={eachKey.iconPath} alt="" />
                              </div>
                            </div>
                            <div>
                              {eachKey.key}{" "}
                              {processedData?.length
                                ? ""
                                : "(No Recommendations To Show)"}
                            </div>
                            <div></div>
                          </div>
                        );
                        return (
                          <Collapse.Panel
                            header={processedTitle}
                            // showArrow={Boolean(processedData?.length)}
                            disabled={!Boolean(processedData?.length)}
                            key={i.toString()}
                          >
                            <div>
                              <Recommendations
                                data={processedData}
                                keyMap={eachKey}
                              />
                            </div>
                          </Collapse.Panel>
                        );
                      })}
                    </Collapse>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        "Getting recommendations data"
      )}
    </div>
  );
};
export default RecommedationsContainer;
