import React, { useEffect, useState } from "react";
import Filters from "../Filters/Filters";
import Dashboard from "./Dashboard/Dashboard";
import ListView from "./ListView/ListView";

const Compliance = () => {
  const [currentView, setCurrentView] = useState("dashboard");
  const [listViewData, setListViewData] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedCompliance, setSelectedCompliance] = useState(null);
  const [selectedScanId, setSelectedScanId] = useState(null);

  const handleRenderListView = (status, selectedData, scanId) => {
    setListViewData({ status, selectedData });
    setSelectedScanId(scanId);
    setCurrentView("listView");
  };

  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
    setCurrentView("dashboard");
  };

  const handleBackToDashboard = () => {
    console.log("Back button clicked. Navigating to Dashboard.");
    setCurrentView("dashboard");
    setListViewData(null);
    setSelectedCompliance(null)
    setSelectedScanId(null);
  };

  const renderContent = () => {
    switch (currentView) {
      case "dashboard":
        return (
          <Dashboard
            selectedCompliance={selectedCompliance}
            setSelectedCompliance={setSelectedCompliance}
            filters={selectedFilters}
            onFindingsClick={handleRenderListView}
          />
        );
      case "listView":
        return (
          <ListView
            selectedCompliance={selectedCompliance}
            data={listViewData}
            onBack={handleBackToDashboard}
            filters={selectedFilters}
            selectedScanId={selectedScanId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Filters onFilterChange={handleFilterChange} />
      <div>{renderContent()}</div>
    </div>
  );
};

export default Compliance;
