import { convertToFixedDigitsOrReturnNA } from '../../../utils/intHelper';

const getInstanceId = (arn) => arn.match(/instance\/([a-zA-Z0-9_-]+)/)?.[1];

const getInstanceInfo = (record) => (
  <div>
    <div>
      <strong>ID:</strong> {getInstanceId(record.InstanceArn)}
    </div>
    <div>
      <strong>Name :</strong> {record.InstanceName}{' '}
    </div>
  </div>
);

export const Ec2InstancesRecommendationInfoFormat = [
  { column: 'Instance Name/ID', key: '', renderMethod: getInstanceInfo, width: 200 },
  { column: 'Instance Name', key: 'InstanceName' },
  // { column: 'Instance State', key: 'InstanceState' },
  { column: 'Current Instance Type', key: 'CurrentInstanceType' },
  { column: 'Current Utilization Status', key: 'Finding' },
  { column: 'Lookback Period(In Days)', key: 'LookbackPeriodInDays' },
  {
    column: 'Suggested Recommendations',
    onHeaderCell: () => ({
      style: { textAlign: 'center' },
    }),
    key: 'RecommendationOptions',
    subTableData: [
      { column: 'Instance Type', key: 'InstanceType' },
      { column: 'Migration Effort', key: 'MigrationEffort' },
      // { column: 'Rank', key: 'Rank' },
      {
        column: 'Estd Savings(IN USD)',
        key: 'SavingsOpportunityAfterDiscounts.EstimatedMonthlySavings.Value',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
      {
        column: 'Estd Savings(IN %)',
        key: 'SavingsOpportunityAfterDiscounts.SavingsOpportunityPercentage',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
      { column: 'Platform Differences', key: 'PlatformDifferences', dataProcessFn: (i) => i?.join(','), width: 150 },
    ],
  },
];
