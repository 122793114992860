import React, { useEffect, useState } from "react";
import axios from "axios";
import FindingCards from "../FindingCards/FindingCards";
import "../../../../styles/SecurityAdvisorDashboard.css";
import SeverityCards from "../SeverityCards/SeverityCards";
import FindingPieChart from "../FindingsPieChart/FindingsPieChard";
import StackedBarChart from "../StackedBarChart/StackedBarChart";
import { Row, Col, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GetSecurityScanUrl } from "../../../../routes";
import {
  GetCognitoUserTokenCredentials,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../../../../components/UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../../../../components/CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../../../../components/Login";
import Cards from "../Cards/Cards";

const Dashboard = ({ onFindingsClick, filters }) => {
  const [scanHistory, setScanHistory] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [hasHistory, setHasHistory] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedScan, setSelectedScan] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);

  const handleRenderFindingsList = (status, severity) => {
    console.log("status", status);
    console.log("Clicked severity", severity);
    console.log("Dashboard data before navigation:", dashboardData);
    if (onFindingsClick) {
      onFindingsClick(status, severity, dashboardData);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.replace(" ", "T"));
    return new Intl.DateTimeFormat("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    }).format(date);
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds?.displayName,
      email: userCreds?.email,
      phoneNumber: userCreds?.phoneNumber,
      photoURL: userCreds?.photoURL,
      providerId: userCreds?.providerId,
      uid: userCreds?.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getSecurityScanHistoryPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_security_scan_history",
      command_args: {
        customerAwsIdList: [filters["AWS Account"]],
        AccountId: filters["AWS Account"],
        ScanStartTimeStamp: filters.ScanStartTimeStamp,
        EndStartTimeStamp: filters.EndStartTimeStamp,
      },
    };
  };

  //1st

  const handleGetSecurityScanHistory = async (event) => {
    setLoading(true);
    const url = GetSecurityScanUrl();
    const payload = getSecurityScanHistoryPayload();
    console.log("payload", payload);
    try {
      const resp = await axios.post(url, payload);
      console.log(resp);
      setScanHistory(resp.data.scan_history || []);
      setHasHistory(resp.data.scan_history.length > 0);
      console.log("scan history", scanHistory);
      if (resp.status === 200) {
        console.log("history", resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
      setScanHistory([]);
      setHasHistory(false);
    } finally {
      setLoading(false);
    }
  };

  //2nd
  const getSecurityScanSummaryPayload = (scanHistoryInfo) => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_security_scan_summary",
      command_args: {
        AccountId: scanHistoryInfo.account_id,
        ExpectedScanId: scanHistoryInfo.scan_id,
      },
    };
  };

  const handleGetSecurityScanSummary = async (scanHistoryInfo) => {
    const url = GetSecurityScanUrl();
    const payload = getSecurityScanSummaryPayload(scanHistoryInfo);

    try {
      const resp = await axios.post(url, payload);
      console.log(resp);
      if (resp.status === 200) {
        console.log(resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    }
  };

  const getDashboardData = async (scanHistoryInfo) => {
    setLoading(true);
    try {
      // const res = await handleGetSecurityScanHistory();
      // if (
      //   !res ||
      //   !res.data ||
      //   !res.data.scan_history ||
      //   res.data.scan_history.length === 0
      // ) {
      //   setHasHistory(false);
      // } else {
      //   setHasHistory(true);
      // }

      const dashboardResp = await handleGetSecurityScanSummary(scanHistoryInfo);

      setDashboardData({
        ...dashboardResp.data,
        scan_id: scanHistoryInfo.scan_id,
      });
      console.log("info", scanHistoryInfo);
      setShowDashboard(true);
      setHasHistory(true);
    } catch (error) {
      console.error("Error", error);
      setHasHistory(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Effect ran");
    handleGetSecurityScanHistory();
  }, [filters]);

  const handleCardClick = (scanHistoryInfo) => {
    setSelectedScan(scanHistoryInfo);
    getDashboardData(scanHistoryInfo);
  };

  const handleDashboardBackButtonClick = () => {
    setShowDashboard(false);
  };

  const findingCardsData = [
    {
      title: "Total Failed Findings",
      content: dashboardData.required_scanid_summary?.Failed,
      status: "FAIL",
      backgroundColor: "#a94839",
    },
    {
      title: "Total Passed Findings",
      content: dashboardData.required_scanid_summary?.Passed,
      status: "PASS",
      backgroundColor: "#469485",
    },
    {
      title: "Total Allowed Findings",
      content: dashboardData.required_scanid_summary?.Manual,
      status: "MANUAL",
      backgroundColor: "#5b7ea1",
    },
  ];

  const severityCardsData = [
    {
      title: "Critical",
      header: "Failed Findings",
      content: dashboardData.required_scanid_summary?.CriticalSeverity,
      severity: "critical",
      backgroundColor: "linear-gradient(to right, #7d2e30, #a9534b)",
    },
    {
      title: "High",
      header: "Failed Findings",
      content: dashboardData.required_scanid_summary?.HighSeverity,
      severity: "high",
      backgroundColor: "linear-gradient(to right, #d2675c, #b44d42)",
    },
    {
      title: "Medium",
      header: "Failed Findings",
      content: dashboardData.required_scanid_summary?.MediumSeverity,
      severity: "medium",
      backgroundColor: "linear-gradient(to right, #a06254, #8c4d40)",
    },
    {
      title: "Low",
      header: "Failed Findings",
      content: dashboardData.required_scanid_summary?.LowSeverity,
      severity: "low",
      backgroundColor: "linear-gradient(to right, #d48b62, #c77b4d)",
    },
  ];

  const pieChartsData = [
    {
      series: [
        dashboardData.required_scanid_summary?.Passed,
        dashboardData.required_scanid_summary?.Failed,
      ],
      labels: ["Passed", "Failed"],
      title: "Overall Status by Result",
      colors: ["#209e65", "#b40034"],
    },
    {
      series: [
        dashboardData.required_scanid_summary?.CriticalSeverity,
        dashboardData.required_scanid_summary?.HighSeverity,
        dashboardData.required_scanid_summary?.MediumSeverity,
        dashboardData.required_scanid_summary?.LowSeverity,
      ],
      labels: ["Critical", "High", "Medium", "Low"],
      title: "Count of Failed Findings by Severity",
      colors: ["#9d4a88", "#ca6281", "#a24965", "#8c50ac"],
    },
  ];

  const processFirstChartData = (data) => {
    if (!data) return {};

    const services = data.map((item) => item.ServiceName);
    const passed = data.map((item) => item.Passed);
    const failed = data.map((item) => item.Failed);

    return {
      title: "Services with More Failed Resources",
      series: [
        { name: "Passed", data: passed },
        { name: "Failed", data: failed },
      ],
      categories: services,
    };
  };

  const firstChartData = processFirstChartData(
    dashboardData.service_wise_summary || []
  );

  const processSecondChartData = (data) => {
    if (!data) return {};

    const services = data.map((item) => item.Region);
    const passed = data.map((item) => item.Passed);
    const failed = data.map((item) => item.Failed);

    return {
      title: "Services with More Failed Resources",
      series: [
        { name: "Passed", data: passed },
        { name: "Failed", data: failed },
      ],
      categories: services,
    };
  };

  const secondChartData = processSecondChartData(
    dashboardData.region_wise_summary || []
  );

  const trendsSummary = dashboardData.trends_summary || [];

  const stackedSeries = [
    {
      name: "Passed",
      data: trendsSummary.map((item) => item.TotalPassed),
      group: "status",
    },
    {
      name: "Failed",
      data: trendsSummary.map((item) => item.TotalFailed),
      group: "status",
    },
    {
      name: "Critical",
      data: trendsSummary.map((item) => item.TotalCritical),
      group: "severity",
    },
    {
      name: "High",
      data: trendsSummary.map((item) => item.TotalHigh),
      group: "severity",
    },
    {
      name: "Medium",
      data: trendsSummary.map((item) => item.TotalMedium),
      group: "severity",
    },
    {
      name: "Low",
      data: trendsSummary.map((item) => item.TotalLow),
      group: "severity",
    },
  ];
  const categories = trendsSummary.map((item) =>
    new Date(item.Date).toLocaleDateString()
  );

  return (
    <>
      {!showDashboard ? (
        <div className="scan-history">
          {loading ? (
            <div className="loading">Loading...</div>
          ) : scanHistory.length === 0 ? (
            <div className="noHistory">
              <h4>No Scan History found</h4>
            </div>
          ) : (
            scanHistory.map((history, index) => (
              <Cards
                key={index}
                title={formatTimestamp(history.scan_timestamp)}
                content={history.scan_id}
                button="View"
                onClick={() => handleCardClick(history)}
              />
            ))
          )}
        </div>
      ) : (
        <div className="dashboard-container">
          <Button
            className="back-btn"
            onClick={handleDashboardBackButtonClick}
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>
          {loading ? (
            <div className="loading">Loading...</div>
          ) : (
            <>
              <Row gutter={[14, 14]} className="row">
                <Col span={12} className="col">
                  <div className="finding-cards">
                    {findingCardsData.map((data, index) => (
                      <FindingCards
                        key={index}
                        title={data.title}
                        content={data.content}
                        backgroundColor={data.backgroundColor}
                        onClick={() => handleRenderFindingsList(data.status)}
                      />
                    ))}
                  </div>
                </Col>
                <Col span={12} className="col">
                  <div className="severity-cards">
                    {severityCardsData.map((data, index) => (
                      <SeverityCards
                        key={index}
                        title={data.title}
                        header={data.header}
                        content={data.content}
                        backgroundColor={data.backgroundColor}
                        onClick={() =>
                          handleRenderFindingsList(data.status, data.severity)
                        }
                      />
                    ))}
                  </div>
                </Col>
              </Row>
              <Row gutter={[14, 14]}>
                <Col span={12} className="col">
                  <div className="piechart">
                    {pieChartsData.map((data, index) => (
                      <FindingPieChart
                        key={0}
                        series={data.series}
                        labels={data.labels}
                        title={data.title}
                        colors={data.colors}
                      />
                    ))}
                  </div>
                </Col>
                <Col span={12} className="col">
                  <div className="chart">
                    <StackedBarChart
                      key={1}
                      series={firstChartData.series}
                      categories={firstChartData.categories}
                      title={firstChartData.title}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={[14, 14]}>
                <Col span={12} className="col">
                  <div className="chart">
                    <StackedBarChart
                      key={2}
                      series={secondChartData.series}
                      categories={secondChartData.categories}
                      title={secondChartData.title}
                    />
                  </div>
                </Col>
                <Col span={12} className="col">
                  <div className="chart">
                    <StackedBarChart
                      key={3}
                      series={stackedSeries}
                      title={"Trend Wise Summary"}
                      categories={categories}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
