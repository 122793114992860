import React from "react";
import { Button, Card } from "antd";
import "../../../../styles/Card.css";

const Cards = ({ title, content, button, onClick }) => {
  return (
    <Card className="scan-history-card">
      <div className="card-header">
        <div className="card-content">
          <div className="card-title">{title}</div>
          <div className="content">{content}</div>
        </div>
        <Button onClick={onClick} className="card-button">
          {button}
        </Button>
      </div>
    </Card>
  );
};

export default Cards;
