import { Table, Button } from 'antd';
import React from 'react';
import { GetLoginCredentialsAsJsonObject } from '../../../../components/UserCredentials';
import { getPayload } from '../../../../components/ProductConfiguration/Schedules/Schedules';
import axios from 'axios';
import { GetProductConfigurationSchedulesUrl } from '../../../../routes';
import { toast } from 'react-toastify';
import { CHATBOT_RESULT_TYPES } from '../../../../newComponents/Chatbot/Chatbot';

const ScheduleConfirmation = ({ data, type, successCallback = () => {}, errorCallback = () => {} }) => {
  // type can be create/update/delete
  const url = GetProductConfigurationSchedulesUrl();

  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const schedule_table_columns = daysOfWeek.map((day) => ({
    title: day,
    dataIndex: day.toLowerCase(),
    key: day.toLowerCase(),
  }));

  const {
    name_of_schedule,
    schedule_valid_from,
    schedule_valid_till,
    time_zone,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
    sun,
    name_of_schedules_list,
  } = data;

  const schedule_table_data = [
    {
      mon,
      tue,
      wed,
      thu,
      fri,
      sat,
      sun,
    },
  ];
  const tableData = [
    { field: 'Schedule Name', value: name_of_schedule },
    { field: 'Valid From', value: schedule_valid_from },
    { field: 'Valid Till', value: schedule_valid_till },
    { field: 'Time Zone', value: time_zone },
  ];

  let componentToRender;

  switch (type) {
    case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_CREATE:
      componentToRender = (
        <>
          <h6>
            Take a moment to review the schedule details.
            <br />
            Are you sure want to create it?
          </h6>
          <Table size='small' indentSize={1} showHeader={false} columns={columns} dataSource={tableData} pagination={false} />
          <Table
            style={{ marginTop: '2vw' }}
            size='small'
            indentSize={1}
            showHeader={true}
            columns={schedule_table_columns}
            dataSource={schedule_table_data}
            pagination={false}
          />
        </>
      );
      break;

    case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_DELETE:
      componentToRender = (
        <>
          <h6>Are you sure want to delete the schedules {data?.name_of_schedules_list?.join(',')}?</h6>
        </>
      );
      break;

    default:
      break;
  }

  const handleCreate = async () => {
    const payload = getPayload(GetLoginCredentialsAsJsonObject(), 'create_daily_schedule_info', {
      schedule_id: name_of_schedule,
      timezone: time_zone,
      validFrom: schedule_valid_from,
      validTill: schedule_valid_till,
      schedule_timings: {
        ...schedule_table_data[0],
      },
      created_by: 'CONCERTO_USER',
    });

    const resp = await axios.post(url, payload);
    if (resp.status === 200) {
      toast.success('Schedule created successfully');
      return successCallback();
    } else {
      toast.error('Schedule creation failed');
      return errorCallback();
    }
  };

  const handleDelete = async () => {
    const payload = getPayload(GetLoginCredentialsAsJsonObject(), 'delete_daily_schedule_info', {
      schedule_id: name_of_schedules_list[0],
    });

    const resp = await axios.post(url, payload);
    if (resp.status === 200) {
      toast.success('Schedule deleted successfully');
      return successCallback();
    } else {
      toast.error('Schedule deletion failed');
      return errorCallback();
    }
  };

  const onConfirm = () => {
    console.log({ type });
    switch (type) {
      case 'concerto_schedule_create':
        handleCreate();
        break;
      case 'concerto_schedule_delete':
        handleDelete();
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '1vw' }}>
      {componentToRender}
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button style={{ marginRight: '10px' }} onClick={errorCallback}>
          Cancel
        </Button>
        <Button type='primary' onClick={onConfirm}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default ScheduleConfirmation;
