import React from "react";
import { Button, Card } from "antd";
import "../../../../styles/FindingCards.css";

const FindingCards = ({ title, content,backgroundColor, onClick }) => {
  return (
    <Card className="finding-card" style={{ backgroundColor }} onClick={onClick}>
      <h6 className="finding-card-title">{title}</h6>
      <p className="card-content">{content}</p>
    </Card>
  );
};

export default FindingCards;
