import { convertToFixedDigitsOrReturnNA } from '../../../utils/intHelper';

const getFunctionName = (functionArn) => functionArn.match(/function:([^:]+)/)?.[1];

export const LambdaFunctionRecommendationInfoFormat = [
  { column: 'Function Name', key: 'FunctionArn', dataProcessFn: getFunctionName },
  { column: 'Current Memory Size', key: 'CurrentMemorySize' },
  { column: 'Current Performance Risk', key: 'CurrentPerformanceRisk' },
  { column: 'Current Utilization Status', key: 'Finding' },
  { column: 'Current Utilization Reasons', key: 'FindingReasonCodes', dataProcessFn: (i) => i?.join(',') },
  { column: 'Lookback Period(In Days)', key: 'LookbackPeriodInDays' },
  {
    column: 'Suggested Recommendations',
    key: 'MemorySizeRecommendationOptions',
    subTableData: [
      { column: 'Memory Size', key: 'MemorySize' },
      {
        column: 'Estd Savings(IN USD)',
        key: 'SavingsOpportunityAfterDiscounts.EstimatedMonthlySavings.Value',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
      {
        column: 'Estd Savings(IN %)',
        key: 'SavingsOpportunityAfterDiscounts.SavingsOpportunityPercentage',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
    ],
  },
];
