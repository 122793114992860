import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ContextBar from './components/ContextBar';
import './styles/ResourceMaker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { GetState, Subscribe } from './components/statemanagement/store';
import { CONTEXT_BAR_CLOSE_BUTTON_CLICKED, RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS } from './components/statemanagement/ActionsList';

import AccordionTest from './components/NestedAccordion/ResourceBrowser';

const ResourceBrowserWrapper = () => {
  const [showContextBar, setShowContextBar] = useState(false);
  const [expandContextBar, setExpandContextBar] = useState(false);

  const onStoreStateChanged_G = () => {
    let state = GetState();

    if (state.currentAction.type === RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS) {
      setShowContextBar(Math.random());
    } else if (state.currentAction.type === CONTEXT_BAR_CLOSE_BUTTON_CLICKED) {
      setShowContextBar(false);
    }
  };
  const unsubscribe = Subscribe(onStoreStateChanged_G);

  function getCloudResourceBrowser() {
    return (
      <div>
        <div className='resource-page d-flex'>
          <div className='canvas'>
            <AccordionTest />
          </div>
          <div className={`sidebar context ${showContextBar ? 'open' : ''} ${showContextBar && expandContextBar ? 'expanded' : ''}`}>
            <ContextBar expanded={expandContextBar} toggleExpandView={() => setExpandContextBar((p) => !p)} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='outlet'>{getCloudResourceBrowser()}</div>
    </div>
  );
};

export default ResourceBrowserWrapper;
