import React from 'react';
import ApexCharts from 'react-apexcharts';
import '../../../../styles/BarChat.css';

const StackedBarChart = ({ series, categories, title }) => {
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      height: '100%',
    },
    colors: [  "#209e65", "#b40034", "#9d4a88", "#ca6281", "#a24965", "#8c50ac"],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      title: {
        text: 'Categories',
      },
      labels: {
        style: {
          fontSize: '12px',
          colors: '#6c757d',
        },
        offsetY: 0,
      },
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'Values',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
    title: {
      text: title,
      align: 'center',
      margin: 20,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#263238',
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: '#e7e7e7',
    },
  };

  return (
    <div className='chart-container'>
      <ApexCharts options={options} series={series} type='bar' height={310} />
    </div>
  );
};

export default StackedBarChart;
