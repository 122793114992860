import React, { useState, useEffect } from 'react';
import './TypingAnimation.css'; // Import your CSS file for styling

const TypingAnimation = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='typing-animation'>
      <div className='wave-container'>
        <div className={`wave-dot dot1 ${dots.length >= 1 ? 'show' : ''}`}></div>
        <div className={`wave-dot dot2 ${dots.length >= 2 ? 'show' : ''}`}></div>
        <div className={`wave-dot dot3 ${dots.length >= 3 ? 'show' : ''}`}></div>
      </div>
    </div>
  );
};

export default TypingAnimation;
