import React from "react";
import { Table, Collapse } from "antd";
import "../../../styles/ChatBotHelp.css";

const { Panel } = Collapse;

const columns = [
  {
    title: "Command ID",
    dataIndex: "command_id",
    key: "command_id",
  },
  {
    title: "Usage Type",
    dataIndex: "usage_type",
    key: "usage_type",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",

  },
  {
    title: "Command Example",
    dataIndex: "command_example",
    key: "command_example",
    render: (examples) => (
      <ol>
        {examples.map((example, index) => (
          <li key={index}>{example}</li>
        ))}
      </ol>
    ),
    width: 600, 
  },
];

const ChatbotHelp = ({ data }) => {
  const groupedData = Object.values(data).reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
    return acc;
  }, {});
  return (
    <div className="chatBot-parent" style={{ position: "relative" }}>
      <Collapse bordered={true} className={"parent-accordion"}>
        {Object.keys(groupedData).map((category) => {
          return (
            <Collapse.Panel
              header={
                <div className="accordion-node-title-container">
                  <div>
                    <span>{category}</span>
                  </div>
                </div>
              }
              forceRender={true}
              className={`accordion-title leafNodeHead`}
              bordered={false}
              style={{
                position: "relative",
              }}
            >
              <div
                style={{ margin: "1vw", marginTop: 0, position: "relative" }}
                className="chatBot-table securityViolationsTable"
              >
                <Table
                  pagination={false}
                  dataSource={groupedData[category]}
                  columns={columns}
                  rowKey="command_id"
                />
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default ChatbotHelp;
