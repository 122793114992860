import React from 'react';
import ReactDOM from 'react-dom';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import App from './App';

posthog.init(process.env.REACT_APP_POSTHOG_API_KEY,
    {api_host:'https://us.i.posthog.com', person_profiles: 'always'})

ReactDOM.render(
    <PostHogProvider client={posthog}>
        <App />
    </PostHogProvider>,
  document.getElementById('root')
);

//
//