import { Button, Space, Table, Tag } from 'antd';
import axios from 'axios';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetLoginCredentialsAsJsonObject } from '../../../components/UserCredentials';
import { GetAiParamsUrl, GetExplainAiRecosUrl } from '../../../routes';
import CPUUsageChart from '../UsagePredictionGraph';

export const getAiParams = async ({ accountId, region, instanceId }) => {
  const url = GetAiParamsUrl();
  let i1 = {
    accountId,
    region,
    instanceId,
  };

  const payload = {
    concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
    command_to_execute: 'get_ec2_ai_params',
    command_args: {
      instances_list: [i1],
    },
  };

  return await axios.post(url, payload);
};
const ScheduleRecommendations = ({
  scheduleRecommendationData,
  updatedScheduleRecommendationData,
  setUpdatedScheduleRecommendationData,
  handleSaveSchedule,
}) => {
  const [explainMode, setExplainMode] = useState(false);
  const [explainData, setExplainData] = useState({});
  const [timezone, setTimezone] = useState('Asia/Kolkata');

  useEffect(() => {
    getTimezone();
  }, []);

  const { account_id: accountId, region, instance_id: instanceId } = scheduleRecommendationData?.[0] || {};

  const getTimezone = () => {
    getAiParams({
      accountId,
      instanceId,
      region,
    }).then((aiParamsData) => {
      const timeZone = aiParamsData?.data?.results?.[instanceId]?.timeZone;
      setTimezone(timeZone);
    });
  };

  console.log({ scheduleRecommendationData });
  const columns = [
    {
      title: 'Start TIme',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (date) => moment.utc(date).tz(timezone).format('YYYY-MM-DD hh:mm A'),
    },
    {
      title: 'End TIme',
      dataIndex: 'end_time',
      key: 'end_time',
      render: (date) => moment.utc(date).tz(timezone).format('YYYY-MM-DD hh:mm A'),
    },
    {
      title: 'Actions',
      dataIndex: 'approval_status',
      key: 'approval_status',
      render: (action, record) => {
        const startTime = moment.tz(record.start_time, timezone);
        const endTime = moment.tz(record.end_time, timezone);
        const currentTime = moment.tz(timezone);

        if (currentTime.isAfter(endTime) || currentTime.add(30, 'minutes').isAfter(startTime)) {
          return (
            <Tag color='red' style={{ cursor: 'not-allowed' }}>
              Expired
            </Tag>
          );
        }

        return action === 'approved' ? (
          <Button type='primary' style={{ backgroundColor: '#52c41a', borderColor: '#52c41a', color: 'white' }} disabled>
            Approved
          </Button>
        ) : action === 'rejected' ? (
          <Button type='danger' style={{ backgroundColor: '#ff4d4f', borderColor: '#eb2f96', color: 'white' }} disabled>
            Rejected
          </Button>
        ) : (
          <Space>
            <Button type='primary' onClick={() => handleApproveReject(record, 'approved')}>
              Approve
            </Button>
            <Button type='danger' onClick={() => handleApproveReject(record, 'rejected')}>
              Reject
            </Button>
          </Space>
        );
      },
    },
  ];

  const handleApproveReject = ({ account_id, end_time, instance_id, start_time }, action) => {
    const startTime = moment(start_time);
    const currentTime = moment();

    if (startTime.diff(currentTime, 'minutes') >= 16) {
      const processedData = updatedScheduleRecommendationData.map((item) =>
        item.start_time === start_time && item.end_time === end_time && item.instance_id === instance_id && item.account_id === account_id
          ? { ...item, approval_status: action }
          : item
      );

      setUpdatedScheduleRecommendationData(processedData);
    } else {
      toast.error('Approval not allowed. Time difference is less than 16 minutes.');
    }
  };

  const handleCancel = () => {
    setUpdatedScheduleRecommendationData(scheduleRecommendationData);
  };

  const handleExplain = async () => {
    if (explainMode) {
      setExplainMode(false);
      return;
    }
    const url = GetExplainAiRecosUrl();

    let args = {
      accountId,
      region,
      instanceId,
    };

    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), //GetLoginCredentials(),
      query: {},
      command_to_execute: 'explain_ec2_ai_reco_data',
      command_args: args,
    };

    try {
      const resp = await axios.post(url, payload);
      console.log('csv', resp.data);
      setExplainData(resp.data);
      setExplainMode((p) => !p);

      //   return resp
    } catch (err) {
      console.log('handleExplain to ' + url + ' failed');
    }
  };

  return (
    <div style={{ padding: '1vw' }} className='securityViolationsTable'>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1vw auto' }}>
        <div>
          <div>
            Account ID: <strong>{scheduleRecommendationData[0]?.account_id}</strong>
          </div>
          <div>
            Instance ID: <strong>{scheduleRecommendationData[0]?.instance_id}</strong>
          </div>
          <div>
            Region: <strong>{scheduleRecommendationData[0]?.region}</strong>
          </div>
        </div>
        <div>
          <Button type='outlined' onClick={handleExplain}>
            {!explainMode ? 'Explain' : 'Close'}
          </Button>
        </div>
      </div>

      {!explainMode ? (
        <>
          <Table
            rowClassName={(record, index) => (index % 2 === 0 ? '' : 'bg-blue-grad')}
            pagination={false}
            dataSource={updatedScheduleRecommendationData}
            columns={columns}
          />
          {!isEqual(updatedScheduleRecommendationData, scheduleRecommendationData) ? (
            <div style={{ marginTop: '1vw', display: 'flex', gap: '1vw' }}>
              <Button type='primary' onClick={handleSaveSchedule}>
                Save
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <CPUUsageChart encodedData={explainData} />
        </>
      )}
    </div>
  );
};

export default ScheduleRecommendations;
