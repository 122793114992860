// https://registry.terraform.io/providers/hashicorp/aws/latest/docs/resources/instance

import { getCurrentActiveContextBarNodeInfo } from "../ContextBarHandlerBuilder";
import {
  BaseContextBarBuilder,
  GetSimpleKeyPairValueElement,
} from "./ContextBarBuilder";
import { Collapse, Divider, Input, Select } from "antd";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { Form as AntForm } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ConfirmationModalContext } from "../../context/ConfirmationModalContext";
import {
  GetAiParamsUrl,
  GetAssetInfoUrl,
  GetLatestResourcesInfoUrl,
  GetProductConfigurationSchedulesUrl,
  GetSetResourceParamsUrl,
} from "../../routes";
import { GetState } from "../statemanagement/store";
import axios from "axios";
import {
  GetCognitoUserTokenCredentials,
  GetLoginCredentialsAsJsonObject,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../UserCredentials";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../Login";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../CognitoUserPoolSignin";
import { EC2_INSTANCE_RESOURCE_INFO_SHOW_CONTEXT } from "../statemanagement/ResourceContextSetter";
import { startCase } from "lodash";
import { useRef } from "react";
import { GetGlobalResourceInfoCache } from "../NestedAccordion/ResourceBrowser";
import LineChart from "../CostManager/linechart.d3";
import EditableDynamicForm from "../../newComponents/EditableDynamicForm/EditableDynamicForm";
import { WEEKDAYS_MAP } from "../../CommonConsts";
import {
  GetAllSchedulesForEc2ResourceHelper,
  UpdateSchedulesForEc2ResourceHelper,
} from "./Ec2SchedulesApiCalls";
import FlowDiagram from "./FlowDiagram";
import moment from "moment";
import { getAiParams } from "../../pages/Scheduler/Tabs/ScheduleRecommendations";
import { toast } from "react-toastify";
const { Option } = Select;

const UPDATE_EC2_ASSET_INFO_COMMAND = "update_ec2_asset_info";
const GET_EC2_ASSET_PERFORMANCE_INFO = "get_ec2_asset_performance_info";

const getAllTimezones = () => {
  const timezones = moment.tz.names();
  return timezones;
};

export const Ec2ContextShowInfoBarHandler = () => {
  let resourceProperties = ["VpcId", "InstanceType", "ReservationId"];

  const displayFields = [];
  const title = "EC2 Instance";

  for (let i = 0; i < resourceProperties.length; i++) {
    let labelStr = resourceProperties[i];
    let keyStr = resourceProperties[i];
    displayFields.push(GetSimpleKeyPairValueElement(labelStr, keyStr));
  }

  return BaseContextBarBuilder(title, displayFields);
};

const getPayload = (userCredentials, commandToExecute, commmandArgs) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: commandToExecute,
    command_args: commmandArgs,
  };
};

export const getAllSchedules = async () => {
  const url = GetProductConfigurationSchedulesUrl();
  const payload = getPayload(
    GetLoginCredentialsAsJsonObject(),
    "read_daily_schedule_info",
    {}
  );
  const resp = await axios.post(url, payload);
  if (resp.status === 200) {
    return resp?.data?.daily_schedules_list || [];
  }
  return [];
};

export const Ec2ContextActionBarHandler = (
  latestResourceData,
  getLatestResourceData
) => {
  const { openModal } = useContext(ConfirmationModalContext);
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();

  const instanceId = getInstanceId();
  const { imgPath, ownedByRegion, nodeType, ownedByAccountId } =
    currentContextBarNodeInfo.node_data || {};

  const latestData =
    latestResourceData?.latestResourcesInfoList?.[0]?.resource_info_data?.[0] ||
    {};

  const [scheduleList, setScheduleList] = useState([]);
  const [startSchedule, setStartSchedule] = useState();
  const [shutdownSchedule, setShutdownSchedule] = useState();

  const [editStartSchedule, setEditStartSchedule] = useState(false);
  const [editShutDownSchedule, setEditShutDownSchedule] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [aiParamsData, setAiParamsData] = useState({});
  const [visibleTimezones, setVisibleTimezones] = useState(5);
  const [searchValue, setSearchValue] = useState("");

  const getAiParams = async ({ accountId, region, instanceId, type }) => {
    const url = GetAiParamsUrl();
    let i1 = {
      accountId,
      region,
      instanceId,
      ...(type === "set" && aiParamsData),
    };

    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      command_to_execute:
        type !== "set" ? "get_ec2_ai_params" : "set_ec2_ai_params",
      command_args: {
        instances_list: [i1],
      },
    };

    return await axios.post(url, payload);
  };

  useEffect(() => {
    getSchedules();
  }, []);

  useEffect(() => {
    getSchedulesForCurrentInstance();
  }, [currentContextBarNodeInfo?.node_data?.nodeId]);

  const getSchedulesForCurrentInstance = async () => {
    console.log("called");
    const respData = await GetAllSchedulesForEc2ResourceHelper(
      ownedByAccountId,
      ownedByRegion,
      nodeType,
      instanceId
    );
    console.log(
      "startSchedule",
      respData?.data?.resourceItemMap?.START?.ScheduleId
    );
    setStartSchedule(respData?.data?.resourceItemMap?.START?.ScheduleId);
    setShutdownSchedule(respData?.data?.resourceItemMap?.STOP?.ScheduleId);
  };

  const getSchedules = async () => {
    const scheduleList = await getAllSchedules();
    setScheduleList(scheduleList);
  };

  const getLatestResourceWithDelay = () => {
    let timeoutId;
    const clearTimer = () => clearTimeout(timeoutId);
    timeoutId = setTimeout(async () => {
      getLatestResourceData();
      clearTimer();
    }, 30000);
    return timeoutId;
  };

  const handleChangeEC2Status = async (action) => {
    openModal(`Are you sure you want to ${action} the EC2 instance?`, () => {
      switch (action) {
        case "start":
          onStartClicked();
          getLatestResourceWithDelay();
          break;
        case "stop":
          onStopClicked();
          getLatestResourceWithDelay();
          break;
        case "reboot":
          onRebootClicked();
          getLatestResourceWithDelay();
          break;
        default:
          break;
      }
    });
  };

  const getNextPossibleStates = () => {
    const { StateReasonCode } = latestData;
    if (!StateReasonCode) return ["stop", "reboot"];
    else if (StateReasonCode === "Client.UserInitiatedShutdown")
      return ["start"];
    else return [];
  };

  const getCurrentState = () => {
    const { StateReasonCode } = latestData;
    if (!StateReasonCode) return "STARTED";
    else if (StateReasonCode === "Client.UserInitiatedShutdown")
      return ["STOPPED"];
  };

  const updateSchedule = async (TYPE) => {
    const selectedScheduleId =
      TYPE === "START" ? startSchedule : shutdownSchedule;
    console.log({ selectedScheduleId });
    await UpdateSchedulesForEc2ResourceHelper(
      ownedByAccountId,
      ownedByRegion,
      nodeType,
      instanceId,
      selectedScheduleId,
      TYPE
    );
    TYPE === "START"
      ? setEditStartSchedule(false)
      : setEditShutDownSchedule(false);
  };

  const scheduleOptions = [
    ...scheduleList.map(({ schedule_id, schedule_timings }) => ({
      label: schedule_id,
      value: schedule_id,
      schedule_timings: schedule_timings,
    })),
    { label: "None", value: null, schedule_timings: {} },
  ];

  useEffect(() => {
    if (instanceId && ownedByAccountId && ownedByRegion) getAiParamsData();
  }, [currentContextBarNodeInfo?.node_data?.nodeId]);

  const getAiParamsData = async () => {
    getAiParams({
      accountId: ownedByAccountId,
      instanceId: instanceId,
      region: ownedByRegion,
    })
      .then((aiParamsData) => {
        console.log({ instanceId, lt: latestData.instanceId });
        const { timeZone, cpuMinThreshHold, isAiInferenceEnabled } =
          aiParamsData?.data?.results?.[instanceId] || {};
        setAiParamsData({
          timeZone,
          cpuMinThreshHold,
          isAiInferenceEnabled: isAiInferenceEnabled || "false",
        });
        console.log({ rd: aiParamsData?.data?.results?.[instanceId] });
      })
      .catch(console.error);
  };
  const handleEditClick = async () => {
    if (isEditing) {
      console.log(aiParamsData);
      try {
        await getAiParams({
          accountId: ownedByAccountId,
          instanceId,
          region: ownedByRegion,
          type: "set",
        });
        toast.success("AI Parameters updated successfully");
      } catch (error) {
        toast.success("Error while updating AI params");
      }
    }
    setIsEditing(!isEditing);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setVisibleTimezones(5); // Reset visible timezones to initial count
  };

  const [startScheduleTableData] = scheduleOptions.filter(
    ({ value }) => value === startSchedule
  );
  const [shutdownScheduleTableData] = scheduleOptions.filter(
    ({ value }) => value === shutdownSchedule
  );

  const filteredTimezones = getAllTimezones().filter((tz) =>
    tz.toLowerCase().includes(searchValue.toLowerCase())
  );
  console.log({ filteredTimezones });
  const visibleTimezonesOptions = filteredTimezones
    .slice(0, visibleTimezones)
    .map((tz) => (
      <Option key={tz} value={tz}>
        {tz}
      </Option>
    ));

  console.log({ visibleTimezonesOptions });

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // User has scrolled to the bottom of the dropdown
      setVisibleTimezones((prevCount) => prevCount + 10); // Increase visible timezones count
    }
  };

  const [form] = AntForm.useForm();

  return (
    <div className="contextbar-actionContainer">
      <div className="context-bar-head">
        <div className="flex">
          <img alt="" src={imgPath} width="48" height="48" />
        </div>
        <div>
          <h4 className="context-bar-title">EC2 Instance</h4>
          <div className="context-bar-subtitle">{latestData?.InstanceId}</div>
        </div>
      </div>

      <br />

      <Divider style={{ margin: "1.5vw 0" }} />
      <div style={{ padding: "0 1vw" }}>
        <label htmlFor="Current_State">Current State</label>
        <Input
          placeholder="Current_State"
          id="Current_State"
          value={getCurrentState()}
          disabled
        />
      </div>
      <div className="contextbar-dropdownContainer">
        <Dropdown drop="down">
          <Dropdown.Toggle id="dropdown-basic">Change State</Dropdown.Toggle>

          <Dropdown.Menu>
            {getNextPossibleStates()?.map((eachState) => (
              <Dropdown.Item onClick={() => handleChangeEC2Status(eachState)}>
                {startCase(eachState)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Divider style={{ margin: "1.5vw 0" }} />
      <Collapse className="schedule-collapse">
        <Collapse.Panel header="Schedule" key="schedule">
          <div>
            <ScheduleEditor
              title="Start Time"
              value={startSchedule}
              editMode={editStartSchedule}
              onChange={setStartSchedule}
              tableData={startScheduleTableData}
              setEditMode={setEditStartSchedule}
              onCancel={() => setEditStartSchedule(false)}
              onUpdate={() => updateSchedule("START")}
              scheduleOptions={scheduleOptions}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <ScheduleEditor
              title="End Time"
              value={shutdownSchedule}
              editMode={editShutDownSchedule}
              onChange={setShutdownSchedule}
              tableData={shutdownScheduleTableData}
              setEditMode={setEditShutDownSchedule}
              onCancel={() => setEditShutDownSchedule(false)}
              onUpdate={() => updateSchedule("STOP")}
              scheduleOptions={scheduleOptions}
            />
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="AI Params" key="aiParams">
          <AntForm form={form} layout="vertical">
            <AntForm.Item label="Instance ID">
              <Input value={instanceId} disabled />
            </AntForm.Item>

            <AntForm.Item label="AI Enabled">
              <Select
                value={aiParamsData.isAiInferenceEnabled}
                placeholder="Select Timezone"
                disabled={!isEditing}
                onSelect={(e) =>
                  setAiParamsData((p) => ({ ...p, isAiInferenceEnabled: e }))
                }
              >
                <Option key={"true"} value={"true"}>
                  {"Yes"}
                </Option>
                <Option key={"false"} value={"false"}>
                  {"No"}
                </Option>
              </Select>
            </AntForm.Item>

            <AntForm.Item label="Select Timezone">
              <Select
                value={aiParamsData.timeZone}
                showSearch
                filterOption={false}
                onSearch={handleSearch}
                onPopupScroll={handlePopupScroll}
                placeholder="Select Timezone"
                disabled={!isEditing}
                onSelect={(e) =>
                  setAiParamsData((p) => ({ ...p, timeZone: e }))
                }
              >
                {visibleTimezonesOptions}
              </Select>
            </AntForm.Item>

            <AntForm.Item label="CPU Min Threshold">
              <Input
                type="number"
                placeholder="Enter CPU Min Threshold"
                value={aiParamsData.cpuMinThreshHold}
                onChange={(e) =>
                  setAiParamsData((p) => ({
                    ...p,
                    cpuMinThreshHold: e.target.value,
                  }))
                }
                disabled={!isEditing}
              />
            </AntForm.Item>

            <Button
              variant="primary"
              style={{ width: "9vw", marginTop: "1vw" }}
              className="search-button"
              onClick={handleEditClick}
            >
              {isEditing ? "Submit" : "Edit"}
            </Button>
          </AntForm>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export const ScheduleEditor = ({
  title,
  value,
  editMode,
  setEditMode,
  onChange,
  tableData,
  scheduleOptions,
  onCancel,
  onUpdate,
}) => {
  console.log({ value, scheduleOptions });
  const [selectedOption] =
    scheduleOptions.filter((option) => option.value === value) || [];
  return (
    <div>
      <div className="schedule-sub-title">{title}</div>
      <div>
        <Select
          style={{ width: "100%" }}
          showSearch
          placeholder={title}
          value={value}
          disabled={!editMode}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={scheduleOptions}
        />
      </div>
      {selectedOption?.validFrom || selectedOption?.validTill ? (
        <div className="valid-layout">
          <div>
            Valid From: {moment(selectedOption.validFrom).format("DD-MM-YYYY")}{" "}
          </div>
          <div>
            Valid Till: {moment(selectedOption.validTill).format("DD-MM-YYYY")}{" "}
          </div>
        </div>
      ) : null}
      <div>
        <table
          style={{ width: "100%", marginTop: "10px" }}
          className="timezone-table"
        >
          <tbody>
            <tr>
              {Object.keys(WEEKDAYS_MAP).map((day) => (
                <td className="table-head" key={day}>
                  {startCase(day)}
                </td>
              ))}
            </tr>
            <tr>
              {Object.values(WEEKDAYS_MAP).map((day) => (
                <td key={day}>{tableData?.schedule_timings?.[day] || "-"}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      {!editMode ? (
        <button
          type="button"
          style={{ marginTop: "10px", width: "fit-content", padding: "0 20px" }}
          onClick={() => setEditMode(true)}
          className="search-button btn btn-primary"
        >
          Edit
        </button>
      ) : (
        <div style={{ display: "flex", gridColumnGap: "0.81vw" }}>
          <button
            type="button"
            style={{ marginTop: "10px", width: "fit-content" }}
            onClick={() => {
              onCancel();
            }}
            className="search-button btn btn-primary"
          >
            Cancel
          </button>
          <button
            type="button"
            style={{ marginTop: "10px", width: "fit-content" }}
            onClick={() => {
              onUpdate();
            }}
            className="search-button btn btn-primary"
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export const GetAssetsInfoPayload = (
  userCredentials,
  commandToExecute,
  commmandArgs
) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: commandToExecute,
    command_args: commmandArgs,
  };
};

const ec2InstanceNodeType = "ec2_instance_node";

export const getAccountUserIdForEc2Instance = (instanceId) => {
  let gric = GetGlobalResourceInfoCache();
  let accountsList = gric.connection_diagram_info;

  for (var i = 0; i < accountsList.length; i++) {
    let nodesInfoOfSingleAccount = accountsList[i];

    let accountId =
      nodesInfoOfSingleAccount.network_connection_info.aws_account_id;
    let awsRegion = nodesInfoOfSingleAccount.network_connection_info.aws_region;
    let concertoUserId =
      nodesInfoOfSingleAccount.network_connection_info.concerto_user_id;

    let nodesList = nodesInfoOfSingleAccount.network_connection_info.nodes_list;

    for (var j = 0; j < nodesList.length; j++) {
      let node = nodesList[j];

      if (node.nodeType != ec2InstanceNodeType) {
        continue;
      }

      let curInstanceId = node.nodeContextInfo.InstanceId;
      if (curInstanceId == instanceId) {
        return {
          accountId: accountId,
          awsRegion: awsRegion,
          concertoUserId: concertoUserId,
          instanceId: instanceId,
        };
      }
    }
  }

  return null;
};

const getEc2AssetInfo = (instanceId) => {
  let resourceLocator = getAccountUserIdForEc2Instance(instanceId);
  let rsStr = JSON.stringify(resourceLocator);
  let userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetAssetInfoUrl();
  const payload = getPayload(userCredentials, ["get_ec2_asset_info"], rsStr);
  return axios.post(url, payload);
};

const getInstanceId = () => {
  let currentContextBarNodeInfo = getCurrentActiveContextBarNodeInfo();
  const instanceId =
    currentContextBarNodeInfo.node_data.nodeContextInfo.InstanceId;
  return instanceId;
};

const processAssetInfoForDynamicForm = (assets_info) => {
  const prefilledValues = {};
  Object.keys(assets_info).forEach((key) => {
    prefilledValues[key] = assets_info[key];
  });
  const inputFields = Object.keys(assets_info).map((key) => {
    return { name: key, label: key, id: `${key}Input` };
  });

  return { inputFields, prefilledValues };
};

export const Ec2ContextAssetHandler = ({ node_info: nodeInfo }) => {
  const formKey = JSON.stringify(nodeInfo);
  let imgPath = nodeInfo.node_data.imgPath;
  const [assetsInfo, setAssetsInfo] = useState({});
  const [viewGraphData, setViewGraphData] = useState({});
  const [graphData, setGraphData] = useState({
    seriesArray: [],
    timeStampsArray: [],
  });

  useEffect(() => {
    getAssetInfoData();
  }, [nodeInfo]);

  useEffect(() => {
    if (viewGraphData && !graphData?.seriesArray?.length) {
      getGraphData();
    }
  }, [viewGraphData]);

  const getAssetInfoData = async () => {
    try {
      const response = await getEc2AssetInfo(getInstanceId());
      if (response.data) setAssetsInfo(response?.data?.assets_info);
    } catch (err) {
      console.log("Error in getting asset info");
    }
  };

  const getGraphData = async () => {
    // ASSET GRAPH - MAKE API CALL HERE AND SET THE STATE
    const performanceData = await getEc2AssetPerformance();
    const timestamps = performanceData?.data?.cpu_stats_info?.[0].Timestamps;

    let seriesData = [];
    if (timestamps?.length) {
      seriesData = performanceData?.data?.cpu_stats_info?.map((eachInfo) => ({
        name: eachInfo.Label,
        data: eachInfo.Values,
      }));
      setGraphData({
        seriesArray: seriesData,
        timeStampArray: timestamps,
      });
    }
  };

  const onSave = (assetsInfoTags) => {
    let allTagsList = [];

    for (const tmpTag in assetsInfoTags) {
      let v = [tmpTag, assetsInfoTags[tmpTag]];
      allTagsList.push(v);
    }

    var resp = updateEc2AssetInfo(getInstanceId(), allTagsList);
  };

  const updateEc2AssetInfo = (instanceId, assetsInfoTags) => {
    let resourceLocator = getAccountUserIdForEc2Instance(instanceId);
    resourceLocator.assetsInfoTags = assetsInfoTags;
    let rsStr = JSON.stringify(resourceLocator);
    let userCredentials = GetLoginCredentialsAsJsonObject();
    const url = GetAssetInfoUrl();
    const payload = getPayload(
      userCredentials,
      [UPDATE_EC2_ASSET_INFO_COMMAND],
      rsStr
    );
    return axios.post(url, payload);
  };

  return (
    <div className="contextbar-actionContainer">
      <div className="context-bar-head">
        <div className="flex">
          <img alt="" src={imgPath} width="48" height="48" />
        </div>
        <div>
          <h4 className="context-bar-title">EC2 Instance</h4>
          {/* <div className='context-bar-subtitle'>{'latestData?.InstanceId'}</div> */}
        </div>
      </div>

      <br />
      <Card>
        <div className="node-property">
          <EditableDynamicForm
            key={formKey}
            {...processAssetInfoForDynamicForm(assetsInfo)}
            onSave={onSave}
            // onCancel={onCancel}
          />
        </div>
      </Card>

      <div className="node-property"></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 10px",
          marginTop: "10px",
        }}
      >
        <h6 className="context-bar-title">CPU Usage</h6>

        <Button
          className="search-button"
          onClick={() => setViewGraphData((p) => !p)}
        >
          {!viewGraphData ? "View Graph" : "Hide Graph"}
        </Button>
      </div>
      {viewGraphData ? (
        <div>
          <LineChart
            seriesArray={graphData.seriesArray || []}
            timeStampArray={graphData.timeStampArray || []}
            formatterFunction="roundDecimal"
          />
        </div>
      ) : null}
    </div>
  );
};

export const ec2ContextBarHandler = [
  { label: "Info", children: <Ec2ContextShowInfoBarHandler /> },
  { label: "Asset", children: <Ec2ContextAssetHandler /> },
  { label: "Action", children: <Ec2ContextActionBarHandler /> },
];

const onStopClicked = () => {
  moveInstanceStateTo(getNodesListContextInfo(), STOP_EC2_INSTANCE);
};

const onStartClicked = () => {
  moveInstanceStateTo(getNodesListContextInfo(), START_EC2_INSTANCE);
};

const onRebootClicked = () => {
  moveInstanceStateTo(getNodesListContextInfo(), REBOOT_EC2_INSTANCE);
};

const STOP_EC2_INSTANCE = "STOP_EC2_INSTANCE";
const START_EC2_INSTANCE = "START_EC2_INSTANCE";
const REBOOT_EC2_INSTANCE = "REBOOT_EC2_INSTANCE";

const getNodesListContextInfo = () => {
  console.log("getNodesListContextInfo");
  let state = GetState();
  if (state.contextBarInfo && state.contextBarInfo.payload) {
    let nodeContextInfo = state.contextBarInfo.payload;

    let a = [];
    var ec2Info = {
      nodeContextInfo: nodeContextInfo,
      nodeContextInfoType: EC2_INSTANCE_RESOURCE_INFO_SHOW_CONTEXT,
    };
    a.push(ec2Info);
    return a;
  }
  return [];
};

const moveInstanceStateTo = async (nodesListContextInfo, newState) => {
  console.log("moveInstanceStateTo requested ", newState);

  let actionParams = {
    resourceType: "Ec2InstanceType",
    command: "SetEc2InstanceState",
    params: newState,
  };

  const payload = prepareSetResourceParamsPayload(nodesListContextInfo);
  const url = GetSetResourceParamsUrl();

  payload.actionParams = actionParams;

  try {
    const resp = await axios.post(url, payload);
    console.log(resp);
    return;
  } catch (err) {
    console.log(err);
    console.log("moveInstanceStateTo to " + url + " failed");
  }
};

const prepareSetResourceParamsPayload = (nodesListContextInfo) => {
  var retPayload = PackPayloadWithCredentials(
    "resourceParamsInfo",
    nodesListContextInfo
  );
  return retPayload;
};

export const PrepareSetResourceParamsPayload = (nodesListContextInfo) => {
  var retPayload = PackPayloadWithCredentials(
    "resourceParamsInfo",
    nodesListContextInfo
  );
  return retPayload;
};

const PackPayloadWithCredentials = (
  requestDataAsObjectKeyName,
  requestDataAsObject
) => {
  let payloadPackedWithCredentials = {
    concerto_user_credentials: GetLoginCredentials(),
    [requestDataAsObjectKeyName]: requestDataAsObject,
  };

  return payloadPackedWithCredentials;
};

export const GetLoginCredentials = () => {
  let userCreds = GetUserLoginCredentials()[0];
  let authProviderTypeStr = "";

  if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
    authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
  }
  if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
    authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
  }

  let credJson = {
    displayName: userCreds.displayName,
    email: userCreds.email,
    phoneNumber: userCreds.phoneNumber,
    photoURL: userCreds.photoURL,
    providerId: userCreds.providerId,
    uid: userCreds.uid,
    user_oauth_token: GetUserLoginToken(),
    authProviderType: authProviderTypeStr,
  };

  if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
    credJson.cognito_user_pool_email_password_info =
      GetCognitoUserTokenCredentials();
  }

  return credJson;
};

const getLatestContextInfoPayload = (nodesListContextInfo) => {
  var retPayload = PackPayloadWithCredentials(
    "nodesListContextInfo",
    nodesListContextInfo
  );
  return retPayload;
};

export const GetLatestContextInfoPayload = (nodesListContextInfo) => {
  return getLatestContextInfoPayload(nodesListContextInfo);
};

export const getEc2LatestResourceInfo = async () => {
  let state = GetState();
  if (state.contextBarInfo && state.contextBarInfo.payload) {
    let nodeContextInfo = state.contextBarInfo.payload;
    const payload = getLatestContextInfoPayload([
      {
        nodeContextInfo: nodeContextInfo,
        nodeContextInfoType: EC2_INSTANCE_RESOURCE_INFO_SHOW_CONTEXT,
      },
    ]);

    const url = GetLatestResourcesInfoUrl();
    try {
      const resp = await axios.post(url, payload);
      return resp;
    } catch (err) {
      console.log(err);
      console.log("sendSyncFromCloudHttpPostRequest to " + url + " failed");
    }
  }
};

export const getEc2AssetPerformance = () => {
  const instanceId = getInstanceId();
  let resourceLocator = getAccountUserIdForEc2Instance(instanceId);
  let rsStr = JSON.stringify(resourceLocator);
  let userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetAssetInfoUrl();
  const payload = getPayload(
    userCredentials,
    [GET_EC2_ASSET_PERFORMANCE_INFO],
    rsStr
  );
  return axios.post(url, payload);
};
