import React from "react";
import ApexCharts from "react-apexcharts";
import "../../../../styles/ApexChartPie.css"

const FindingPieChart = ({ series, labels, title, height, colors }) => {
  const options = {
    chart: {
      type: "pie",
    },
    colors: colors || ["#FF4560", "#00E396", "#008FFB", "#FEB019", "#FF66C3"],
    labels: labels || [],
    title: {
      text: title || "",
      align: "center",
      style: {
        fontSize: "16px",
        color: "#000",
      },
      offsetY: -7,
    },
    legend: {
      position: "right",
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        customScale: 1,
        offsetY: 0,
      },
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <div className="apex-pie-chart">
      <ApexCharts
        options={options}
        series={series}
        type="pie"
        height={height || 300}
      />
    </div>
  );
};

export default FindingPieChart;
