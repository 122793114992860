import React, { useEffect, useState } from 'react';
import NestedAccordion from './NestedAccordion';
import axios from 'axios';
import { GetLoginCredentialsAsJsonObject, GetUserLoginCredentials, GetUserLoginToken } from '../UserCredentials';
import { GetResourcesOverviewUiCacheUrl, IsDemoModeHideAccountDetails } from '../../routes';
import { getCredsV2 } from '../CognitoUserPoolSignin';
import { groupBy } from 'lodash';

const sendSyncFromCloudHttpPostRequest_2 = async (requestedResourceType) => {
  const url = GetResourcesOverviewUiCacheUrl();
  try {
    let payloadData = getCredsV2(GetLoginCredentialsAsJsonObject(requestedResourceType));
    payloadData.requestedResourceType = requestedResourceType;
    let resp = await axios.post(url, payloadData);
    return resp;
  } catch (err) {
    console.log(err);
    console.log('sendSyncFromCloudHttpPostRequest to ' + url + ' failed');
  }
};

var globalResourceInfoCache = {};

export const GetGlobalResourceInfoCache = () => {
  return globalResourceInfoCache;
};

const ResourceBrowser = () => {
  const [resourceData, setResourceData] = useState({});
  useEffect(() => {
    getResourceData();
  }, []);

  const getResourceData = async () => {
    const respData = await sendSyncFromCloudHttpPostRequest_2('ConnectionDiagramType');
    setResourceData(respData.data);
    globalResourceInfoCache = respData.data;
  };

  const { connection_diagram_info = [], ui_assets_map = {} } = resourceData;

  return <div>{getConnectionTree(connection_diagram_info, ui_assets_map)}</div>;
};

const getAwsAccountID = (aws_account_id) => {
  if (IsDemoModeHideAccountDetails() === 'true') {
    const aws_id = aws_account_id;
    const numberOfCharacters = 2;
    const first_digit = aws_id.slice(0, numberOfCharacters);
    const middle_digits = aws_id.slice(numberOfCharacters, aws_id.length);
    return [first_digit, middle_digits];
  }
  return [aws_account_id, ''];
};

const getResourceChildData = (network_connection_info, resource, ui_assets_map, aws_account_id, aws_region, aws_icon, title) => {
  const { aws_ui_assets_base_url, aws_resource_ui_paths } = ui_assets_map;

  // change the icon
  const icon = aws_resource_ui_paths.find(({ resource_type }) => resource_type === aws_icon);
  const iconImagePath = aws_ui_assets_base_url + '/' + icon?.image_path;
  const nodes = network_connection_info.nodes_list.filter(({ nodeType }) => nodeType === resource);
  const childData = [
    nodes?.length
      ? {
          title: (
            <div className='accordion-node-title-container'>
              <img src={iconImagePath} alt='' />
              <div>{title}</div>
            </div>
          ),
          children: nodes.map((eachNode) => ({
            title: eachNode.nodeId,
            imgPath: iconImagePath,
            OwnedByAccountId: aws_account_id,
            OwnedByRegion: aws_region,
            ...eachNode,
          })),
        }
      : null,
  ];

  return childData;
};

const getIamChildData = (network_connection_info, ui_assets_map, aws_account_id, aws_region, iam_type, title) => {
  const { aws_ui_assets_base_url, aws_resource_ui_paths } = ui_assets_map;

  // change the icon
  const icon = aws_resource_ui_paths.find(({ resource_type }) => resource_type === 'aws_internet_gateway_resource');
  const iamIconImagePath = aws_ui_assets_base_url + '/' + icon?.image_path;

  console.log('iam_type ', iam_type);

  const nodes = network_connection_info.nodes_list.filter(({ nodeType }) => nodeType === iam_type);

  const childData = [
    nodes?.length
      ? {
          title: (
            <div className='accordion-node-title-container'>
              <img src={iamIconImagePath} alt='' />
              <div>{title}</div>
            </div>
          ),
          children: nodes.map((eachNode) => ({
            title: eachNode.nodeId,
            imgPath: iamIconImagePath,
            OwnedByAccountId: aws_account_id,
            OwnedByRegion: aws_region,
            ...eachNode,
          })),
        }
      : null,
  ];

  return childData;
};

const getElasticChildData = (network_connection_info, ui_assets_map, aws_account_id, aws_region, elastic_type, title) => {
  const { aws_ui_assets_base_url, aws_resource_ui_paths } = ui_assets_map;

  // change the icon

  const icon = aws_resource_ui_paths.find(({ resource_type }) => resource_type === 'aws_sns_node');
  const elasticIconImagePath = aws_ui_assets_base_url + '/' + icon?.image_path;

  const nodes = network_connection_info.nodes_list.filter(({ nodeType }) => nodeType === elastic_type);

  console.log('nodes ', nodes);
  const childData = [
    nodes?.length
      ? {
          title: (
            <div className='accordion-node-title-container'>
              <img src={elasticIconImagePath} alt='' />
              <div>{title}</div>
            </div>
          ),
          children: nodes.map((eachNode) => ({
            title: eachNode.nodeId,
            imgPath: elasticIconImagePath,
            OwnedByAccountId: aws_account_id,
            OwnedByRegion: aws_region,
            ...eachNode,
          })),
        }
      : null,
  ];

  return childData;
};

const getConnectionTree = (connection_diagram_info, ui_assets_map) => {
  const { aws_ui_assets_base_url, aws_resource_ui_paths } = ui_assets_map;
  console.log({ connection_diagram_info });

  const groupedData = groupBy(connection_diagram_info, 'network_connection_info.aws_account_id');

  const getProcessedData = (network_connection_info) => {
    console.log({ network_connection_info });
    const { aws_account_id, aws_region } = network_connection_info;
    // Filter nodes that are top level VPC nodes
    const topLevelVpcNodes = network_connection_info.nodes_list.filter(({ nodeType }) => nodeType === 'top_level_vpc_node');

    // Map over top level VPC nodes to create child nodes
    const vpcChildData = topLevelVpcNodes
      .map((currNode) => {
        const [vpcIcon] = aws_resource_ui_paths.filter(({ resource_type }) => resource_type === 'top_level_vpc_node');
        const [ec2ResourceIcon] = aws_resource_ui_paths.filter(({ resource_type }) => resource_type === 'ec2_resources');
        const [lambdaResourceIcon] = aws_resource_ui_paths.filter(({ resource_type }) => resource_type === 'lambda_resources');
        const [rdsResourceIcon] = aws_resource_ui_paths.filter(({ resource_type }) => resource_type === 'rds_db_node');
        const vpcLambdaChildNodes = network_connection_info.nodes_list.filter(
          ({ nodeType, parentNodeId }) => nodeType === 'aws_lambda_node' && parentNodeId === currNode.nodeId
        );
        const vpcEc2ChildNodes = network_connection_info.nodes_list.filter(
          ({ nodeType, parentNodeId }) => nodeType === 'ec2_instance_node' && parentNodeId === currNode.nodeId
        );
        const vpcRdsChildNodes = network_connection_info.nodes_list.filter(
          ({ nodeType, parentNodeId }) => nodeType === 'rds_db_node' && parentNodeId === currNode.nodeId
        );

        const vpcIconImagePath = aws_ui_assets_base_url + '/' + vpcIcon?.image_path;
        const ec2IconImagePath = aws_ui_assets_base_url + '/' + ec2ResourceIcon?.image_path;
        const lambdaIconImagePath = aws_ui_assets_base_url + '/' + lambdaResourceIcon?.image_path;
        const rdsResourceIconPath = aws_ui_assets_base_url + '/' + rdsResourceIcon?.image_path;

        // Return formatted child node data
        const children = [
          vpcLambdaChildNodes?.length
            ? {
                title: (
                  <div className='accordion-node-title-container'>
                    <img src={lambdaIconImagePath} alt='' />
                    <div>Lambda</div>
                  </div>
                ),
                children: vpcLambdaChildNodes.map((eachNode) => ({
                  title: eachNode.nodeId,
                  imgPath: lambdaIconImagePath,
                  OwnedByAccountId: aws_account_id,
                  OwnedByRegion: aws_region,
                  ...eachNode,
                })),
              }
            : null,
          vpcEc2ChildNodes.length
            ? {
                title: (
                  <div className='accordion-node-title-container'>
                    <img src={ec2IconImagePath} alt='' />
                    <div>EC2</div>
                  </div>
                ),
                children: vpcEc2ChildNodes.map((eachNode) => ({
                  title: eachNode.nodeId,
                  imgPath: ec2IconImagePath,
                  ownedByAccountId: aws_account_id,
                  ownedByRegion: aws_region,
                  ...eachNode,
                })),
              }
            : null,
          vpcRdsChildNodes?.length
            ? {
                title: (
                  <div className='accordion-node-title-container'>
                    <img src={rdsResourceIconPath} alt='' />
                    <div>RDS</div>
                  </div>
                ),
                children: vpcRdsChildNodes.map((eachNode) => ({
                  title: eachNode.nodeId,
                  imgPath: rdsResourceIconPath,
                  ownedByAccountId: aws_account_id,
                  ownedByRegion: aws_region,
                  ...eachNode,
                })),
              }
            : null,
        ].filter(Boolean);

        return children?.length
          ? {
              title: (
                <div key={JSON.stringify(currNode)} className='accordion-node-title-container'>
                  <div>
                    <img src={vpcIconImagePath} alt='' />
                  </div>
                  <div>{currNode.nodeId}</div>
                </div>
              ),
              titleLong: (
                <div key={JSON.stringify(currNode)} className='accordion-node-title-container'>
                  <div>
                    <img src={vpcIconImagePath} alt='' />
                  </div>
                  <div>{`${currNode.nodeId} ${currNode.nodeType}`}</div>
                </div>
              ),
              children,
            }
          : null;
      })
      .filter(Boolean);
    const [accountId, _value] = getAwsAccountID(aws_account_id);

    let childrenData = [];
    childrenData.push(...vpcChildData);

    const sqsChildData = getResourceChildData(
      network_connection_info,
      'sqs_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sqs_node',
      'Simple Queue Service(SQS)'
    );

    childrenData.push(...sqsChildData);

    const apiGatewayChildData = getResourceChildData(
      network_connection_info,
      'api_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_api_gateway_rest_api',
      'API Gateway'
    );
    childrenData.push(...apiGatewayChildData);

    const snsChildData = getResourceChildData(
      network_connection_info,
      'sns_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Simple Notification Service(SNS)'
    );
    childrenData.push(...snsChildData);

    /* ============= IAM Data ============= */
    const IamChildData = [];

    const IamRolePoliciesChildData = getIamChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'iam_role_policies_node',
      'IAM Role Policies'
    );
    if (!(IamRolePoliciesChildData.length === 1 && IamRolePoliciesChildData[0] === null)) {
      IamChildData.push(...IamRolePoliciesChildData);
    }

    const IamRolesListChildData = getIamChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'iam_roles_list_node',
      'IAM Role List'
    );
    if (!(IamRolesListChildData.length === 1 && IamRolesListChildData[0] === null)) {
      IamChildData.push(...IamRolesListChildData);
    }

    const IamUserAttachedChildData = getIamChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'iam_user_attached_node',
      'IAM User Attached'
    );
    if (!(IamUserAttachedChildData.length === 1 && IamUserAttachedChildData[0] === null)) {
      IamChildData.push(...IamUserAttachedChildData);
    }

    const IamUserInlineChildData = getIamChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'iam_user_inline_node',
      'IAM User Inline'
    );
    if (!(IamUserInlineChildData.length === 1 && IamUserInlineChildData[0] === null)) {
      IamChildData.push(...IamUserInlineChildData);
    }

    const IamUserResourcesChildData = getIamChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'iam_user_resources_node',
      'IAM User Resources'
    );
    if (!(IamUserResourcesChildData.length === 1 && IamUserResourcesChildData[0] === null)) {
      IamChildData.push(...IamUserResourcesChildData);
    }

    const [iamIcon] = aws_resource_ui_paths.filter(({ resource_type }) => resource_type === 'aws_sns_node');
    const IamImgPath = aws_ui_assets_base_url + '/' + iamIcon?.image_path;
    const IamChildren = [
      IamChildData?.length
        ? {
            title: (
              <div className='accordion-node-title-container'>
                <img src={IamImgPath} alt='' />
                <div>IAM</div>
              </div>
            ),
            children: IamChildData.map((eachNode) => ({
              title: eachNode.nodeId,
              imgPath: IamImgPath,
              OwnedByAccountId: aws_account_id,
              OwnedByRegion: aws_region,
              ...eachNode,
            })),
          }
        : null,
    ];

    if (!(IamChildren.length === 1 && IamChildren[0] === null)) {
      childrenData.push(...IamChildren);
    }
    /* ============= IAM Ends ============= */

    /* ============= Elastic child data ============= */
    const elasticChildData = [];

    const ElasticIPResourcesChildData = getElasticChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'elastic_ip_node',
      'Elastic IP Resources'
    );
    if (!(ElasticIPResourcesChildData.length === 1 && ElasticIPResourcesChildData[0] === null)) {
      elasticChildData.push(...ElasticIPResourcesChildData);
    }
    const ElasticLoadBalancerChildData = getElasticChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'elastic_load_balancer_resource_info_show_context',
      'Elastic Load Balancer'
    );
    if (!(ElasticLoadBalancerChildData.length === 1 && ElasticLoadBalancerChildData[0] === null)) {
      elasticChildData.push(...ElasticLoadBalancerChildData);
    }

    const ElasticLoadBalancerV2ChildData = getElasticChildData(
      network_connection_info,
      ui_assets_map,
      accountId,
      aws_region,
      'elastic_load_balancer_v2_resource_info_show_context',
      'Elastic Load Balancer V2'
    );
    if (!(ElasticLoadBalancerV2ChildData.length === 1 && ElasticLoadBalancerV2ChildData[0] === null)) {
      elasticChildData.push(...ElasticLoadBalancerV2ChildData);
    }

    const elasticIcon = aws_resource_ui_paths.find(({ resource_type }) => resource_type === 'aws_sns_node');
    const elasticImgPath = aws_ui_assets_base_url + '/' + elasticIcon?.image_path;

    const elasticChildren = [
      elasticChildData?.length
        ? {
            title: (
              <div className='accordion-node-title-container'>
                <img src={elasticImgPath} alt='' />
                <div>Elastic</div>
              </div>
            ),
            children: elasticChildData.map((eachNode) => ({
              title: eachNode.nodeId,
              imgPath: elasticImgPath,
              OwnedByAccountId: aws_account_id,
              OwnedByRegion: aws_region,
              ...eachNode,
            })),
          }
        : null,
    ];

    if (!(elasticChildren.length === 1 && elasticChildren[0] === null)) {
      childrenData.push(...elasticChildren);
    }
    /* ============= Elastic child data ends ============= */

    const ecsChildData = getResourceChildData(network_connection_info, 'ecs_node', ui_assets_map, accountId, aws_region, 'aws_sns_node', 'ECS');
    if (!(ecsChildData.length === 1 && ecsChildData[0] === null)) {
      childrenData.push(...ecsChildData);
    }

    /* ============= Internet Gateway child data ============= */

    const internetGatewayChildData = getResourceChildData(
      network_connection_info,
      'internet_gateway_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Internet Gateway'
    );
    if (!(internetGatewayChildData.length === 1 && internetGatewayChildData[0] === null)) {
      childrenData.push(...internetGatewayChildData);
    }

    /* ============= Nat Gateway child data ============= */

    const natGatewayChildData = getResourceChildData(
      network_connection_info,
      'nat_gateway_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'NAT Gateway'
    );
    if (!(natGatewayChildData.length === 1 && natGatewayChildData[0] === null)) {
      childrenData.push(...natGatewayChildData);
    }

    /* ============= Network Interface child data ============= */

    const networkInterfaceChildData = getResourceChildData(
      network_connection_info,
      'network_interface_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Network Interface'
    );
    if (!(networkInterfaceChildData.length === 1 && networkInterfaceChildData[0] === null)) {
      childrenData.push(...networkInterfaceChildData);
    }

    /* ============= Route Table child data ============= */

    const routeTableChildData = getResourceChildData(
      network_connection_info,
      'route_table_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Route Table'
    );
    if (!(routeTableChildData.length === 1 && routeTableChildData[0] === null)) {
      childrenData.push(...routeTableChildData);
    }

    /* ============= Route 53 child data ============= */

    const route53ChildData = getResourceChildData(
      network_connection_info,
      'route53_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Route 53'
    );
    if (!(route53ChildData.length === 1 && route53ChildData[0] === null)) {
      childrenData.push(...route53ChildData);
    }

    /* ============= Subnet child data ============= */

    const subnetChildData = getResourceChildData(
      network_connection_info,
      'subnet_node',
      ui_assets_map,
      accountId,
      aws_region,
      'aws_sns_node',
      'Subnet'
    );
    if (!(subnetChildData.length === 1 && subnetChildData[0] === null)) {
      childrenData.push(...subnetChildData);
    }

    // Return formatted diagram data
    return {
      title: (
        <div className='accordion-node-title-container'>
          <div>
            <span>{aws_region}</span>
          </div>
        </div>
      ),
      children: childrenData,
    };
  };

  let processedData = [];

  for (const key in groupedData) {
    const [accountId, _value] = getAwsAccountID(key);

    processedData.push({
      title: (
        <div className='accordion-node-title-container'>
          <div>
            <img style={{ height: '3.5vw' }} src='https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png' alt='' />
          </div>

          <div>
            <span>{accountId}</span>
            <span style={{ filter: 'blur(5px)' }}>{_value}</span>
          </div>
        </div>
      ),
      children: groupedData[key]?.map((eachItem) => getProcessedData(eachItem.network_connection_info)),
    });
  }

  return processedData.map((item, idx) => {
    console.log({ processedData });
    return <NestedAccordion key={item.title} title={item.title} children={item.children} />;
  });
};

export default ResourceBrowser;
