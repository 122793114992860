import React from "react";
import { Card } from "antd";
import "../../../../styles/SeverityCard.css";

const SeverityCards = ({
  title,
  header,
  content,
  backgroundColor,
  onClick,
}) => {
  return (
    <Card
      className="severity-card"
      title={<div className="severity-card-title">{title}</div>}
      style={{ background: backgroundColor }}
      onClick={onClick}
    >
      <h6 className="severity-card-header">{header}</h6>
      <p className="severity-card-content">{content}</p>
    </Card>
  );
};

export default SeverityCards;
