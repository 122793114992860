import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../Table/DataTable";
import { GetSecurityScanUrl } from "../../../../routes";
import {
  GetCognitoUserTokenCredentials,
  GetLoginCredentialsAsJsonObject,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../../../../components/UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../../../../components/CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../../../../components/Login";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

const ListView = ({
  data,
  onBack,
  selectedCompliance,
  filters,
  selectedScanId,
}) => {
  const [findingsData, setFindingsData] = useState([]);
  const { status = "", selectedData = {} } = data || {};
  const [loading, setLoading] = useState(false);

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const getSecurityComplianceDetailsPayload = () => {
    let filters_args = {
      accounts: ["206824716966"],

      finding_status: ["FAIL", "MANUAL", "PASS"],

      severity_type: ["High", "Low", "Medium"],

      compliance_type: [selectedCompliance],
      // compliance_type: [
      //   "aws_well_architected_framework_security_pillar_aws.csv",
      // ],

      region_type: ["us-east-1", "ap-south-1"],
    };

    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_compliance_details",
      command_args: {
        AccountId: filters["AWS Account"],
        ExpectedScanId: selectedScanId,
        filters: filters_args,
        startRow: "0",
        numRows: "10000",
      },
    };
  };

  const handleGetComplianceDetails = async (event) => {
    const url = GetSecurityScanUrl();
    const payload = getSecurityComplianceDetailsPayload();
    console.log("list view payload", payload);
    try {
      setLoading(true);
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        console.log("details", resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    }finally {
      setLoading(false); 
    }
  };

  const getFindings = async () => {
    try {
      const responseData = await handleGetComplianceDetails();
      if (responseData) {
        const findings = responseData?.data?.findings || [];

        let processedFindings = [...findings];

        if (status) {
          processedFindings = processedFindings.filter(
            (item) => item.status === status
          );
        }

        setFindingsData(processedFindings);
      }
    } catch (error) {
      console.error("Error fetching compliance details:", error);
    }
  };

  useEffect(() => {
    getFindings();
  }, [status, selectedData]);

  return (
    <div
      style={{
        height: "75vh",
        overflow: "auto",
      }}
    >
      <Button
        className="back-btn"
        onClick={() => {
          console.log("Back button in ListView clicked.");
          onBack();
        }}
        icon={<ArrowLeftOutlined />}
      >
        Back
      </Button>
      <DataTable data={findingsData} loading={loading}  />
    </div>
  );
};

export default ListView;
