import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetLoginCredentialsAsJsonObject } from '../../../../components/UserCredentials';
import { GetAckSetUrl } from '../../../../routes';
import axios from 'axios';
import CopyIcon from '../../../../components/CopyIcon/CopyIcon';

const getAwsKeysReadPayload = () => {
  let keysReadPayload = {
    concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
    ack_data: {
      name: '',
      AWS_ACCOUNT_ID: '',
      AWS_ACCESS_KEY_ID: '',
      AWS_SECRET_ACCESS_KEY: '',
      DEFAULT_REGION: '',
      REGIONS: [],
      data_op: 'read',
    },
  };

  return keysReadPayload;
};

const AddDeleteAwsAccount = ({ data, type, successCallback = () => {}, errorCallback = () => {} }) => {
  const [awsAccounts, setAwsAccounts] = useState([]);
  const { account_name, account_id, account_description, aws_regions_list, default_aws_region, account_names_list, account_ids_list } = data;
  const typeOfOperation = type === 'create' ? 'Creation' : 'Deletion';
  let userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetAckSetUrl();

  const getAwsAccounts = async () => {
    const url = GetAckSetUrl();
    const payload = getAwsKeysReadPayload();

    try {
      const { data } = await axios.post(url, payload);
      console.log(data);
      let respData = [];
      Object.keys(data).forEach((key) => {
        if (data[key]?.AccountId) respData.push(data[key]);
      });
      setAwsAccounts(respData);
      return respData;
    } catch (err) {
      console.log(err);
      console.log('getAwsKeys to ' + url + ' failed.');
    }
  };

  useEffect(() => {
    if (typeOfOperation === 'Deletion') {
      getAwsAccounts();
    }
  }, []);

  const getPayload = (data_op) => {
    return {
      concerto_user_credentials: userCredentials,
      ack_data: {
        name: account_name,
        Description: account_description,
        AWS_ACCOUNT_ID: account_id,
        AWS_ACCESS_KEY_ID: '',
        AWS_SECRET_ACCESS_KEY: '',
        DEFAULT_REGION: default_aws_region,
        REGIONS: aws_regions_list,
        data_op,
      },
    };
  };

  const getPayloadForDeletion = ({ account_name, account_id, default_aws_region, aws_regions_list }) => {
    console.log({ account_name });
    return {
      concerto_user_credentials: userCredentials,
      ack_data: {
        name: account_name,
        AWS_ACCOUNT_ID: account_id,
        AWS_ACCESS_KEY_ID: '',
        AWS_SECRET_ACCESS_KEY: '',
        DEFAULT_REGION: default_aws_region,
        REGIONS: aws_regions_list,
        data_op: 'delete',
      },
    };
  };
  const handleCreate = () => {
    return axios.post(url, getPayload('update'));
  };

  const handleDelete = async () => {
    const accountsToDelete = awsAccounts.filter(
      (eachAccount) => account_ids_list.includes(eachAccount.AccountId) || account_names_list.includes(eachAccount.FriendlyAlias)
    );
    console.log({ accountsToDelete });
    for await (const account of accountsToDelete) {
      console.log({ account });
      const payload = getPayloadForDeletion({
        account_name: account.FriendlyAlias,
        account_id: account.AccountId,
        default_aws_region: account.DefaultRegion,
        aws_regions_list: account.Regions,
      });
      await axios.post(url, payload);
    }
    return true;
  };

  const onConfirm = async () => {
    const resp = type === 'create' ? await handleCreate() : await handleDelete();
    if ([resp.data?.ackset_update_status, resp.data?.ackset_delete_status].includes('success') || type === 'delete') {
      toast.success(`Account ${typeOfOperation} success`);
      return successCallback();
    } else {
      toast.error(`Account ${typeOfOperation} failed`);
      return errorCallback();
    }
  };

  let componentToRender = (
    <div style={{ marginBottom: '20px' }}>
      <p>
        <strong>Account Name:</strong> {account_name}
      </p>
      <p>
        <strong>Account ID:</strong> {account_id}
      </p>
      <p>
        <strong>Account Description:</strong> {account_description}
      </p>
      <p>
        <strong>AWS Regions List:</strong> {aws_regions_list?.join(', ')}
      </p>
      <p>
        <strong>Default AWS Region:</strong> {default_aws_region}
      </p>
    </div>
  );

  if (typeOfOperation === 'Deletion' && awsAccounts.length) {
    const accountsToDelete = awsAccounts.filter(
      (eachAccount) => account_ids_list.includes(eachAccount.AccountId) || account_names_list.includes(eachAccount.FriendlyAlias)
    );

    const columns = [
      {
        title: 'Account ID',
        dataIndex: 'AccountId',
        key: 'AccountId',
        render: (text) => (
          <div style={{ display: 'flex', gap: '0.5vw', alignItems: 'center' }}>
            <img style={{ height: '2.3vw' }} src='https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png' alt='' />{' '}
            <CopyIcon text={text} title={'Copy Account ID'} />
          </div>
        ),
      },
      {
        title: 'Friendly Name',
        dataIndex: 'FriendlyAlias',
        key: 'FriendlyAlias',
        render: (text) => <CopyIcon text={text} title={'Copy Name'} />,
      },

      {
        title: 'Default Region',
        dataIndex: 'DefaultRegion',
        key: 'DefaultRegion',
      },
    ];
    componentToRender = (
      <div className='securityViolationsTable'>
        <Table style={{ borderRadius: '1vw' }} size='small' pagination={false} dataSource={accountsToDelete} columns={columns} />
      </div>
    );
  }

  return (
    <div>
      <h6 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>Confirm Account {typeOfOperation}</h6>
      <div style={{ marginTop: '1vw' }}>
        <p style={{ marginBottom: '20px' }}>Please confirm the {typeOfOperation} of the following AWS account(s):</p>
        {componentToRender}
        <div style={{ marginTop: '20px', textAlign: 'right' }}>
          <Button style={{ marginRight: '10px' }} onClick={errorCallback}>
            Cancel
          </Button>
          <Button type='primary' onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddDeleteAwsAccount;
