import React, { useContext, useEffect, useState } from 'react';
import ScheduleForm from './ScheduleForm/ScheduleForm';
import { Form, Table } from 'antd';
import { Button } from 'react-bootstrap';
import { startCase } from 'lodash';
import EditIcon from '../../../images/Edit.svg';
import DeleteIcon from '../../../images/Delete.svg';
import axios from 'axios';
import { GetProductConfigurationSchedulesUrl } from '../../../routes';
import { GetLoginCredentialsAsJsonObject } from '../../UserCredentials';
import { getCredsV2 } from '../../CognitoUserPoolSignin';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ConfirmationModalContext } from '../../../context/ConfirmationModalContext';

export const getPayload = (userCredentials, commandToExecute, commmandArgs) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: commandToExecute,
    command_args: commmandArgs,
  };
};

const Schedules = () => {
  const { openModal } = useContext(ConfirmationModalContext);
  const userCredentials = GetLoginCredentialsAsJsonObject();
  const url = GetProductConfigurationSchedulesUrl();

  const [scheduleForm] = Form.useForm();
  const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    getAllSchedules();
  }, []);

  const openEditModal = (record) => {
    let schedule_timings = {};
    Object.keys(record.schedule_timings).forEach((key) => {
      if (record.schedule_timings[key]) {
        schedule_timings[key] = moment(record.schedule_timings[key], 'HH:mm');
      } else {
        schedule_timings[key] = moment();
      }
    });
    console.log({ schedule_timings });
    scheduleForm.setFieldsValue({ ...record, schedule_timings });
    setIsScheduleFormOpen(true);
  };

  const showModal = () => {
    setIsScheduleFormOpen(true);
    scheduleForm.resetFields();
  };

  const getAllSchedules = async () => {
    const payload = getPayload(userCredentials, 'read_daily_schedule_info', {});
    const resp = await axios.post(url, payload);
    if (resp.status === 200) {
      setSchedule(resp?.data?.daily_schedules_list || []);
    }
  };

  const handleDelete = async (id) => {
    openModal(`Are you sure you want to delete the schedule?`, async () => {
      const payload = getPayload(userCredentials, 'delete_daily_schedule_info', {
        schedule_id: id,
      });
      const resp = await axios.post(url, payload);
      if (resp.status === 200) {
        const updatedSchedule = schedule.filter((item) => item.schedule_id !== id);
        setSchedule(updatedSchedule);
        toast.success('Schedule deleted successfully');
      }
    });
  };

  const handleCreate = async (values) => {
    const payload = getPayload(userCredentials, 'create_daily_schedule_info', { ...values, created_by: 'CONCERTO_USER' });
    const resp = await axios.post(url, payload);
    if (resp.status === 200) {
      toast.success('Schedule created successfully');
      setIsScheduleFormOpen(false);
      return true;
    } else toast.error('Schedule creation failed');
  };

  const handleUpdate = async (schedule) => {
    const payload = getPayload(userCredentials, 'update_daily_schedule_info', schedule);
    const resp = await axios.post(url, payload);
    if (resp.status === 200) {
      toast.success('Schedule updated successfully');
      setIsScheduleFormOpen(false);
      return true;
    } else toast.error('Schedule update failed');
  };

  const handleSubmit = async (values) => {
    const updatedSchedule = [...schedule];
    const existingIndex = updatedSchedule.findIndex((item) => item.schedule_id === values.schedule_id);

    if (existingIndex !== -1) {
      const isUpdated = await handleUpdate(values);
      if (isUpdated) updatedSchedule[existingIndex] = values; // Update existing schedule
    } else {
      const isCreated = await handleCreate(values);
      if (isCreated) updatedSchedule.push(values); // Add new schedule
    }
    setSchedule(updatedSchedule);
  };

  return (
    <div>
      <Button
        className='search-button'
        style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        type='primary'
        onClick={showModal}
      >
        <spa style={{ marginTop: '-2px' }}>+ &nbsp;</spa> Add New
      </Button>
      <ScheduleForm
        form={scheduleForm}
        isOpen={isScheduleFormOpen}
        setIsOpen={setIsScheduleFormOpen}
        schedule={schedule}
        setSchedule={setSchedule}
        handleSubmit={handleSubmit}
      />
      {/* <Table className='mt-3' pagination={false} dataSource={schedule} columns={columns} /> */}

      <div className='schedules-container mt-3'>
        {schedule.map((eachSchedule) => {
          const { schedule_id, timezone, schedule_timings } = eachSchedule;
          return (
            <div>
              <div className='header'>
                <div className='title'>{schedule_id}</div>
                <div className='buttons-container'>
                  <div onClick={() => openEditModal(eachSchedule)} className='edit-button'>
                    <img src={EditIcon} alt='' /> Edit
                  </div>
                  <div onClick={() => handleDelete(schedule_id)} className='delete-button'>
                    <img src={DeleteIcon} alt='' /> Delete
                  </div>
                </div>
              </div>
              <div className='timezone'>{timezone}</div>
              <div>
                <table className='timezone-table'>
                  <tbody>
                    <tr>
                      {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                        <td className='table-head' key={day}>
                          {startCase(day)}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                        <td key={day}>{schedule_timings?.[day] ? moment(schedule_timings[day], 'HH:mm').format('HH:mm') : 'Not set'}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Schedules;
