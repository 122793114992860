import React, { useState, lazy, Suspense, useEffect, Component } from "react";
import {
  DollarCircleOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  DollarOutlined,
  LockOutlined,
  SettingFilled,
  ClockCircleOutlined,
  HistoryOutlined,
  LineChartOutlined,
  GlobalOutlined,
  DiffOutlined,
  CodeOutlined,
  CloudServerOutlined,
  RiseOutlined,
  FileSearchOutlined,
  SwapOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

import BluefinwareLogo from "../../landingPage/assets/LogoWithText.svg";
import { ReactComponent as DashboardIcon } from "../../images/Dashboard.svg";
import ResourceBrowserWrapper from "../../ResourceBrowserWrapper";
import Recommendations from "../../pages/Recommendations/Recommendations";
import { GetUserBasedSidebarMenuInformation } from "../../components/UserCredentials";
import Chatbot, { CHATBOT_RESULT_TYPES } from "../Chatbot/Chatbot";
import AwsUsageCost from "../../pages/ChatbotResponses/AwsCostUsage/AwsUsageCost";
import EC2InstanceInfo from "../../pages/ChatbotResponses/EC2InstanceInfo/EC2InstanceInfo";
import Ec2InstanceStatuses from "../../pages/ChatbotResponses/Ec2InstanceStatuses/Ec2InstanceStatuses";
import { SecurityAdvisor } from "../../pages/ChatbotResponses/SecurityAdvisor/SecurityAdvisor";
import RecommedationsContainer from "../../pages/Recommendations/Recommendations";
import Schedules from "../../components/ProductConfiguration/Schedules/Schedules";
import CPUStatsChart from "../../pages/ChatbotResponses/CPUStatsChart/CPUStatsChart";
import TerraformScript from "../../pages/ChatbotResponses/TerraformScript/TerraformScript";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ChatbotHelp from "../../pages/ChatbotResponses/ChatbotHelp/ChatbotHelp";
import SecurityAdvisorNew from "../../pages/Security/SecurityAdvisors/SecurityAdvisorNew";
import Compliance from "../../pages/Security/Compliance/Compliance";
import DetailedView from "../../components/CostManager/v2/DetailedView";
import BillingCompare from "../../components/CostManager/v2/BillingCompare";

const { Header, Content, Sider } = Layout;

const ChangePassword = lazy(() => import("../../components/ChangePassword"));
const UserProfile = lazy(() => import("../../components/UserProfile"));
const AwsAccessKeys = lazy(() =>
  import("../../components/Settings/AwsAccessKeys")
);
const BillingsPlans = lazy(() =>
  import("../../components/Settings/BillingsPlans")
);
const ProductConfiguration = lazy(() =>
  import("../../components/ProductConfiguration/ProductConfiguration")
);
const Billing = lazy(() => import("../../components/CostManager/Billing"));
const Scheduler = lazy(() => import("../../pages/Scheduler/Scheduler"));
const AdvancedSearch = lazy(() => import("../../components/AdvancedSearch"));
// const Security = lazy(() =>
//   import("../../pages/Security")
// );
const InfraAsCode = lazy(() =>
  import("../../components/DisasterRecoveryPage/InfraAsCode/InfraAsCode")
);
const ResourceComparision = lazy(() =>
  import(
    "../../components/DisasterRecoveryPage/ResourceComparision/ResourceComparision"
  )
);

const isFeatureEnabled = (menuInfo, featureName, parent) => {
  if (featureName === "enabled") return true;
  if (parent?.featureName)
    return (
      menuInfo?.[parent?.featureName]?.children?.[featureName]?.state ===
      "enabled"
    );
  else return menuInfo?.[featureName]?.state === "enabled";
};

const isFeatureDisabled = (menuInfo, featureName, parent) => {
  if (parent?.featureName)
    return (
      menuInfo?.[parent?.featureName]?.children?.[featureName]?.state ===
      "disabled"
    );
  else return menuInfo?.[featureName]?.state === "disabled";
};

const isChatbotEnabled = (menuInfo, featureName, parent) => {
  return true;
};

const getProcessedMenuItems = (menuInfo, children, parent) => {
  return children
    ?.filter((child) => {
      return isFeatureEnabled(menuInfo, child.featureName, parent);
    })
    ?.map((child) => {
      return {
        ...child,
        chatBotEnabled:
          isChatbotEnabled(menuInfo, child.featureName, parent) ||
          child.chatBotEnabled,
        disabled: isFeatureDisabled(menuInfo, child.featureName),
        children: getProcessedMenuItems(menuInfo, child.children, child),
      };
    });
};

const DISASTER_RECOVERY_PAGES = [
  {
    label: "Resource Comparision",
    param: "ResourceComparision",
    id: "resource-comparison",
    key: "resource-comparison",
    component: <ResourceComparision />,
    featureName: "ResourceComparison",
    icon: <DiffOutlined />,
  },
  {
    label: "Infra As Code",
    id: "infra-as-code",
    key: "infra-as-code",
    component: <InfraAsCode />,
    icon: <CodeOutlined />,
  },
];

const PROFILE_PAGES = [
  {
    featureName: "enabled",
    label: "Product Configuration",
    id: "product-config",
    key: "product-config",
    component: <ProductConfiguration />,
    icon: <SettingFilled />,
  },
  {
    featureName: "enabled",
    label: "Billings & Payments",
    id: "billings-payments",
    key: "billings-payments",
    component: <BillingsPlans />,
    icon: <DollarOutlined />,
  },

  {
    label: "Change Password",
    featureName: "enabled",
    id: "change-password",
    key: "change-password",
    component: <ChangePassword />,
    icon: <LockOutlined />,
  },
];

const COST_MANAGER_PAGES = [
  {
    label: "Current Billing Cycle",
    featureName: "CurrentBillingCycle",
    id: "current-billing-cycle",
    key: "current-billing-cycle",
    component: <Billing billingCycle="current_billing_cycle" />,
    icon: <DollarCircleOutlined />,
  },
  {
    label: "Summary",
    featureName: "LastBillingCycle",
    id: "last-billing-cycle",
    key: "last-billing-cycle",
    component: <Billing billingCycle="last_billing_cycle" />,
    icon: <HistoryOutlined />,
  },
  {
    label: "Detailed Breakup",
    featureName: "enabled",
    id: "DetailedView",
    key: "DetailedView",
    component: <DetailedView />,
    icon: <FileSearchOutlined />,
  },
  {
    label: "Quick View",
    featureName: "AdvancedSearch",
    id: "advanced-search",
    key: "advanced-search",
    component: <AdvancedSearch />,
    icon: <SearchOutlined />,
  },
  {
    label: "Cost Compare",
    featureName: "enabled",
    id: "CostCompare",
    key: "CostCompare",
    component: <BillingCompare />,
    icon: <SwapOutlined />,
  },
];

const SECURITY_PAGES = [
  {
    label: "Security Advisor",
    featureName: "enabled",
    id: "security-advisor",
    key: "security-advisor",
    icon: <SafetyCertificateOutlined />,
    component: <SecurityAdvisorNew />,
  },
  {
    label: "Compliance",
    featureName: "enabled",
    id: "compliance",
    key: "compliance",
    icon: <SafetyCertificateOutlined />,
    component: <Compliance />,
  },
];

const App = () => {
  let menuInfo = GetUserBasedSidebarMenuInformation();

  const [items, setItems] = useState([
    {
      label: "Cost Manager",
      featureName: "CostManager",
      id: "cost-manager",
      key: "cost-manager",
      icon: <DollarCircleOutlined />,
      children: COST_MANAGER_PAGES,
    },
    {
      label: "Security",
      featureName: "DisasterRecovery",
      id: "security",
      key: "security",
      icon: <SafetyCertificateOutlined />,
      children: SECURITY_PAGES,
    },
    {
      label: "Recommendations",
      featureName: "Recommendations",
      id: "recommedations",
      key: "recommedations",
      icon: <RiseOutlined />,
      component: <Recommendations />,
    },
    {
      label: "Scheduler",
      featureName: "Scheduler",
      id: "scheduler",
      key: "scheduler",
      icon: <ClockCircleOutlined />,
      component: <Scheduler />,
    },
    {
      label: "Resource Browser",
      featureName: "ResourceBrowser",
      id: "resource-browser",
      key: "resource-browser",
      icon: <GlobalOutlined />,
      component: <ResourceBrowserWrapper />,
    },
    {
      label: "Disaster Recovery",
      featureName: "DisasterRecovery",
      id: "disaster-recovery",
      key: "disaster-recovery",
      icon: <CloudServerOutlined style={{ fontSize: "18px" }} />,
      children: DISASTER_RECOVERY_PAGES,
    },
    {
      label: "Profile",
      featureName: "enabled",
      id: "profile",
      key: "profile",
      icon: <UserOutlined />,
      children: PROFILE_PAGES,
    },
  ]);

  const menuItems = getProcessedMenuItems(menuInfo, items, {});

  const [collapsed, setCollapsed] = useState(true);
  const [activeItem, setActiveItem] = useState(menuItems[0]);

  const handleMenuItemChange = ({ key }) => {
    const findItem = (items) => {
      console.log({ key, items });
      for (const item of items) {
        if (item.key === key) {
          return item;
        }
        if (item.children) {
          const childItem = findItem(item.children);
          if (childItem) {
            return childItem;
          }
        }
      }
      return null;
    };

    const currentActiveItem = findItem(menuItems);
    console.log({ currentActiveItem });
    setActiveItem(currentActiveItem);
  };

  const renderChatbotComponent = (componentName, data, visualizationType) => {
    console.log({ componentName, data });
    switch (componentName) {
      case CHATBOT_RESULT_TYPES.AWS_USAGE_COST:
        setActiveItem({
          component: (
            <AwsUsageCost data={data} visualizationType={visualizationType} />
          ),
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO:
        setActiveItem({
          component: <AwsAccessKeys key={JSON.stringify(data)} />,
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.EC2_INSTANCE_DETAILS_LIST:
        setActiveItem({
          component: (
            <EC2InstanceInfo
              key={JSON.stringify(data)}
              ec2InstancesDataMapList={data}
            />
          ),
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.EC2_INSTANCE_ACTIONS.some(
        (type) => type === componentName
      ):
        setActiveItem({
          component: (
            <Ec2InstanceStatuses key={JSON.stringify(data)} data={data} />
          ),
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.SECURITY_VIOLATIONS_LIST:
        setActiveItem({
          component: (
            <SecurityAdvisorNew key={JSON.stringify(data)} {...data} />
          ),
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.RECOMMENDATIONS:
        setActiveItem({
          component: (
            <RecommedationsContainer
              key={JSON.stringify(data)}
              recommedationData={data}
            />
          ),
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.LIST_ALL_SCHEDULES:
        setActiveItem({
          component: <Schedules key={JSON.stringify(data)} />,
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.GET_CPU_METRICS:
        setActiveItem({
          component: <CPUStatsChart params={data} />,
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.AWS_TERRAFORM_SCRIPT_CREATE:
        setActiveItem({
          component: <TerraformScript data={data} />,
          chatBotEnabled: true,
        });
        break;
      case CHATBOT_RESULT_TYPES.CHATBOT_HELP_AND_USAGE:
        setActiveItem({
          component: <ChatbotHelp data={data} />,
          chatBotEnabled: true,
        });
        break;
      default:
        break;
    }
  };

  const dashboardComponent = {
    label: "Dashboard",
    featureName: "enabled",
    id: "dashboard",
    chatBotEnabled: true,
    key: "dashboard",
    icon: <DashboardIcon />,
    component: <Dashboard handleMenuItemChange={handleMenuItemChange} />,
  };

  useEffect(() => {
    setItems([dashboardComponent, ...items]);
    setActiveItem(dashboardComponent);
  }, []);

  return (
    <div className="my-new-sidebar">
      <Suspense fallback={<div>Loading...</div>}>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            theme="dark"
            collapsible
            defaultCollapsed={true}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div style={{ padding: "17px" }}>
              <img
                style={{ width: "100%", height: "30px" }}
                src={BluefinwareLogo}
                alt=""
              />
            </div>
            <Menu
              theme="dark"
              items={menuItems}
              selectedKeys={[activeItem?.key]}
              defaultSelectedKeys={["dashboard"]}
              mode="inline"
              onClick={handleMenuItemChange}
            />
          </Sider>
          <Layout>
            <Header style={{ padding: 0, background: "#fff" }}>
              <UserProfile />
            </Header>
            <Content style={{ margin: "10px 16px" }}>
              <Suspense fallback={<div>Loading...</div>}>
                {activeItem?.component}
              </Suspense>
            </Content>
          </Layout>
        </Layout>
        {activeItem?.chatBotEnabled ? (
          <Chatbot renderChatbotComponent={renderChatbotComponent} />
        ) : null}
      </Suspense>
    </div>
  );
};

export default App;
