import { GetProductConfigurationSchedulesUrl } from '../../routes';
import { GetLoginCredentialsAsJsonObject } from '../UserCredentials';
import axios from 'axios';

export const ReadResourceItemSchedule = 'read_resource_item_schedule';
export const UpdateResourceItemSchedule = 'update_resource_item_schedule';
export const GetAllSchedulesForEc2ResourceTest = () => {
  console.log('getAllSchedulesForEc2ResourceTest ');
  GetAllSchedulesForEc2ResourceHelper('23412623452', 'ap-south-1', 'ec2_instance', 'i-8923982');
};

export const GetAllSchedulesForEc2ResourceHelper = (accountId, awsRegion, resourceType, resourceId) => {
  let commandArgs = {
    account_id: accountId,
    region: awsRegion,
    resource_item_info: {
      resource_type: resourceType,
      resource_id: resourceId,
    },
  };
  return getAllSchedulesForEc2Resource(commandArgs);
};

const getAllSchedulesForEc2Resource = (commandArgs) => {
  const url = GetProductConfigurationSchedulesUrl();
  const userCredentials = GetLoginCredentialsAsJsonObject();
  const payload = getPayload(userCredentials, ReadResourceItemSchedule, commandArgs);
  return axios.post(url, payload);
  // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
  // console.log(resp)
};

export const UpdateSchedulesForEc2ResourceTest = () => {
  console.log('getAllSchedulesForEc2ResourceTest ');
  UpdateSchedulesForEc2ResourceHelper('23412623452', 'ap-south-1', 'ec2_instance', 'i-8923982', 'SJul510', 'START');
};

export const UpdateSchedulesForEc2ResourceHelper = (accountId, awsRegion, resourceType, resourceId, scheduleId, actionType) => {
  const Ec2InstanceNode = "ec2_instance_node"
  let commandArgs = {
    account_id: accountId,
    region: awsRegion,
    resource_item_info: {
      resource_type: Ec2InstanceNode,
      resource_id: resourceId,
      action_type: actionType, // 'start' / 'shutdown'
    },
    schedule_id: scheduleId,
  };
  return UpdateSchedulesForEc2Resource(commandArgs);
};

const UpdateSchedulesForEc2Resource = (commandArgs) => {
  const url = GetProductConfigurationSchedulesUrl();
  const userCredentials = GetLoginCredentialsAsJsonObject();
  const payload = getPayload(userCredentials, UpdateResourceItemSchedule, commandArgs);
  return axios.post(url, payload);
};

const getPayload = (userCredentials, commandToExecute, commmandArgs) => {
  return {
    concerto_user_credentials: userCredentials,
    command_to_execute: commandToExecute,
    command_args: commmandArgs,
  };
};
