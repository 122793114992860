import React, { useState } from 'react';
import costManager from '../../../src/images/earning.png';
import scheduler from '../../../src/images/morning-routine.png';
import securityManger from '../../../src/images/safety.png';
import disaster from '../../../src/images/disaster-recovery.png';
import recomendations from '../../../src/images/accountability.png';
import bill from '../../../src/images/bill .png';
import ProductConfig from '../../../src/images/ProductWebmanagement.png';
import CostPredit from '../../../src/images/predictive-chart.png';
import ResourceBrowser from '../../../src/images/resourceBrowser.png';
import LastBilling from '../../../src/images/billing.png';
import '../../styles/Dashboard.css';

const Dashboard = ({ handleMenuItemChange }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const dataArray = [
    {
      onClick: () => handleMenuItemChange({ key: 'last-billing-cycle' }),
      img: costManager,
      title: 'Cost Manager',
      description: 'Cost Manager helps you slash your AWS bill with laser-focused insights and optimization magic.',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'scheduler' }),
      img: scheduler,
      title: 'Scheduler',
      description: 'Schedule tasks on AWS services with ease, so your applications run like clockwork, 24/7',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'recommedations' }),
      img: recomendations,
      title: 'Recommendations',
      description: 'Get personalized suggestions for AWS services that can take your projects to the next level.',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'security-advisor' }),
      img: securityManger,
      title: 'Security Manager',
      description:
        'Lock down your AWS environment with the Security Manager, your one-stop shop for bulletproof protection.',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'resource-browser' }),
      img: ResourceBrowser,
      title: 'Resource Browser',
      description: 'Find what you need, fast!  The Resource Browser is your key to locating and managing all your AWS resources in a snap.',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'resource-comparison' }),
      img: disaster,
      title: 'Disaster Recovery',
      description:
        'Plan and implement recovery solutions with Disaster Recovery, ensuring your applications bounce back faster than ever.',
    },
    {
      onClick: () => handleMenuItemChange({ key: 'product-config' }),
      img: ProductConfig,
      title: 'Product Configurations',
      description: 'Configure perfectly to fit your needs, for a smooth and powerful operation.',
    },
  ];

  return (
    <div className='container mt-5'>
      {selectedComponent ? (
        <div className='selected-component'>{selectedComponent}</div>
      ) : (
        <>
          <div className='mb-4'>
            <h3>Features At Your Fingertips</h3>
          </div>
          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4'>
            {dataArray.map((item, index) => (
              <div className='col mb-4' key={index}>
                <div className='custom-card card h-100 d-flex flex-column align-items-center p-3' onClick={item.onClick}>
                  <img src={item.img} className='card-img-top img-fluid' alt={item.title} style={{ maxWidth: '100%', height: 'auto' }} />
                  <div className='card-body text-center'>
                    <h5 className='card-title'>
                      <div to='#' className='custom-link'>
                        {item.title}
                      </div>
                    </h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
