import React, { useEffect, useState } from "react";
import axios from "axios";
import { Select, Button, DatePicker } from "antd";
import moment from "moment";
import "../../../styles/Filter.css";
import { GetAckSetUrl } from "../../../routes";
import { GetLoginCredentialsAsJsonObject } from "../../../components/UserCredentials";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filters = ({ onFilterChange = () => {} }) => {
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [accountId, setAccountId] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    "AWS Account": "All",
    "AWS Region": "all",
  });
  const [scanStartTimeStamp, setScanStartTimeStamp] = useState("");
  const [endStartTimeStamp, setEndStartTimeStamp] = useState("");

  const [filters, setFilters] = useState([
    {
      name: "AWS Account",
      defaultValue: "",
      options: [],
    },
    {
      name: "Assessment Date",
      defaultValue: [],
      options: [],
    },

    {
      name: "AWS Region",
      defaultValue: "all",
      options: [{ value: "all", label: "All" }],
    },
  ]);

  const handleFilterChange = (filterName, value) => {
    console.log("Filter changed:", filterName, value);

    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const start = moment(dateStrings[0], "YYYY-MM-DD").startOf("day");
      const end = moment(dateStrings[1], "YYYY-MM-DD").endOf("day");

      const formattedStart = start.format("YYYY-MM-DD HH:mm:ss");
      const formattedEnd = end.format("YYYY-MM-DD HH:mm:ss");

      setScanStartTimeStamp(formattedStart);
      setEndStartTimeStamp(formattedEnd);

      setSelectedFilters((prev) => ({
        ...prev,
        "Assessment Date": dateStrings,
      }));
    }
  };

  const handleSubmit = () => {
    const filterData = {
      ...selectedFilters,
      ScanStartTimeStamp: scanStartTimeStamp,
      EndStartTimeStamp: endStartTimeStamp,
    };

    onFilterChange(filterData);
  };

  const getAwsKeysReadPayload = () => {
    let keysReadPayload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      ack_data: {
        name: "",
        AWS_ACCOUNT_ID: "",
        AWS_ACCESS_KEY_ID: "",
        AWS_SECRET_ACCESS_KEY: "",
        DEFAULT_REGION: "",
        REGIONS: [],
        data_op: "read",
      },
    };

    return keysReadPayload;
  };

  const getAwsAccounts = async () => {
    const url = GetAckSetUrl();
    const payload = getAwsKeysReadPayload();

    try {
      const { data } = await axios.post(url, payload);
      console.log(data);
      let respData = [];
      Object.keys(data).forEach((key) => {
        if (data[key]?.AccountId) respData.push(data[key]);
      });
      setAwsAccounts(respData);
      const accountId = respData.map((filter) => filter.AccountId);
      setAccountId(accountId);
      console.log("accid", accountId);
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.name === "AWS Account"
            ? {
                ...filter,
                options: respData.map((account) => ({
                  value: account.AccountId,
                  label: account.AccountId,
                })),
              }
            : filter
        )
      );
    } catch (err) {
      console.log(err);
      console.log("getAwsKeys to " + url + " failed.");
    }
  };

  useEffect(() => {
    console.log("Effect ran, accountId:", accountId);
    getAwsAccounts();
  }, []);

  return (
    <div className="filters-container">
      {filters.map((filter) => (
        <div key={filter.name} className="filter-item">
          <label className="filter-label">{filter.name}:</label>
          {filter.name === "Assessment Date" ? (
            <RangePicker
              onChange={handleDateChange}
              className="filter-date-picker"
            />
          ) : (
            <Select
              defaultValue={filter.defaultValue}
              onChange={(value) => handleFilterChange(filter.name, value)}
              className="filter-select"
            >
              {filter.options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
        </div>
      ))}
      <Button type="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default Filters;
