import React, { useEffect, useState } from "react";
import aws_logo from "../../images/Amazon_Web_Services_Logo.svg";
import BillingConsole from "./v2/BillingConsole";
import {
  GetAckSetUrl,
  GetCostManagerV2Url,
  GetSecurityScanUrl,
} from "../../routes";
import axios from "axios";
import {
  GetCognitoUserTokenCredentials,
  GetLoginCredentialsAsJsonObject,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../Login";
import { Button, Tabs, Select } from "antd";
import "../../styles/Billing.css";
import BillingSummary from "./v2/BillingSummary.js";

const { Option } = Select;

const { TabPane } = Tabs;
const Billing = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [costDetails, setCostDetails] = useState(null);
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [selectMode, setSelectMode] = useState("multiple");
  const [selectedFilters, setSelectedFilters] = useState({
    "AWS Account": [],
  });

  //   const handleTabClick = (index) => {
  //     setActiveTab(index);
  //   };

  const handleTabClick = (key) => {
    setActiveTab(parseInt(key));
  };

  const [filters, setFilters] = useState([
    {
      name: "AWS Account",
      defaultValue: [],
      options: [],
    },
  ]);

  const handleFilterChange = async (filterName, value) => {
    console.log("Filter changed:", filterName, value);

    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const handleCostDetailsPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "",
      command_args: {
        AccountId: selectedFilters["AWS Account"],
      },
    };
  };

  const handleCostDetails = async (event) => {
    const url = GetCostManagerV2Url();
    const payload = handleCostDetailsPayload();

    try {
      const resp = await axios.post(url, payload);
      console.log(resp);
      if (resp.status === 200) {
        setCostDetails(resp.data);
        console.log(resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    }
  };

  const getAwsKeysReadPayload = () => {
    let keysReadPayload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      ack_data: {
        name: "",
        AWS_ACCOUNT_ID: "",
        AWS_ACCESS_KEY_ID: "",
        AWS_SECRET_ACCESS_KEY: "",
        DEFAULT_REGION: "",
        REGIONS: [],
        data_op: "read",
      },
    };

    return keysReadPayload;
  };

  const getAwsAccounts = async () => {
    const url = GetAckSetUrl();
    const payload = getAwsKeysReadPayload();

    try {
      const { data } = await axios.post(url, payload);
      console.log("resp", data);
      let respData = [];
      Object.keys(data).forEach((key) => {
        if (data[key]?.AccountId) respData.push(data[key]);
      });
      setAwsAccounts(respData);
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.name === "AWS Account"
            ? {
                ...filter,
                options: respData.map((account) => ({
                  value: account.AccountId,
                  label: account.AccountId,
                })),
              }
            : filter
        )
      );
    } catch (err) {
      console.log(err);
      console.log("getAwsKeys to " + url + " failed.");
    }
  };

  useEffect(() => {
    getAwsAccounts();
  }, []);
  return (
    <div>
      {costDetails ? (
        <>
          <div className="billing-tab">
            <Tabs
              className="billing-tabs"
              activeKey={String(activeTab)}
              onChange={handleTabClick}
              type="card"
              tabBarStyle={{
                color: "#333",
              }}
              onClick={handleCostDetails}
              items={[
                {
                  label: "Overview",
                  key: "0",
                   children: <BillingSummary selectedFilters={selectedFilters}/>,
                }
                // ,
                // {
                //   label: "Billing Console",
                //   key: "1",
                //   children: <BillingConsole />,
                // },
                // {
                //   label: "Tab 3",
                //   key: "2",
                //   children: "Content for Tab 3",
                // },
              ]}
            />
          </div>
        </>
      ) : (
        <>
          <div className="billing-dropdown">
            <div>
              {filters.map((filter) => (
                <div key={filter.name} className="filter-item">
                  <label className="filter-label">{filter.name}:</label>
                  <Select
                    mode={selectMode}
                    defaultValue={filter.defaultValue}
                    onChange={(value) => handleFilterChange(filter.name, value)}
                    className="filter-select"
                  >
                    {filter.options.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              ))}
            </div>
            <div>
              <Button
                onClick={handleCostDetails}
                type="primary"
                className="billing-dropdown"
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const getCloudServiceLogo = (accountType) => {
  let imgSrc = aws_logo;
  /*        if(accountType === "aws" || accountType === "") {
                    imgSrc = aws_logo
                }*/
  return <img alt="" height="50" src={imgSrc} />;
};

export default Billing;

// /*
// import {
//   CONST_BILLING_CURR_CYCLE,
//   CONST_BILLING_LAST_CYCLE,
//   CONST_BILLING_COST_PREDICTOR,
//   CONST_ADVANCED_SEARCH,
//   CONST_COST_PLANNER,
// } from './CostManagerPage';
// import { Collapse } from 'antd';
// import aws_logo from '../../images/Amazon_Web_Services_Logo.svg';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { getTooltipInfo, RoundUp } from '../CommonFunctions';
// import AdvancedSearch from '../AdvancedSearch';
// import _, { cloneDeep, isEmpty, startCase } from 'lodash';
// import * as d3 from 'd3';
// import CostPlanner from './CostPlanner/CostPlanner';
// import PieChart from './pieChart';
// import LineChart from './linechart.d3';
//
// import { LoadingOutlined } from '@ant-design/icons';
// import { Popover, Spin } from 'antd';
// import arrowDownIcon from '../../images/arrow-down.png';
// import { AWS_COST_SAMPLE_DATA } from './AwsSampleData';
// import { GetLoginCredentialsAsJsonObject } from '../UserCredentials';
// import {
//   CURRENT_MONTH_END_DATE,
//   CURRENT_MONTH_START_DATE,
//   CURRENT_WEEK_START_DATE,
//   getLastSixMonths,
//   LAST_MONTH_END_DATE,
//   LAST_MONTH_START_DATE,
//   LAST_WEEK_END_DATE,
//   LAST_WEEK_START_DATE,
//   NEXT_MONTH_END_DATE,
//   NEXT_MONTH_START_DATE,
// } from '../../dateUtil';
// import { GetAdvancedSearchURL, GetCostManagerUrl } from '../../routes';
// import axios from 'axios';
// import { LINE_CHART_MOCK_DATA } from './linechart.mock';
// import MultiLineChart from './MultiLinechart.js';
// import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
// import moment from 'moment';
// import useAxios from 'axios-hooks';
//
// const aapl = require('./linechart.mock');
//
// const antIcon = (
//   <LoadingOutlined
//     style={{
//       fontSize: 24,
//       color: '#5B9BD6',
//     }}
//     spin
//   />
// );
//
// const TEXT_INFO_TOTAL_COST_THIS_WEEK = 'Total cost from beginning of this week till today';
// const TEXT_INFO_TOTAL_COST_THIS_MONTH = 'Total cost from beginning of this month till today';
// const TEXT_INFO_TOTAL_COST_LAST_WEEK = 'Total cost incurred last week';
// const TEXT_INFO_TOTAL_COST_LAST_CYCLE = 'Total cost incurred in last cycle';
// const TEXT_INFO_TOTAL_COST_PREDICTION = 'Total cost forecasted for next cycle';
//
// export const getCloudServiceLogo = (accountType) => {
//   let imgSrc = aws_logo;
//   /*        if(accountType === "aws" || accountType === "") {
//                   imgSrc = aws_logo
//               }*/
//   return <img alt='' height='50' src={imgSrc} />;
// };
//
// const CHART_VIEWBY_OPTIONS = [
//   { label: 'This month', value: 'this_month' },
//   { label: 'This week', value: 'this_week' },
//   { label: 'Last week', value: 'last_week' },
// ];
//
// const isAwsDemoDataToBeUsed = () => {
//   return process.env.REACT_APP_USE_AWS_COST_MANAGER_DEMO_DATA === 'true';
// };
//
// const getRequestPayload = () => {
//   const payload = {
//     concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
//     command_to_execute: 'get_cost_details',
//   };
//   return payload;
// };
//
//
//
// const Billing2 = (props) => {
//   const TODAY = moment().format('YYYY-MM-DD');
//
//   const [{ data: fetchedData, loading }, fetchData] = useAxios(
//     {
//       url: GetCostManagerUrl(),
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       data: getRequestPayload(),
//     },
//     { manual: true }
//   );
//
//   useEffect(() => {
//     fetchData();
//   }, []);
//
//   const billingCycle = props.billingCycle;
//   const data = isAwsDemoDataToBeUsed() ? AWS_COST_SAMPLE_DATA : fetchedData;
//   const accountsCost = data?.account_wise_cost;
//   const totalCost = data?.all_accounts?.total_aggregate;
//
//   const [chartToggleState, setChartToggleState] = useState({});
//   const [lineGraphData, setLineGraphData] = useState(isAwsDemoDataToBeUsed() ? LINE_CHART_MOCK_DATA : {});
//
//   useEffect(() => {
//     !isAwsDemoDataToBeUsed() && getLast6MonthsCostDetails();
//   }, [data?.account_wise_cost]);
//
//   const getCurrCycleTotalCostTable = () => {
//     return (
//       <table className='costTable curr-total'>
//         <thead>
//           <tr>
//             <th> </th>
//             <th>Last week cost {getTooltipInfo(`${LAST_WEEK_START_DATE} to ${LAST_WEEK_END_DATE}`)}</th>
//             <th>Total so far this week {getTooltipInfo(`${CURRENT_WEEK_START_DATE} to ${TODAY}`)}</th>
//             <th>Total so far this month {getTooltipInfo(`${CURRENT_WEEK_START_DATE} to ${TODAY}`)}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {totalCost ? (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td> $ {RoundUp(totalCost?.last_week)}</td>
//               <td> $ {RoundUp(totalCost?.this_week)}</td>
//               <td> $ {RoundUp(totalCost?.this_month)}</td>
//             </tr>
//           ) : (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td>NA</td>
//               <td>NA</td>
//               <td>NA</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     );
//   };
//
//   const getResourceTextByCycle = (resource, resourceWiseCostByCycle) => {
//     if (resourceWiseCostByCycle && resourceWiseCostByCycle[resource] !== undefined) {
//       return '$' + RoundUp(resourceWiseCostByCycle[resource]);
//     }
//     return 'N/A';
//   };
//
//   const getCurrCycleCostTables = (accountData) => {
//     const { last_week_details, this_week_total_details, this_month_total_details } = accountData['current_billing_details'];
//     const resourceWiseLastWeek = last_week_details.ResourceWiseBreakUp;
//     const resourceWiseThisWeek = this_week_total_details.ResourceWiseBreakUp;
//     const resourceWiseThisMonth = this_month_total_details.ResourceWiseBreakUp;
//
//     let resourceKeys = [];
//     resourceKeys = resourceWiseThisWeek ? Object.keys(resourceWiseThisWeek) : [];
//     resourceKeys = resourceWiseLastWeek ? resourceKeys.concat(Object.keys(resourceWiseLastWeek)) : resourceKeys;
//     resourceKeys = resourceWiseThisMonth ? resourceKeys.concat(Object.keys(resourceWiseThisMonth)) : resourceKeys;
//
//     resourceKeys = _.uniq(resourceKeys);
//     if (loading)
//       return (
//         <div className='flex a-ic just-cont-center'>
//           <Spin indicator={antIcon} />
//         </div>
//       );
//     return (
//       <table className='costTable curr-cost'>
//         <thead>
//           <tr>
//             <th></th>
//             <th>Last week cost {getTooltipInfo(`${last_week_details?.StartDate} to ${last_week_details?.EndDate}`)}</th>
//             <th>Total cost this week {getTooltipInfo(`${this_week_total_details?.StartDate} to ${this_week_total_details?.EndDate}`)}</th>
//             <th>
//               Total cost this month {getTooltipInfo(`${this_month_total_details?.StartDate} to ${this_month_total_details?.EndDate}`)}
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {resourceKeys.map((resource) => {
//             return (
//               <tr key={accountData.friendly_name + 'cost_table_curr_' + resource}>
//                 <td>{resource}</td>
//                 <td>{getResourceTextByCycle(resource, resourceWiseLastWeek)}</td>
//                 <td>{getResourceTextByCycle(resource, resourceWiseThisWeek)}</td>
//                 <td>{getResourceTextByCycle(resource, resourceWiseThisMonth)}</td>
//               </tr>
//             );
//           })}
//           <tr>
//             <td>Tax</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].last_week_details.Tax)}</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].this_week_total_details.Tax)}</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].this_month_total_details.Tax)}</td>
//           </tr>
//           <tr className='sum-row'>
//             <td>Total Cost</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].last_week_details.Total)}</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].this_week_total_details.Total)}</td>
//             <td>$ {RoundUp(accountData['current_billing_details'].this_month_total_details.Total)}</td>
//           </tr>
//         </tbody>
//       </table>
//     );
//   };
//
//   const getLastCycleTotalCostTable = () => {
//     const totalCost = data?.all_accounts?.total_aggregate;
//
//     return (
//       <table className='costTable last-tot'>
//         <thead>
//           <tr>
//             <th> </th>
//             <th>Total cost last cycle {getTooltipInfo(`${LAST_MONTH_START_DATE} to ${LAST_MONTH_END_DATE}`)}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {totalCost ? (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td> $ {RoundUp(totalCost?.last_billing_sum)}</td>
//             </tr>
//           ) : (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td>NA</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     );
//   };
//
//   const getLastCycleCostTables = (accountData) => {
//     const resourceWiseLastMonth = accountData['previous_billing_details'].last_month_total_details.ResourceWiseBreakUp;
//     return (
//       <table className='costTable last-cost'>
//         <thead>
//           <tr>
//             <th>Total cost last month {getTooltipInfo(`${LAST_MONTH_START_DATE} to ${LAST_MONTH_END_DATE}`)}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {Object.keys(resourceWiseLastMonth).map((resource, thisWeekCost) => {
//             return (
//               <tr key={accountData.friendly_name + 'cost_table_last_' + resource}>
//                 <td>{resource}</td>
//                 <td>$ {RoundUp(resourceWiseLastMonth[resource])}</td>
//               </tr>
//             );
//           })}
//           <tr>
//             <td>Tax</td>
//             <td>$ {RoundUp(accountData['previous_billing_details'].last_month_total_details.Tax)}</td>
//           </tr>
//           <tr className='sum-row'>
//             <td>Total Cost</td>
//             <td>$ {RoundUp(accountData['previous_billing_details'].last_month_total_details.Total)}</td>
//           </tr>
//         </tbody>
//       </table>
//     );
//   };
//
//   const getCostPredictionTotal = () => {
//     const totalCost = data?.all_accounts?.total_aggregate;
//     return (
//       <table className='costTable pred-tot'>
//         <thead>
//           <tr>
//             <th> </th>
//             <th>Total cost forecasted {getTooltipInfo(TEXT_INFO_TOTAL_COST_PREDICTION)}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {totalCost ? (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td> $ {RoundUp(totalCost?.forecast_billing_sum)}</td>
//             </tr>
//           ) : (
//             <tr className='only-row sum-row'>
//               <td>Total Cost</td>
//               <td>NA</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     );
//   };
//
//   const getCostPredictionTables = (accountData) => {
//     const forecastData = accountData['forecasted_billing_details'].current_month_forecast_details;
//     const resourceWiseForecast = forecastData.ResourceWiseBreakUp;
//     return (
//       <table className='costTable pred-cost'>
//         <thead>
//           <tr>
//             <th>Total cost forecasted {getTooltipInfo(`${forecastData.StartDate} to ${forecastData.EndDate}`)}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {resourceWiseForecast
//             ? Object.keys(resourceWiseForecast).map((resource, thisWeekCost) => {
//                 return (
//                   <tr key={accountData.friendly_name + 'cost_predict_' + resource}>
//                     <td>{resource}</td>
//                     <td>$ {RoundUp(resourceWiseForecast[resource])}</td>
//                   </tr>
//                 );
//               })
//             : null}
//           <tr>
//             <td>Tax</td>
//             <td>$ {RoundUp(accountData['forecasted_billing_details'].current_month_forecast_details.Tax)}</td>
//           </tr>
//           <tr className='sum-row'>
//             <td>Total Cost</td>
//             <td>$ {RoundUp(accountData['forecasted_billing_details'].current_month_forecast_details.Total)}</td>
//           </tr>
//         </tbody>
//       </table>
//     );
//   };
//
//   const getLast6MonthsCostDetails = async () => {
//     let dateRanges = getLastSixMonths();
//     const thisMonthDate = {
//       startDate: CURRENT_MONTH_START_DATE,
//       endDate: TODAY,
//     };
//     const thisMonthTillEndDate = {
//       startDate: TODAY,
//       endDate: CURRENT_MONTH_END_DATE,
//     };
//
//     const nextMonthDate = {
//       startDate: '2023-03-29',
//       endDate: '2023-04-02',
//     };
//
//     dateRanges.push(thisMonthDate, thisMonthTillEndDate, nextMonthDate);
//
//     const accountIds = data?.account_wise_cost.map((eachAccount) => parseInt(eachAccount.account_name));
//
//     const queries = dateRanges.map((eachMonth) => {
//       const payload = {
//         concerto_user_credentials: GetLoginCredentialsAsJsonObject(), //GetLoginCredentials(),
//         query: {
//           startDate: eachMonth.startDate,
//           endDate: eachMonth.endDate,
//           accountIds,
//         },
//         command_to_execute: 'get_cost_details_advance_search',
//       };
//       return axios.post(GetAdvancedSearchURL(), payload);
//     });
//     const respData = await Promise.all(queries);
//     const processedRespData = respData?.map((eachData) => eachData?.data?.account_wise_cost);
//
//     const accountWiseCost = {};
//     accountIds?.forEach((id) => (accountWiseCost[id] = []));
//     if (accountIds?.length && processedRespData?.length)
//       for (let i = 0; i < processedRespData.length; i++) {
//         const costArray = processedRespData[i];
//         for (let j = 0; j < costArray?.length; j++) {
//           const currentCostElement = costArray[j];
//           accountWiseCost[currentCostElement.account_name].push({
//             date: `${moment(currentCostElement?.cost_breakup?.StartDate).format('DD MMM')} - ${moment(
//               currentCostElement?.cost_breakup?.EndDate
//             ).format('DD MMM')}`,
//             value: currentCostElement?.cost_breakup?.Total,
//           });
//         }
//       }
//     setLineGraphData(accountWiseCost);
//   };
//
//   const toggleChartView = (account) => {
//     let chartsToBeShown = cloneDeep(chartToggleState) || {};
//     if (chartsToBeShown.hasOwnProperty(account?.account_name))
//       chartsToBeShown[account?.account_name].showChart = !chartsToBeShown[account?.account_name].showChart;
//     else {
//       chartsToBeShown[account?.account_name] = {};
//       chartsToBeShown[account?.account_name].showChart = true;
//       chartsToBeShown[account?.account_name].viewBy = { label: 'This month', value: 'this_month' };
//     }
//     setChartToggleState(chartsToBeShown);
//   };
//
//   const onViewByChange = (option, account) => {
//     let chartsToBeShown = cloneDeep(chartToggleState) || {};
//     chartsToBeShown[account?.account_name].viewBy = option;
//     setChartToggleState(chartsToBeShown);
//   };
//
//   const getChartToggleButton = (account) => {
//     const title = !chartToggleState[account?.account_name]?.showChart ? 'VIEW CHART' : 'VIEW TABLE';
//     return (
//       <div
//         className='rdfn-search-btn inline-flex h-40p a-i-c just-cont-center p-l-r-10 cursr-pntr'
//         onClick={() => toggleChartView(account)}
//       >
//         <div className='txt-white p-l-r-10'>{title}</div>
//       </div>
//     );
//   };
//
//   const getViewByKey = (account) => {
//     if (billingCycle === CONST_BILLING_LAST_CYCLE) return 'last_month_total_details';
//     else if (billingCycle === CONST_BILLING_COST_PREDICTOR) return 'current_month_forecast_details';
//     switch (chartToggleState[account?.account_name].viewBy.value) {
//       case 'this_month':
//         return 'this_month_total_details';
//       case 'this_week':
//         return 'this_week_total_details';
//       case 'last_week':
//         return 'last_week_details';
//       default:
//         return 'this_month_total_details';
//     }
//   };
//
//   const getBillingCycleKey = () => {
//     if (billingCycle === CONST_BILLING_CURR_CYCLE) return 'current_billing_details';
//     if (billingCycle === CONST_BILLING_LAST_CYCLE) return 'previous_billing_details';
//     if (billingCycle === CONST_BILLING_COST_PREDICTOR) return 'forecasted_billing_details';
//     return 'current_billing_details';
//   };
//
//   const getViewByOptions = (account) => {
//     if (billingCycle === CONST_BILLING_LAST_CYCLE) return null;
//     else if (billingCycle === CONST_BILLING_COST_PREDICTOR) return null;
//     const content = (
//       <div>
//         {CHART_VIEWBY_OPTIONS.map((option) => {
//           let isSelected = chartToggleState[account?.account_name].viewBy.value === option.value;
//           return (
//             <div
//               className={`cursr-pntr p-l-r-10 p-t-b-2 ${isSelected ? 'bg-blue' : ''}`}
//               onClick={() => {
//                 onViewByChange(option, account);
//               }}
//             >
//               {option.label}
//             </div>
//           );
//         })}
//       </div>
//     );
//     return (
//       <div className='view-by'>
//         <Popover placement='bottomRight' content={content} trigger='click'>
//           <img src={arrowDownIcon} />
//         </Popover>
//       </div>
//     );
//   };
//
//   const getViewByLabel = (account) => {
//     if (billingCycle === CONST_BILLING_LAST_CYCLE) return 'last month';
//     else if (billingCycle === CONST_BILLING_COST_PREDICTOR) return 'predicted for current month';
//     else return chartToggleState[account?.account_name].viewBy.value.replace('_', ' ');
//   };
//
//   const getChartForAccount = (account) => {
//     let billingCycleKey = getBillingCycleKey();
//     let key = getViewByKey(account);
//     let total_aggregate_key = chartToggleState[account?.account_name]?.viewBy?.value;
//     let chartData = [],
//       calculatedTotalCost = 0;
//
//     if (account?.account_name === 'All') {
//       calculatedTotalCost = totalCost[total_aggregate_key];
//       chartData = accountsCost.map((eachAccount) => {
//         return {
//           label: eachAccount.account_name,
//           value:
//             key === 'last_month_total_details'
//               ? eachAccount?.previous_billing_details?.[key]?.Total
//               : eachAccount?.current_billing_details?.[key]?.Total,
//         };
//       });
//     } else {
//       chartData = Object.entries(account?.[billingCycleKey]?.[key]?.ResourceWiseBreakUp || []).map((kvPair) => {
//         const [key, value] = kvPair;
//         calculatedTotalCost = calculatedTotalCost + value;
//         return { label: key, value };
//       });
//       // we can even take total like total=account?.[billingCycleKey]?.[key]?.Total
//     }
//
//     return (
//       <div className='wid-95per brdr-gry'>
//         <div className='bg-blue-grad-grad flex a-i-c just-cont-sb p-t-b-20 p-l-r-5'>
//           <div className='p-l-r-20 fs-16 fw-600'></div>
//           <div className='flex a-i-c p-l-r-10'>
//             <div className='p-r-10 txt-cyan-blue fs-14'>
//               <span className='fw-400'>{`Total cost ${getViewByLabel(account)}`} : </span>
//               <span className='fw-700'>{` $ ${calculatedTotalCost.toFixed(2)}`}</span>
//             </div>
//             {getViewByOptions(account)}
//           </div>
//         </div>
//         {chartData && chartData.length ? (
//           <div>
//             <PieChart data={chartData} />
//           </div>
//         ) : (
//           <div className='flex just-cont-center p-t-b-20'>No data available</div>
//         )}
//       </div>
//     );
//   };
//
//   const renderChartOrTable = (account) => {
//     if (billingCycle === CONST_BILLING_CURR_CYCLE) {
//       if (account?.account_name === 'All') {
//         if (chartToggleState[account?.account_name]?.showChart) return getChartForAccount(account);
//         else return getCurrCycleTotalCostTable();
//       }
//       if (chartToggleState[account?.account_name]?.showChart) return getChartForAccount(account);
//       else return getCurrCycleCostTables(account);
//     }
//
//     if (billingCycle === CONST_BILLING_LAST_CYCLE) {
//       if (account?.account_name === 'All') {
//         if (chartToggleState[account?.account_name]?.showChart) return getChartForAccount(account);
//         else return getLastCycleTotalCostTable();
//       }
//       if (chartToggleState[account?.account_name]?.showChart) return getChartForAccount(account);
//       else return getLastCycleCostTables(account);
//     }
//
//     if (billingCycle === CONST_BILLING_COST_PREDICTOR) {
//       if (chartToggleState[account?.account_name]?.showChart) return getChartForAccount(account);
//       else return getCostPredictionTables(account);
//     }
//   };
//
//   const getTables = () => {
//     if (loading)
//       return (
//         <div className='flex a-i-c h-50vh just-cont-center'>
//           <Spin indicator={antIcon} />
//         </div>
//       );
//     if (billingCycle === CONST_BILLING_CURR_CYCLE || billingCycle === CONST_BILLING_LAST_CYCLE) {
//       return (
//         <>
//           <div>
//             <div className='txt-align-rt p-t-b-15 m-r-60'>{getChartToggleButton({ account_name: 'All' })}</div>
//             <div>{renderChartOrTable({ account_name: 'All' })}</div>
//           </div>
//           <br />
//           <br />
//           <div>
//             <Collapse defaultActiveKey={['1']}>
//               {accountsCost &&
//                 Object.keys(accountsCost)?.map((index, value) => {
//                   return (
//                     <CollapsePanel
//                       header={
//                         <div>
//                           {getCloudServiceLogo()} {accountsCost[index]?.friendly_name} : {accountsCost[index]?.account_name}
//                         </div>
//                       }
//                       key={(index + 1).toString()}
//                     >
//                       <div className='txt-align-rt p-t-b-15 m-r-60'>{getChartToggleButton(accountsCost[index])}</div>
//                       <div>{renderChartOrTable(accountsCost[index])}</div>
//                     </CollapsePanel>
//                   );
//                 })}
//             </Collapse>
//           </div>
//           <br />
//           <br />
//         </>
//       );
//     }
//
//     if (billingCycle === CONST_BILLING_COST_PREDICTOR) {
//       return (
//         <>
//           <div>{getCostPredictionTotal()}</div>
//           <br />
//           <br />
//           <div>
//             <Collapse defaultActiveKey={['1']}>
//               {accountsCost &&
//                 Object.keys(accountsCost)?.map((index, value) => {
//                   return (
//                     <CollapsePanel
//                       header={
//                         <div>
//                           {getCloudServiceLogo()} {accountsCost[index]?.friendly_name} : {accountsCost[index]?.account_name}
//                         </div>
//                       }
//                       key={index + 1}
//                     >
//                       <div className='txt-align-rt p-t-b-15 m-r-60'>{getChartToggleButton(accountsCost[index])}</div>
//                       <div>{renderChartOrTable(accountsCost[index])}</div>
//                     </CollapsePanel>
//                   );
//                 })}
//             </Collapse>
//           </div>
//           <br />
//           <br />
//           <div>
//             <MultiLineChart data={lineGraphData} />
//           </div>
//         </>
//       );
//     }
//
//     if (billingCycle === CONST_COST_PLANNER) {
//       return <CostPlanner />;
//     }
//
//     if (billingCycle === CONST_ADVANCED_SEARCH) {
//       return <AdvancedSearch />;
//     }
//   };
//   return (
//     <>
//       <h4>{startCase(billingCycle)}</h4>
//       {getTables()}
//     </>
//   );
// };
//
// export default Billing;
// */
