import React, { useState } from 'react';
import Filters from './Filters';
import Dashboard from './Dashboard/Dashboard';
import ListView from './ListView/ListView';

const SecurityAdvisorNew = (props) => {
  console.log({ scProp: props });
  const [currentView, setCurrentView] = useState('');
  const [listViewData, setListViewData] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleRenderListView = (status, severity, dashboardData) => {
    setListViewData({ status, severity, dashboardData });
    setCurrentView('listView');
  };

  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
    setCurrentView('dashboard');
  };
  const handleBackToDashboard = () => {
    setCurrentView('dashboard');
  };

  const renderContent = () => {
    switch (currentView) {
      case 'dashboard':
        return <Dashboard filters={selectedFilters} onFindingsClick={handleRenderListView} />;
      case 'listView':
        return <ListView data={listViewData} onBackClick={handleBackToDashboard} />;
      default:
        return '';
    }
  };

  return (
    <div>
      <Filters accIds={props.requested_accounts_list} onFilterChange={handleFilterChange} />
      <div>{renderContent()}</div>
    </div>
  );
};

export default SecurityAdvisorNew;
