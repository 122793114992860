import React from 'react';
import PlanCard from '../PlanCard/PlanCard';
import classes from './Plans.module.css';
import DolphinLogo from '../../assets/Dolphin.svg';
import { useHistory } from 'react-router-dom';

const plans = [
  // {
  //   title: '19% OF REALIZED SAVINGS',
  //   subtitle: 'Optimize Your Costs with Advanced Tools',
  //   benefits: [
  //     'Free trial for 7 days',
  //     'AWS Pricing Comparison',
  //     'Cost Planner',
  //     'Security Advisor',
  //     'Schedule & Start, Stop resources',
  //     'Get smart recommendations',
  //     'Schedule start and stop resource',
  //   ],
  // },
  {
    title: 'BASIC',
    subtitle: 'Essential Tools for Cost Management',
    benefits: [
      'Upto 1k resources',
      'Bluefinware Cloud management platform access to save AWS costs of your platform',
      'Free trial for 7 days',
      'Resource Browser',
      'Disaster recovery',
      'Take a snapshot of all your resources on a daily basis',
      'Retrieve the system settings from the snapshot',
      'Validate and compare the settings from the past date',
      // 'Retrieve and compare the system settings from the past dates as well',
    ],
  },
  {
    title: 'PRO',
    subtitle: 'Advanced Cost Management and Optimization',
    isPopular: true,
    benefits: [
      'Upto 3k resources',
      'Free trial for 7 days',
      'Bluefinware Cloud management platform access to save AWS costs of your platform',
      'Resource Browser',
      'Disaster recovery',
      'Take a snapshot of all your resources on a daily basis',
      'Retrieve the system settings from the snapshot',
      'Validate and compare the settings from the past date',
      // 'Retrieve and compare the system settings from the past dates as well',
    ],
  },
  {
    title: 'ENTERPRISE',
    subtitle: 'Comprehensive Cost Solutions for Large Enterprises',
    benefits: [
      'Upto 5k resources',
      'Free trial for 7 days',
      'Bluefinware Cloud management platform access to save AWS costs of your platform',
      'Deploy in your environment',
      'Resource Browser',
      'Disaster recovery',
      'Take a snapshot of all your resources on a daily basis',
      'Retrieve the system settings from the snapshot',
      'Validate and compare the settings from the past date',
      // 'Retrieve and compare the system settings from the past dates as well',
    ],
  },
];

const Plans = () => {
  const history = useHistory();
  return (
    <div className={classes.Plans}>
      <div className={classes.Head}>
        <div>
          <div className={classes.Title}>SELECT PLAN</div>
          <div className={classes.Subtitle}>Streamline Costs and Optimize Performance: Cloud Management Pricing</div>
        </div>
        <div className={classes.HeadRight}>
          <div className={classes.Subtitle}>Not sure about the plans?</div>
          <div className={classes.CalculateButton}>
            <div
              onClick={() => {
                history.push('contact-us');
                window.scrollTo(0, 0);
              }}
              className='new-btn-primary'
            >
              CONTACT US
            </div>
          </div>
        </div>
      </div>
      <div className={classes.CardContainer}>
        {plans.map((plan, index) => (
          <PlanCard
            key={index}
            title={plan.title}
            subtitle={plan.subtitle}
            amount={plan.amount}
            usage={plan.usage}
            benefits={plan.benefits}
            isPopular={plan.isPopular}
          />
        ))}
        <div className={classes.ContactUs}>
          <div className={classes.PlanSubtitle}>Not sure about the plans?</div>
          <div className={classes.PlanDesc}>
            Thank you for your interest! If you have any questions or would like more information, please don't hesitate to contact our customer
            support team. We're here to provide you with the assistance you need to make an informed decision.
          </div>
          <div className={classes.DolphinLogo}>
            <img src={DolphinLogo} alt='' />
          </div>
          <div className={classes.PlanCalculateButton}>
            <div>CONTACT US</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
