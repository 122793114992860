import React, { createContext, useState, useCallback, useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import '../styles/ConfirmationModal.css';

export const ConfirmationModalContext = createContext();

const ConfirmationModal = ({ content, onConfirm, closeModal, title = null }) => {
  console.log({ title });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (content !== null) {
      setVisible(true);
    }
  }, [content]);

  const handleOk = useCallback(() => {
    onConfirm();
    closeModal();
  }, [onConfirm, closeModal]);

  const handleCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal open={visible} title={title} footer={null} closable={false} maskClosable={false} centered>
      {typeof content === 'string' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExclamationCircleFilled style={{ fontSize: '3vw', color: '#faad14', marginRight: '1vw' }} />
          <div style={{ fontSize: '1.4vw', fontWeight: 'bold', color: '#333' }}>{content}</div>
        </div>
      ) : (
        content
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2vw' }}>
        <Button onClick={handleCancel} style={{ marginRight: '2vw' }}>
          Cancel
        </Button>
        <Button type='primary' onClick={handleOk}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmationModalProvider = ({ children }) => {
  const [content, setContent] = useState(null);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [additionalProps, setAdditionalProps] = useState({});

  const openModal = useCallback((content, onConfirm, additionalProps) => {
    setContent(content);
    setOnConfirm(() => onConfirm);
    setAdditionalProps(additionalProps || {});
  }, []);

  const closeModal = useCallback(() => {
    setContent(null);
    setOnConfirm(() => {});
  }, []);

  return (
    <ConfirmationModalContext.Provider value={{ openModal }}>
      {children}
      {content && <ConfirmationModal content={content} onConfirm={onConfirm} closeModal={closeModal} {...additionalProps} />}
    </ConfirmationModalContext.Provider>
  );
};
