import React, { useState } from 'react';
import { Button, Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { RoundUp } from '../../../components/CommonFunctions';
import { getCloudServiceLogo } from '../../../components/CostManager/Billing';
import ApexPieChart from '../../../components/CostManager/pieChart';
import { Dropdown, Menu } from 'antd';
import { startCase } from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import BarChart from '../../../components/CostManager/Charts/BarChart';

const CostTable = ({ resourceWiseBreakUp, accountData }) => {
  return (
    <table className='costTable last-cost'>
      <thead>
        <tr>
          <th>Resource</th>
          <th>Cost (USD)</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(resourceWiseBreakUp).map((resource) => (
          <tr key={`${accountData.friendly_name}_cost_table_last_${resource}`}>
            <td>{resource}</td>
            <td>$ {RoundUp(resourceWiseBreakUp[resource])}</td>
          </tr>
        ))}
        <tr>
          <td>Tax</td>
          <td>$ {RoundUp(accountData.cost_breakup.Tax)}</td>
        </tr>
        <tr className='sum-row'>
          <td>Total Cost</td>
          <td>$ {RoundUp(accountData.cost_breakup.Total)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const getChartComponent = (chartType, chartData) => {
  console.log({ chartType, chartData });
  switch (chartType) {
    case 'pie':
      return (
        <ApexPieChart
          data={[...chartData]}
          type={'line'}
          height={'85%'}
          options={{
            chart: { height: '100%' },
            legend: { position: 'bottom', horizontalAlign: 'left', show: true, fontSize: '10px' },
          }}
        />
      );
    case 'bar':
      return <BarChart data={chartData} />;

    default:
      break;
  }
};

const PieChartComponent = ({ resourceWiseBreakUp, accountData }) => {
  const [chartType, setChartType] = useState('pie');

  const calculatedTotalCost = RoundUp(accountData.cost_breakup.Total);

  const handleMenuClick = (e) => {
    setChartType(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='pie'>Pie Chart</Menu.Item>
      <Menu.Item key='bar'>Bar Chart</Menu.Item>
    </Menu>
  );

  let chartData = [];

  switch (chartType) {
    case 'bar':
    case 'pie':
      chartData = Object.entries({ ...resourceWiseBreakUp }).map((kvPair) => {
        const [label, value] = kvPair;
        return { label, value: parseFloat(value).toFixed(2) };
      });
      break;

    default:
      break;
  }

  return (
    <div className='wid-95per brdr-gry' style={{ height: '100%' }}>
      <div className='bg-blue-grad-grad flex a-i-c just-cont-sb p-t-b-20 p-l-r-5 w-100'>
        <div>
          <Dropdown overlay={menu} placement='bottomRight' arrow>
            <Button style={{ display: 'flex', alignItems: 'center' }}>
              {chartType ? startCase(chartType + ' Chart') : 'Chart Type'} <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className='p-r-10 txt-cyan-blue fs-14'>
          <span className='fw-400'>{`Total cost`} : </span>
          <span className='fw-700'>{` $ ${calculatedTotalCost}`}</span>
        </div>
      </div>
      {chartData && chartData.length ? (
        getChartComponent(chartType, chartData)
      ) : (
        <div className='flex just-cont-center p-t-b-20'>No data available</div>
      )}
    </div>
  );
};

const getUiComponent = (accountData, uiType) => {
  const processedData = Object.fromEntries(
    Object.entries({ ...accountData.cost_breakup.ResourceWiseBreakUp, Tax: accountData.cost_breakup.Tax })
      .filter(([, value]) => value !== 0)
      .sort(([, a], [, b]) => b - a)
  );

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 5 }}>
        <CostTable resourceWiseBreakUp={processedData} accountData={accountData} />;
      </div>
      <div style={{ flex: 5, height: '700px' }}>
        <PieChartComponent resourceWiseBreakUp={processedData} accountData={accountData} />
      </div>
    </div>
  );
};

const AwsUsageCost = ({ data, visualizationType }) => {
  const accountWiseCost = [...(data?.account_wise_cost || [])];
  if (!accountWiseCost?.length) return null;

  const total_cost = {
    cost_breakup: {
      ResourceWiseBreakUp: {},
      Tax: 0,
      Total: 0,
    },
  };

  accountWiseCost.forEach((account) => {
    const resourceBreakUp = account.cost_breakup.ResourceWiseBreakUp;

    for (const resource in resourceBreakUp) {
      if (resourceBreakUp.hasOwnProperty(resource)) {
        total_cost.cost_breakup.ResourceWiseBreakUp[resource] =
          (total_cost.cost_breakup.ResourceWiseBreakUp[resource] || 0) + resourceBreakUp[resource];
      }
    }

    total_cost.cost_breakup.Tax += account.cost_breakup.Tax;
    total_cost.cost_breakup.Total += account.cost_breakup.Total;
  });

  return (
    <div>
      <Collapse defaultActiveKey={['1']}>
        <CollapsePanel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getCloudServiceLogo()} &nbsp;&nbsp;
                <h6>{`Total Cost`}</h6>
              </div>
            </div>
          }
          key={'totalCostCom'.toString()}
        >
          <div>{getUiComponent(total_cost, visualizationType)}</div>
        </CollapsePanel>
      </Collapse>

      <Collapse defaultActiveKey={['1']}>
        {accountWiseCost.map((account, idx) => (
          <CollapsePanel
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {getCloudServiceLogo()} &nbsp;&nbsp;
                  {`${account.friendly_name} : ${account.account_name}`}
                </div>
                <div style={{ margin: 'auto 1px auto auto' }}>
                  <h6>Period: {`${account.cost_breakup.StartDate} to ${account.cost_breakup.EndDate}`}</h6>
                </div>
              </div>
            }
            key={(idx + 1).toString()}
          >
            <div>{getUiComponent(account, visualizationType)}</div>
          </CollapsePanel>
        ))}
      </Collapse>
    </div>
  );
};

export default AwsUsageCost;
