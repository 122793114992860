import { convertToFixedDigitsOrReturnNA } from '../../../utils/intHelper';
const getVolumeId = (arn) => arn.match(/volume\/([a-zA-Z0-9_-]+)/)?.[1];

export const EbsVolumeRecommendationInfoFormat = [
  { column: 'Volume Id', key: 'VolumeArn', dataProcessFn: getVolumeId },
  { column: 'Current Utilization Status', key: 'Finding' },
  {
    column: 'Current Configuration',
    key: 'CurrentConfiguration',
    dataProcessFn: (obj) => (
      <div>
        <>Volume Type: </>
        {obj?.VolumeType}{' '}
      </div>
    ),
    width: '150px',
  },
  {
    column: 'Suggested Recommendations',
    key: 'VolumeRecommendationOptions',
    subTableData: [
      {
        column: 'Configuration',
        key: 'Configuration',
        dataProcessFn: (obj) => (
          <div>
            <>Volume Type: </>
            {obj?.VolumeType}{' '}
          </div>
        ),
        width: 300,
      },
      { column: 'Performance Risk', key: 'PerformanceRisk' },
      // { column: 'Rank', key: 'Rank' },
      {
        column: 'Estd Savings(IN USD)',
        key: 'SavingsOpportunityAfterDiscounts.EstimatedMonthlySavings.Value',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
      {
        column: 'Estd Savings(IN %)',
        key: 'SavingsOpportunityAfterDiscounts.SavingsOpportunityPercentage',
        dataProcessFn: (i) => convertToFixedDigitsOrReturnNA(i, 4),
      },
      { column: 'Platform Differences', key: 'PlatformDifferences', dataProcessFn: (i) => i?.join(','), width: 300 },
    ],
  },
];
