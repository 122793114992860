import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ data }) => {
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '95%',
        endingShape: 'rounded',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: data.map((item) => item.label),
      fontSize: 10,
    },
    yaxis: {
      title: {
        text: 'Values',
      },
    },
  };

  const series = [
    {
      name: 'Values',
      data: data.map((item) => item.value),
    },
  ];

  return <Chart options={options} series={series} type='bar' height={'450'} />;
};

export default BarChart;
