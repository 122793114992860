import React from "react";
import { Table } from "antd";
import "../../../../styles/DataTable.css";

const getStatusColor = (status) => {
  switch (status) {
    case "PASS":
      return "green";
    case "FAIL":
      return "red";
    case "MANUAL":
      return "darkblue";
    default:
      return "black";
  }
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "Low":
      return "blue";
    case "Medium":
      return "brown";
    case "High":
      return "red";
    case "Critical":
      return "lightcoral";
    default:
      return "black";
  }
};

const columns = [
  {
    title: "Account ID",
    dataIndex: "account_id",
    key: "accountId",
    className: "custom-header",
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    className: "custom-header",
  },

  {
    title: "Check ID",
    dataIndex: "check_id",
    key: "checkId",
    className: "custom-header",
  },
  {
    title: "Check Title",
    dataIndex: "requirements_attributes_name",
    key: "checkTitle",
    className: "custom-header",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    className: "custom-header",
    render: (text) => (
      <span
        className={`status-text ${text.toLowerCase()}`}
        style={{ color: getStatusColor(text) }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Status Details",
    dataIndex: "status_extended",
    key: "statusDetails",
    className: "custom-header",
  },
  {
    title: "Severity",
    dataIndex: "requirements_attributes_level_of_risk",
    key: "severity",
    className: "custom-header",
    render: (text) => (
      <span
        className={`severity-text ${text.toLowerCase()}`}
        style={{ color: getSeverityColor(text) }}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    className: "custom-header",
  },
  {
    title: "Resource Name",
    dataIndex: "resource_name",
    key: "resourceName",
    className: "custom-header",
  },
  {
    title: "Resource ID",
    dataIndex: "resource_id",
    key: "resourceId",
    className: "custom-header",
  },
];

const DataTable = ({ data, loading }) => {
  return (
    <div className="table-container">
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ position: "topRight" }}
        className="custom-table"
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
