import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/LeftMenu.css';
import { useLocation } from 'react-router-dom';
import homeIcon from '../images/i-home.png';
import settingsIcon from '../images/i-settings.png';
import billingIcon from '../images/i-billing.png';
import Logo from '../images/logo.png';
import { GetUserLoginCredentials } from './UserCredentials';

function LeftMenu() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const userCreds = GetUserLoginCredentials()[0];

  return (
    <>
      <div className='left-menu-links'>
        <div className='d-flex flex-column mb-5'>
          <h5>{userCreds.displayName}</h5>
          <span>{userCreds.email}</span>
        </div>
        <Link to={`/resource-maker`} className={'link' + (splitLocation[2] === 'aws-authentication' ? ' active' : '')}>
          <i className='bi bi-house'></i> Dashboard
        </Link>

        <div className='company d-flex'>
          <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />
          <span className='copy'>
            Bluefinware Inc.
            <br /> Copywright
          </span>
        </div>
      </div>
    </>
  );
}

export default LeftMenu;
