import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Logo from '../images/logo_light.svg';
import azureDiagram from '../images/sample-diagram.svg';
import awsDiagram from '../images/aws-example.png';
import '../styles/menu.css';
import UserProfile from './UserProfile';

export const getLogo = () => {
  return <img src={Logo} width='49' height='32' className='d-inline-block align-top' alt='React Bootstrap logo' />;
};

export const getBrand = () => {
  return (
    <>
      {getLogo()}
      {/* BluefinWare */}
    </>
  );
};

export const getNavBarTheme = (isLight = true) => {
  return isLight ? 'light' : 'dark';
};
const loginSignup = (prop) => {
  const authType = process.env.REACT_APP_AUTH_PROVIDER;
  let loginUrl, signupUrl;
  switch (authType) {
    case 'FIREBASE':
      loginUrl = '/login';
      signupUrl = '/signup';
      break;
    case 'COGNITO_USER_POOL':
      loginUrl = '/cguplogin';
      signupUrl = '/cgupsignup';
      break;
    default:
      loginUrl = '/cguplogin';
      signupUrl = '/cgupsignup';
  }
  if (authType === 'FIREBASE') {
    loginUrl = '/login';
    signupUrl = '/signup';
  }
  if (authType === 'COGNITO_USER_POOL') {
  }
  if (prop !== 'user_logged_in') {
    return (
      <div className='signup-signin'>
        <Nav.Link as={Link} to={loginUrl}>
          Login
        </Nav.Link>
        <Nav.Link as={Link} to={signupUrl}>
          Signup
        </Nav.Link>
      </div>
    );
  } else {
    return (
      <span>
        <UserProfile />
      </span>
    );
  }
};

export const GetMenuBar = (prop, tabs) => {
  return (
    <div>
      <Navbar bg={getNavBarTheme()} variant={getNavBarTheme()}>
        <Container className='small'>
          <Nav.Link as={Link} to={'/home'} target='_blank'>
            {getBrand()}
          </Nav.Link>
          <div className='menubar-tabs' style={{ flex: 3 }}>
            {prop === 'user_logged_in' && tabs?.()}
          </div>
          <Nav>
            {prop !== 'user_logged_in' ? (
              <>
                <Nav.Link as={Link} to={'/resource-maker'}>
                  See it Live!
                </Nav.Link>
                <Nav.Link as={Link} to={'/usecases'}>
                  Usecases
                </Nav.Link>
                <Nav.Link as={Link} to={'/pricing'}>
                  Pricing
                </Nav.Link>
                <Nav.Link as={Link} to={'/security'}>
                  Security
                </Nav.Link>
                <Nav.Link as={Link} to={'/contact-us'}>
                  Contact Us
                </Nav.Link>
              </>
            ) : null}
            {loginSignup(prop)}
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

const resourceEditorExampleImage = (image, descriptionText, altText) => {
  return (
    <Container className='mt-5 align-me'>
      <h1> {descriptionText} </h1>
      <img src={image} width='75%' height='75%' className='mt-2' alt={altText} />
    </Container>
  );
};

export const GetDrawingBoardSamplePictures = () => {
  return (
    <div className='text-center'>
      {resourceEditorExampleImage(awsDiagram, 'Draw AWS Diagrams, create resources visually', 'Aws example')}
      {resourceEditorExampleImage(azureDiagram, 'Draw Azure Diagrams, create resources visually', 'Azure example')}
      <br /> <br />
    </div>
  );
};

//
//
