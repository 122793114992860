import uniqid from 'uniqid';
import '../styles/ContextBar.css';
import { PROVIDER_AWS } from './ResourceEditorCanvas';
import React, { useState, createRef } from 'react';
import { DispatchAction, GetState, Subscribe } from './statemanagement/store';
import * as actions from './statemanagement/ActionsList';
import { ButtonGroup, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS, RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS } from './statemanagement/ActionsList';
import { FindLinksHandler } from './NodesLinkHandlerMap';
import { setAwsResourceHandler, SetAwsResourceHandlerForAutoDiagramcontext } from './statemanagement/ResourceContextSetter';
import { Tabs } from 'antd';
import { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { getCurrentActiveContextBarNodeInfo } from './ContextBarHandlerBuilder';
import { rdsContextBarItems } from './AwsContextBarHandlersImpl/RdsContextBar';
import { ec2ContextBarHandler } from './AwsContextBarHandlersImpl/Ec2ContextBarHandler';
import { lambdaContextBarHandler } from './AwsContextBarHandlersImpl/LambdaContextBarHandlers';

let contextContent_G = null;
let setContextContent_G = null;

const handleResourceNodeSelectedInEditorCanvas = (state) => {
  /*
    console.log("handleResourceNodeSelectedInEditorCanvas ............")
    console.log(state.contextBarInfo.payload)
    console.log("handleResourceNodeSelectedInEditorCanvas ------------")*/

  if (state.contextBarInfo && state.contextBarInfo.payload) {
    /*        console.log("yes     if (state.contextBarInfo && state.contextBarInfo.payload) ")*/
    let contextBarInfoPayload = state.contextBarInfo.payload;
    /*        console.log(contextBarInfoPayload)*/
    setContextBarHandler(contextContent_G, setContextContent_G, contextBarInfoPayload);
  } else {
    console.log('handleResourceNodeSelectedInEditorCanvas:' + ' FAILED check if (state.contextBarInfo && state.contextBarInfo.payload)');
  }
};

const handleResourceNodesLinkSelectedInEditorCanvas = (state) => {
  /*
    console.log("handleResourceNodesLinkSelectedInEditorCanvas ............")
    console.log(state.contextBarInfo.payload)
    console.log("handleResourceNodesLinkSelectedInEditorCanvas ------------")*/

  if (state.contextBarInfo && state.contextBarInfo.payload) {
    /* console.log("yes     if (state.contextBarInfo && state.contextBarInfo.payload) ")*/
    let contextBarInfoPayload = state.contextBarInfo.payload;
    setContextBarHandler(contextContent_G, setContextContent_G, contextBarInfoPayload);
  } else {
    console.log(
      'handleResourceNodesLinkSelectedInEditorCanvas:' + ' FAILED check if (state.contextBarInfo && state.contextBarInfo.payload)'
    );
  }
};

const onStoreStateChanged_G = () => {
  //console.log(currentAction)
  // console.log("ContextBar.js: onStoreStateChanged_G")
  let state = GetState();

  if (state.currentAction.type === RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS) {
    handleResourceNodeSelectedInEditorCanvas(state);
  } else if (state.currentAction.type === RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS) {
    handleResourceNodesLinkSelectedInEditorCanvas(state);
  }
};

const unsubscribe_G = Subscribe(onStoreStateChanged_G);

const initialContextBarContent = () => {
  return <></>;
};

const INITIAL_CONTEXT_BAR_CONTENT = {
  contextHandlerFunc: initialContextBarContent,
  assetHandlerFunc: initialContextBarContent,
  flowHandlerFunc: initialContextBarContent,
  contextActionHandlerFunc: initialContextBarContent,
  getLatestResourceFunc: initialContextBarContent,
  getAssetPerformanceFunc: initialContextBarContent,
};

let currentContextBarContent = INITIAL_CONTEXT_BAR_CONTENT;
export const GetNewTextInputItem = (_id, _label, _placeholder, _defaultvalue = '') => {
  return {
    id: _id,
    elementType: 'text',
    label: _label,
    placeholder: _placeholder,
    defaultvalue: _defaultvalue,
  };
};

export const GetNewDropDownItem = (_id, _label, _dropdownitems, _onSelectHandler, _defaultvalue = '') => {
  return {
    id: _id,
    elementType: 'dropdown',
    label: _label,
    dropdownitems: _dropdownitems,
    onSelectHandler: _onSelectHandler,
    defaultvalue: _defaultvalue,
  };
};

export const GetNewButtonGroup = (_buttongroupName, buttonsList) => {
  return {
    elementType: 'buttongroup',
    buttons: buttonsList,
  };
};

export const GetNewButton = (_id, _text, _variant, _type, _onClickHandler) => {
  return {
    id: _id,
    elementType: 'button',
    text: _text, // button label
    variant: _variant,
    type: _type,
    onClickHandler: _onClickHandler,
  };
};

const setContextBarHandler = (contextContent, setContextContent, contextBarInfoPayload) => {
  if (contextBarInfoPayload.what === actions.RESOURCE_NODE_SELECTED_IN_EDITOR_CANVAS) {
    onResourceNodeSelectedInEditorCanvas(contextContent, setContextContent, contextBarInfoPayload);
  } else if (contextBarInfoPayload.what === actions.RESOURCE_NODES_LINK_SELECTED_IN_EDITOR_CANVAS) {
    onResourceNodesLinkSelectedInEditorCanvas(contextContent, setContextContent, contextBarInfoPayload);
  }
};

const formTextElementHandler = (elementDefinition, refsMap) => {
  // console.log("formTextElementHandler")
  // console.log(elementDefinition)
  const elementRef = refsMap[elementDefinition.id];

  return (
    <Form.Group className='mb-1' id={elementDefinition.id}>
      <Form.Label>{elementDefinition.label}</Form.Label>
      <Form.Control
        type='text'
        ref={elementRef}
        placeholder={elementDefinition.placeholder}
        defaultValue={elementDefinition.defaultvalue}
      />
    </Form.Group>
  );
};

export const formDropDownElementHandler = (elementDefinition, refsMap) => {
  return (
    <DropdownButton
      alignRight
      title={elementDefinition.label}
      id='dropdown-menu-align-right'
      onSelect={elementDefinition.onSelectHandler}
      defaultValue={elementDefinition.defaultvalue}
    >
      {elementDefinition.dropdownitems.map((runtimeItem, index) => (
        <Dropdown.Item eventKey={runtimeItem} key={index}>
          {' '}
          {runtimeItem}{' '}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

const formButtonElementHandler = (elementDefinition, refsMap) => {
  return (
    <Button
      className='btn-primary-spacing'
      variant={elementDefinition.variant}
      type={elementDefinition.type}
      onClick={elementDefinition.onClickHandler}
    >
      {elementDefinition.text}
    </Button>
  );
};

export const formButtonGroupElementHandler = (contextBarDefinition, refsMap, formSubmitHandler) => {
  const buttons = contextBarDefinition.buttons;
  return (
    <ButtonGroup aria-label='Basic example'>
      {buttons.map((elementDefinition, index) => (
        <Button
          key={index}
          className='btn-primary-spacing'
          variant={elementDefinition.variant}
          type={elementDefinition.type}
          onClick={elementDefinition.onClickHandler}
        >
          {elementDefinition.text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export const GetNewContextBarContentForm = (contextBarDefinition, refsMap, formSubmitHandler, otherElements) => {
  const elementHandlerMap = {
    text: formTextElementHandler,
    button: formButtonElementHandler,
    dropdown: formDropDownElementHandler,
    buttongroup: formButtonGroupElementHandler,
  };

  const contextBarDefinitionMarkup = (
    <Form onSubmit={formSubmitHandler}>
      {contextBarDefinition.map((element, index) => (
        <div key={uniqid()} className='contextBarElement'>
          {' '}
          {elementHandlerMap[element.elementType](element, refsMap)}{' '}
        </div>
      ))}

      {otherElements.map((elementFunction, index) => (
        <div> {elementFunction()} </div>
      ))}
    </Form>
  );

  return contextBarDefinitionMarkup;
};

export const CreateRefsForContextBarForm = (contextBarDefinition) => {
  let refsMap = {};
  contextBarDefinition.map((elementDefinition) => {
    refsMap[elementDefinition.id] = createRef();
  });
  return refsMap;
};

const handleAwsResourceContextForAutoDiagramcontext = (
  contextContent,
  setContextContent,
  contextBarInfoPayload,
  currentContextBarContent
) => {
  SetAwsResourceHandlerForAutoDiagramcontext(contextContent, setContextContent, contextBarInfoPayload, currentContextBarContent);
};

const handleAwsResourceContext = (contextContent, setContextContent, contextBarInfoPayload, subgroup_1, currentContextBarContent) => {
  console.log('handleAwsResourceContext ' + '"' + subgroup_1 + '"');
  setAwsResourceHandler(contextContent, setContextContent, contextBarInfoPayload, subgroup_1, currentContextBarContent);
};

export const getCurrentContextBarLinkInfo = () => {
  let state = GetState();

  if ('contextBarInfo' in state) {
    console.log('return state.contextBarInfo.payload');
    return state.contextBarInfo.payload;
  }
  console.log('asdfa');
  return null;
};

export const getCurrentContextBarNodeInfo = () => {
  let state = GetState();
  console.log(state);
  if ('contextBarInfo' in state) {
    console.log('getCurrentContextBarNodeInfo:: if ("contextBarInfo" in state) {');
    return GetState().contextBarInfo.payload;
  }
  console.log('getCurrentContextBarNodeInfo:: return null');
  return null;
};

const onResourceNodeSelectedInEditorCanvas = (contextContent, setContextContent, contextBarInfoPayload) => {
  // console.log(contextBarInfoPayload)
  //
  // console.log("########## onResourceNodeSelectedInEditorCanvas")
  handleAwsResourceContextForAutoDiagramcontext(contextContent, setContextContent, contextBarInfoPayload);
  return;

  let node_data = contextBarInfoPayload.node_data;
  let node_hint = node_data.node_hint;
  let resource_details = node_hint.resource_details;
  let provider = resource_details.provider;
  let subgroup_1 = resource_details.subgroup_1;

  if (provider === PROVIDER_AWS) {
    //console.log("########## onResourceNodeSelectedInEditorCanvas provider === PROVIDER_AWS")
    handleAwsResourceContext(contextContent, setContextContent, contextBarInfoPayload, subgroup_1);
  } else {
    //console.log("########## onResourceNodeSelectedInEditorCanvas provider !== PROVIDER_AWS")
  }

  // console.log("###### subgroup_1 ")
  console.log(subgroup_1);
};

const getResourceType = (node_hint) => {
  let resource_details = node_hint.resource_details;
  // console.log(resource_details)
  let group = resource_details.group;
  let provider = resource_details.provider;
  let subgroup_1 = resource_details.subgroup_1;

  let resourceType = provider + group + subgroup_1;

  return resourceType;
  // return ""
};

const getNodesLinkHandler = (fromNodeHint, toNodeHint) => {
  let fromNodeType = getResourceType(fromNodeHint);
  let toNodeType = getResourceType(toNodeHint);
  // console.log(fromNodeType)
  // console.log(toNodeType)
  let linkHandler = FindLinksHandler(fromNodeType, toNodeType);

  return linkHandler;
};

// awscomputelambda
// awsnetworkingandcontentdeliveryapigateway

const onResourceNodesLinkSelectedInEditorCanvas = (contextContent, setContextContent, contextBarInfoPayload) => {
  console.log('onResourceNodesLinkSelectedInEditorCanvas');
  // console.log("contextBarInfoPayload")
  // console.log("^^^^^^^^^^^^")
  // console.log(contextBarInfoPayload)

  let node_link_data = contextBarInfoPayload.node_link_data;

  let fromNodeID = node_link_data.from;
  let toNodeID = node_link_data.to;

  // console.log("####$$$$$")
  // console.log(contextBarInfoPayload)

  let fromNodeData = contextBarInfoPayload.from_node_data;
  let toNodeData = contextBarInfoPayload.to_node_data;

  //
  // console.log("####$$$$$(*******(*((((((((((((((")
  // console.log(fromNodeData)
  // console.log(toNodeData)

  let fromNodeHint = fromNodeData.node_hint;
  let toNodeHint = toNodeData.node_hint;
  let linkHandler = getNodesLinkHandler(fromNodeHint, toNodeHint);

  currentContextBarContent = {
    contextHandlerFunc: linkHandler,
  };
  setContextContent(currentContextBarContent);
};

const initialContextBarStateStore = {
  LambdaContextBarContentHandlerStore: {
    cliCmd: '',
  },
  CustomWidgetDataStore: {},
};

const NODE_COMPONENTS = {
  rds_db_node: (node_info) =>
    rdsContextBarItems?.map((item, i) => ({
      ...item,
      children: React.cloneElement(item.children, { node_info }),
      key: (i + 1).toString(),
    })),

  ec2_instance_node: (node_info) =>
    ec2ContextBarHandler?.map((item, i) => ({
      ...item,
      children: React.cloneElement(item.children, { node_info }),
      key: (i + 1).toString(),
    })),

  aws_lambda_node: (node_info) =>
    lambdaContextBarHandler?.map((item, i) => ({
      ...item,
      children: React.cloneElement(item.children, { node_info }),
      key: (i + 1).toString(),
    })),
};

const ContextBar = ({ toggleExpandView, expanded }) => {
  let nodeInfo = getCurrentActiveContextBarNodeInfo();
  const [contextContent, setContextContent] = useState(currentContextBarContent);
  const [contextBarStateStore, setContextBarStateStore] = useState(initialContextBarStateStore);
  const [latestResourceData, setLatestResourceData] = useState({});

  const currentNodeComponents = NODE_COMPONENTS?.[nodeInfo?.node_data?.nodeType]?.(nodeInfo);

  contextContent_G = contextContent;
  setContextContent_G = setContextContent;

  const getLatestResourceData = async () => {
    const { data } = (await contextContent?.getLatestResourceFunc?.()) || {};
    setLatestResourceData(data);
  };

  useEffect(() => {
    return () =>
      DispatchAction(actions.CONTEXT_BAR_CLOSE_BUTTON_CLICKED, {
        what: actions.CONTEXT_BAR_CLOSE_BUTTON_CLICKED,
      });
  }, []);

  useEffect(() => {
    if (!currentNodeComponents?.length) getLatestResourceData();
  }, [contextContent.getLatestResourceFunc, contextContent.getAssetPerformanceFunc, nodeInfo]);

  const handleCloseButtonClicked = () => {
    DispatchAction(actions.CONTEXT_BAR_CLOSE_BUTTON_CLICKED, {
      what: actions.CONTEXT_BAR_CLOSE_BUTTON_CLICKED,
    });
  };

  return (
    <>
      <div className='contextbarbackground'>
        <Tabs
          tabBarExtraContent={<CloseOutlined className='close-icon' onClick={handleCloseButtonClicked} />}
          defaultActiveKey='1'
          items={
            currentNodeComponents?.length
              ? currentNodeComponents
              : [
                  {
                    key: '1',
                    label: 'INFO',
                    children: <Col>{contextContent.contextHandlerFunc(contextBarStateStore, setContextBarStateStore)}</Col>,
                  },
                  contextContent.contextActionHandlerFunc && {
                    key: '2',
                    label: 'ACTION',
                    children: (
                      <Col>
                        {contextContent.contextActionHandlerFunc(
                          contextBarStateStore,
                          setContextBarStateStore,
                          latestResourceData,
                          getLatestResourceData
                        )}
                      </Col>
                    ),
                  },
                ]
          }
        />

        <div style={{ height: '100%', margin: '1vw 0' }}>
          <button className='collapse-button' onClick={toggleExpandView}>
            {expanded ? 'Collapse' : 'Expand'}
          </button>
        </div>
      </div>
    </>
  );
};

export default ContextBar;
