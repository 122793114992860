import base64 from 'base-64';
import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

const CPUUsageChart = ({ encodedData }) => {
  console.log({ encodedData });
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'line',
        height: 350,
      },
      title: {
        text: 'CPU Usage Prediction',
        align: 'left',
      },
      stroke: {
        width: 1.5, // Adjust the width to make the line thinner
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'CPU Usage Percentage %',
        },
      },
      legend: {
        position: 'top',
      },
    },
  });

  useEffect(() => {
    // Function to decode base64 and parse CSV
    const parseCSVData = (base64String) => {
      const decodedString = base64.decode(base64String);
      const rows = decodedString.split('\n');
      const data = rows.map((row) => row.split(','));
      return data;
    };

    // Assuming encodedData is an array of base64 encoded CSV strings
    const [resampledData, testData, testSelfPrediction, futurePrediction] = [
      parseCSVData(encodedData.ec2_cpu_ai_data?.[0]?.fileContent),
      parseCSVData(encodedData.ec2_cpu_ai_data?.[1]?.fileContent),
      parseCSVData(encodedData.ec2_cpu_ai_data?.[2]?.fileContent),
      parseCSVData(encodedData.ec2_cpu_ai_data?.[3]?.fileContent),
    ];

    console.log({ resampledData, testData, testSelfPrediction, futurePrediction });
    // Transform data for ApexCharts
    const transformData = (dataArray) => {
      return dataArray
        .filter((row) => row.length === 2 && row[0] && row[1]) // Filter out invalid rows
        .map((row) => ({
          x: new Date(row[0]),
          y: parseFloat(row[1]).toFixed(2),
        }))
        .filter((item) => !isNaN(item.y)); // Filter out any invalid data points
    };

    setChartData({
      ...chartData,
      series: [
        {
          name: 'Train Data',
          data: transformData(resampledData),
        },
        {
          name: 'Test Data',
          data: transformData(testData),
        },
        {
          name: 'Test Self Prediction',
          data: transformData(testSelfPrediction),
        },
        {
          name: 'Future Prediction',
          data: transformData(futurePrediction),
        },
      ],
    });
  }, [encodedData.ec2_cpu_ai_data]);

  return (
    <div id='chart'>
      <ApexCharts options={chartData.options} series={chartData.series} type='line' height={350} />
    </div>
  );
};

export default CPUUsageChart;
