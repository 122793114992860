import React, { useRef, useState } from 'react';
import Logo from '../landingPage/assets/LogoWithText.svg';
import { useHistory } from 'react-router-dom';

import { DispatchAction, Subscribe } from './statemanagement/store';
import * as actions from './statemanagement/ActionsList';
import { GetUserLoginCredentialsInCognitoUserPoolFormat } from './UserCredentials';
import { GetVerifyCognitoUserPoolLoginUrl } from '../routes';
import axios from 'axios';

import UserPool from './AWS/UserPool';

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import classes from '../styles/Login.module.css';
import Eye from '../landingPage/assets/login/passwordEye.svg';
import EyeHide from '../landingPage/assets/login/passwordEyeHide.svg';
import { toast } from 'react-toastify';
import { PATHS } from '../landingPage/components/Navbar/Navbar';
import posthog from 'posthog-js';


export const AUTH_PROVIDER_AWS_COGNITO_USER_POOL = 'AWS_COGNITO_USER_POOL';

const onStoreStateChanged = () => {
  return;
};

const unsubscribe = Subscribe(onStoreStateChanged); // this is for redux

const getCognitoUserPoolEmailPasswordCreds = (userCredentials) => {
  return {
    concerto_user_credentials: userCredentials,
  };
};

const sendVerifyCognitoUserPoolLoginPostRequest = async (data) => {
  let credsData = GetUserLoginCredentialsInCognitoUserPoolFormat();
  const url = GetVerifyCognitoUserPoolLoginUrl();
  const payloadData = getCognitoUserPoolEmailPasswordCreds(credsData);

  try {
    const resp = await axios.post(url, payloadData);
    return resp;
  } catch (err) {
    console.log('sendVerifyUserLoginPostRequest to ' + url + ' failed');
  }
};

const COGNITO_USER_POOL_LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS';

function CognitoUserPoolSignin({ handleLogin }) {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [passwordInput, setPasswordInput] = useState('password');
  const [loginState, setloginState] = useState(false);

  const history = useHistory();

  function getUiCompatibilityAdaptorUserCreds(cognitoCredsFormat) {
    let u = {};

    u.email = cognitoCredsFormat.idToken.payload.email;
    u.uid = cognitoCredsFormat.accessToken.payload.username;

    let provData = [];
    let providerDataItem = {
      providerId: 'password',
      uid: cognitoCredsFormat.accessToken.payload.username,
      displayName: null,
      email: cognitoCredsFormat.idToken.payload.email,
      phoneNumber: null,
      photoURL: null,
    };

    provData.push(providerDataItem);
    u.providerData = provData;
    return u;
  }

  function handleSubmit(e) {
    e.preventDefault();

    posthog.capture('SIGNIN_ATTEMPTED', {
      property1: 'value1'
    });

    const user = new CognitoUser({
      Username: emailRef.current.value,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: emailRef.current.value,
      Password: passwordRef.current.value,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log('logindata', data);
        posthog.capture('SIGNIN_SUCCESS', {
          property1: 'value1'
        });
        let compatData = getUiCompatibilityAdaptorUserCreds(data);

        data.authProvider = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
        data.user = compatData;

        DispatchAction(actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW, {
          what: actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
          login_event: {
            setloginStateFunc: setloginState,
            user_credentials: data,
          },
        });

        sendVerifyCognitoUserPoolLoginPostRequest(data).then((res) => {
          let loginAuthStatus = res?.data?.login_verification_result;
          if (loginAuthStatus === COGNITO_USER_POOL_LOGIN_AUTH_SUCCESS) {
            DispatchAction(actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW, {
              what: actions.USER_LOGIN_HAPPENED_SUCCESSFULLY_NOW,
              user_product_configs_list: res?.data?.user_product_configs_list,
              login_event: {
                setloginStateFunc: setloginState,
                user_credentials: data,
              },
            });
            const path = '/resource-maker';
            history.push(path);
          }
        });

        handleLogin();
      },
      onFailure: (err) => {
        console.log('authenticateUser onFailure err', err);
        posthog.capture('SIGNIN_FAILED', {
          property1: 'value1'
        })
        toast.error(err.message);
      },
      newPasswordRequired: (data) => {
        posthog.capture('NEW_PASSWORD_REQUIRED', {
          property1: 'value1'
        })
        console.log('authenticateUser newPasswordRequired data ', data);
      },
    });
  }

  return (
    <>
      <div className={classes.LoginContainer}>
        <div className={classes.leftContainer}>
          <img className={classes.LoginLogo} src={Logo} alt='' />
          <div className={`${classes.formContainer} ${classes.toggleIn}`}>
            <div className={classes.form}>
              <div className={classes.title}>Sign in</div>
              <div className={classes.Inputs}>
                <div>
                  <label htmlFor='email'>Email</label>
                  <div className={classes.Input}>
                    <input className={classes.inputBox} ref={emailRef} type='email' name='email' id='email' placeholder='Email' />
                  </div>
                </div>
                <div>
                  <label htmlFor='password'>Password</label>
                  <div className={classes.PasswordInput}>
                    <input
                      className={classes.inputBox}
                      type={passwordInput}
                      ref={passwordRef}
                      name='password'
                      id='password'
                      placeholder='Password'
                    />
                    {passwordInput === 'password' ? (
                      <img className={classes.eye} src={Eye} onClick={() => setPasswordInput('text')} />
                    ) : (
                      <img className={classes.eye} src={EyeHide} onClick={() => setPasswordInput('password')} />
                    )}
                  </div>
                </div>
                <div className={classes.helperLine}>
                  Forgot password?
                  <span className={classes.hint} onClick={() => history.push(PATHS.RESET_PASSWORD)}>
                    Click here to reset
                  </span>
                </div>
                <div className={classes.helperLine}>
                  Don't have an account?
                  <span className={classes.hint} onClick={() => history.push(PATHS.SIGNUP)}>
                    Signup
                  </span>
                </div>
                <div className='new-btn-secondary' onClick={handleSubmit} style={{ height: '3vw', fontSize: '1.1vw' }}>
                  Log In
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const getCredsV2 = (userCredentials) => {
  return {
    concerto_user_credentials: userCredentials,
  };
};

export default CognitoUserPoolSignin;

//
//
