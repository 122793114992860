

const BillingConsole = () => {
    return (
        <div>
            <h2>
                Billing Console
            </h2>
        </div>
    )
}

export default BillingConsole;