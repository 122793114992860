import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Button, DatePicker, Card, Table } from 'antd';
import moment from 'moment';
import { GetAllAccountsURL, GetCostManagerV2Url } from '../../../routes';
import { GetLoginCredentialsAsJsonObject, GetLoginCredentials } from '../../UserCredentials';
import './BillingCompare.css';
import { ArrowLeftOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const BillingCompareInput = ({
  accounts,
  dataSet1,
  dataSet2,
  detailsType,
  handleAccountSelection,
  handleDateChange,
  handleDetailsTypeChange,
  handleSubmit,
}) => {
  const renderDataSet = (dataSetNumber, dataSet) => (
    <div className='filters-container'>
      <div className='filter-item'>
        <label className='filter-label'>Select Account</label>
        <Select
          mode='multiple'
          options={accounts}
          onChange={(values) => handleAccountSelection(dataSetNumber, values)}
          className='filter-select'
          placeholder='Select accounts'
        />
      </div>
      <div className='date-selection-container'>
        <label htmlFor='dateRange' className='date-label'>
          Date Range
        </label>
        <RangePicker onChange={(dates) => handleDateChange(dataSetNumber, dates)} className='date-picker' format='YYYY-MM-DD' />
      </div>
    </div>
  );

  return (
    <div className='billing-compare-container'>
      <div className='data-sets-container'>
        {renderDataSet(1, dataSet1)}
        <div className='data-set-separator'></div>
        {renderDataSet(2, dataSet2)}
      </div>

      <div className='details-filters-container'>
        <div className='filter-item'>
          <label className='filter-label'>Details Type</label>
          <Select value={detailsType} onChange={handleDetailsTypeChange} className='filter-select'>
            {/* <Option value="detailed">Detailed</Option> */}
            <Option value='overview'>Overview</Option>
          </Select>
        </div>
        <Button type='primary' className='submit-button' onClick={handleSubmit}>
          Compare
        </Button>
      </div>
    </div>
  );
};

const ComparisonDescription = ({ dateRanges, dataSet1, dataSet2 }) => {
  return (
    <div className='comparison-description'>
      <h2>Billing Comparison</h2>
      <p>This table compares billing data for the following periods and accounts:</p>
      <div className='comparison-details'>
        <div className='comparison-set'>
          <h3>Data Set 1:</h3>
          <p>
            Date Range: {dateRanges.dataset1.fromDate} to {dateRanges.dataset1.toDate}
          </p>
          <p>Accounts: {dataSet1.selectedAccountIds.join(', ')}</p>
        </div>
        <div className='comparison-set'>
          <h3>Data Set 2:</h3>
          <p>
            Date Range: {dateRanges.dataset2.fromDate} to {dateRanges.dataset2.toDate}
          </p>
          <p>Accounts: {dataSet2.selectedAccountIds.join(', ')}</p>
        </div>
      </div>
    </div>
  );
};

const BillingCompareTable = ({ comparisonData, dateRanges, dataSet1, dataSet2 }) => {
  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'ProductName',
      key: 'ProductName',
    },
    {
      title: dateRanges ? (
        <div>
          <div>{`(${dateRanges.dataset1.fromDate} to ${dateRanges.dataset1.toDate})`}</div>
          <div>{`[${dataSet1.selectedAccountIds.join(', ')}]`}</div>
        </div>
      ) : (
        'Date Range 1'
      ),
      dataIndex: 'DateRange1TotalCost',
      key: 'DateRange1TotalCost',
      render: (text) => (text ? `$${parseFloat(text).toFixed(2)}` : '-'),
    },
    {
      title: dateRanges ? (
        <div>
          <div>{`(${dateRanges.dataset2.fromDate} to ${dateRanges.dataset2.toDate})`}</div>
          <div>{`[${dataSet2.selectedAccountIds.join(', ')}]`}</div>
        </div>
      ) : (
        'Date Range 2'
      ),
      dataIndex: 'DateRange2TotalCost',
      key: 'DateRange2TotalCost',
      render: (text) => (text ? `$${parseFloat(text).toFixed(2)}` : '-'),
    },
    {
      title: 'Cost Difference',
      dataIndex: 'CostDifference',
      key: 'CostDifference',
      render: (text) => (text ? `$${parseFloat(text).toFixed(2)}` : '-'),
    },
    {
      title: 'Cost Percentage Difference',
      dataIndex: 'CostPercentageDifference',
      key: 'CostPercentageDifference',
      render: (text) => (text.includes('E') ? 'NA(∞)' : text),
    },
    {
      title: 'Trend',
      dataIndex: 'UpOrDownTrend',
      key: 'UpOrDownTrend',
      render: (text) =>
        text === 'UP' ? (
          <>
            <ArrowUpOutlined style={{ color: 'red' }} />
          </>
        ) : (
          <>
            <ArrowDownOutlined style={{ color: 'green' }} />
          </>
        ),
    },
  ];

  return (
    <div className='billing-compare-table-container'>
      <Table
        dataSource={comparisonData}
        columns={columns}
        rowKey='ProductName'
        rowClassName={(record) => {
          return record.UpOrDownTrend === 'UP' ? 'trend-up' : 'trend-down';
        }}
        pagination={false}
        className='billing-compare-table'
      />
    </div>
  );
};

const BillingCompare = () => {
  const [accounts, setAccounts] = useState([]);
  const [dataSet1, setDataSet1] = useState({
    selectedAccountIds: [],
    dateRange: [null, null],
  });
  const [dataSet2, setDataSet2] = useState({
    selectedAccountIds: [],
    dateRange: [null, null],
  });
  const [detailsType, setDetailsType] = useState('overview');
  const [comparisonData, setComparisonData] = useState(null);
  const [dateRanges, setDateRanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const handleBack = () => {
    setShowTable(false);
    setComparisonData(null);
    setDateRanges(null);
    setError(null);
    setDataSet1({
      selectedAccountIds: [],
      dateRange: [null, null],
    });
    setDataSet2({
      selectedAccountIds: [],
      dateRange: [null, null],
    });
    setDetailsType('overview');
  };

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  const fetchAllAccounts = async () => {
    const url = GetAllAccountsURL();
    const payload = {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      command_to_execute: 'get_account_ids',
    };

    try {
      const resp = await axios.post(url, payload);
      if (resp.status === 200 && typeof resp.data === 'object' && resp.data.message !== 'Internal server error') {
        const accountOptions = resp.data.account_info_details_list.map((account) => ({
          value: account.AccountIdStr,
          label: `${account.AccountIdStr} ${account.FriendlyName || ''}`.trim(),
        }));
        setAccounts(accountOptions);
      }
    } catch (err) {
      console.error('Failed to fetch account data from ' + url, err);
    }
  };

  const handleAccountSelection = (dataSetNumber, selectedValues) => {
    if (dataSetNumber === 1) {
      setDataSet1((prev) => ({ ...prev, selectedAccountIds: selectedValues }));
    } else {
      setDataSet2((prev) => ({ ...prev, selectedAccountIds: selectedValues }));
    }
  };

  const handleDateChange = (dataSetNumber, dates) => {
    if (dates) {
      const [start, end] = dates;
      const formattedDates = [start ? moment(start).format('YYYY-MM-DD') : null, end ? moment(end).format('YYYY-MM-DD') : null];
      if (dataSetNumber === 1) {
        setDataSet1((prev) => ({ ...prev, dateRange: formattedDates }));
      } else {
        setDataSet2((prev) => ({ ...prev, dateRange: formattedDates }));
      }
    } else {
      if (dataSetNumber === 1) {
        setDataSet1((prev) => ({ ...prev, dateRange: [null, null] }));
      } else {
        setDataSet2((prev) => ({ ...prev, dateRange: [null, null] }));
      }
    }
  };

  const handleDetailsTypeChange = (value) => {
    setDetailsType(value);
  };

  const fetchComparisonData = async (argSet1, argSet2, detailsType) => {
    setLoading(true);
    setError(null);
    const url = GetCostManagerV2Url();

    let costCompareArgs = {
      dataset1: {
        AccountId: argSet1.selectedAccountIds,
        fromDate: argSet1.fromDate,
        toDate: argSet1.toDate,
      },
      dataset2: {
        AccountId: argSet2.selectedAccountIds,
        fromDate: argSet2.fromDate,
        toDate: argSet2.toDate,
      },
      details_type: detailsType,
    };

    const payload = {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: 'get_aws_cost_compare',
      command_args: {
        costCompareArgs: costCompareArgs,
      },
    };

    try {
      const resp = await axios.post(url, payload);
      console.log('API Response:', resp.data);

      if (resp.status === 200) {
        if (resp.data.auth_errors) {
          setError(`Authentication error: ${resp.data.auth_errors}`);
        } else if (resp.data.get_aws_cost_compare.error) {
          setError(`Error: ${resp.data.get_aws_cost_compare.error}`);
        } else {
          const data = resp.data.get_aws_cost_compare;
          setComparisonData(data.service_wise_cost_summary_compare);
          setDateRanges({
            dataset1: {
              fromDate: data.costCompareArgsDataset1FromDate,
              toDate: data.costCompareArgsDataset1ToDate,
            },
            dataset2: {
              fromDate: data.costCompareArgsDataset2FromDate,
              toDate: data.costCompareArgsDataset2ToDate,
            },
          });
          setShowTable(true);
        }
      }
    } catch (err) {
      console.error('Failed to fetch comparison data', err);
      setError('Failed to fetch comparison data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    console.log('Data Set 1:', dataSet1);
    console.log('Data Set 2:', dataSet2);
    console.log('Details Type:', detailsType);

    const argSet1 = {
      selectedAccountIds: dataSet1.selectedAccountIds,
      fromDate: dataSet1.dateRange[0],
      toDate: dataSet1.dateRange[1],
    };

    const argSet2 = {
      selectedAccountIds: dataSet2.selectedAccountIds,
      fromDate: dataSet2.dateRange[0],
      toDate: dataSet2.dateRange[1],
    };

    fetchComparisonData(argSet1, argSet2, detailsType);
  };

  return (
    <div>
      <BillingCompareInput
        accounts={accounts}
        dataSet1={dataSet1}
        dataSet2={dataSet2}
        detailsType={detailsType}
        handleAccountSelection={handleAccountSelection}
        handleDateChange={handleDateChange}
        handleDetailsTypeChange={handleDetailsTypeChange}
        handleSubmit={handleSubmit}
      />

      {/* Render Back Button and Table if comparisonData is available */}
      {comparisonData && (
        <div>
          {/* <Button
            onClick={handleBack}
            className="back-btn"
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button> */}
          <BillingCompareTable comparisonData={comparisonData} dateRanges={dateRanges} dataSet1={dataSet1} dataSet2={dataSet2} />
        </div>
      )}

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default BillingCompare;
