import React, { useEffect, useRef, useState } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import axios from 'axios';
import { GetLoginCredentialsAsJsonObject } from '../../components/UserCredentials';
import { GetAiGenResponsetUrl, getCarloIpFromBff } from '../../routes';
import classes from './Chatbot.module.css';
import { Modal } from 'antd';
import ScheduleConfirmation from '../../pages/ChatbotResponses/ComponentResponses/CreateSchedulePreview/CreateSchedulePreview';
import AttachSchedulePreview from '../../pages/ChatbotResponses/ComponentResponses/AttachSchedulePreview/AttachSchedulePreview';
import AddUpdateTagPreview from '../../pages/ChatbotResponses/ComponentResponses/AddUpdateTagPreview/AddUpdateTagPreview';
import { ResizableBox } from 'react-resizable';
import TypingAnimation from '../../components/TypingAnimation/TypingAnimation';
import AddDeleteAwsAccount from '../../pages/ChatbotResponses/ComponentResponses/AddDeleteAwsAccount/AddDeleteAwsAccount';
import { parseOrReturnJson } from '../../utils/stringHelper';

export const CHATBOT_RESULT_TYPES = {
  AI_RESPONSE: 'ai_response',
  AWS_USAGE_COST: 'aws_usage_cost',
  AWS_ACCOUNTS_INFO: 'aws_accounts_info',
  EC2_INSTANCE_DETAILS_LIST: 'get_ec2_instance_details_list_for_accounts_list',
  SECURITY_VIOLATIONS_LIST: 'security_violations_list_for_accounts',
  RECOMMENDATIONS: 'resource_evaluator_upgrade_downgrade_recommendations',
  EC2_INSTANCE_ACTIONS: [
    'set_ec2_instance_state',
    'ec2_instance_stop_action_result',
    'ec2_instance_start_action_result',
    'ec2_instance_reboot_action_result',
  ],
  CONCERTO_SCHEDULE_CREATE: 'concerto_schedule_create',
  CONCERTO_SCHEDULE_DELETE: 'concerto_schedule_delete',
  AWS_EC2_INSTANCE_SCHEDULE_UPDATE: 'aws_ec2_instance_schedule_update',
  AWS_EC2_INSTANCE_SCHEDULE_DELETE: 'aws_ec2_instance_schedule_delete',
  AWS_EC2_INSTANCE_CREATE_TAG: 'aws_ec2_instance_create_tag',
  AWS_EC2_INSTANCE_DELETE_TAG: 'aws_ec2_instance_delete_tag',
  LIST_ALL_SCHEDULES: 'concerto_schedule_read_all',
  GET_CPU_METRICS: 'aws_ec2_instance_get_cpu_metrics',
  AWS_ACCOUNTS_INFO_ADD: 'aws_accounts_info_add',
  AWS_ACCOUNTS_INFO_DELETE: 'aws_accounts_info_delete',
  AWS_TERRAFORM_SCRIPT_CREATE: 'aws_terraform_iac_script_create',
  CHATBOT_HELP_AND_USAGE: 'chatbot_help_and_usage',
};

const Chatbot = ({ renderChatbotComponent }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState();
  const [carloIp, setCarloIp] = useState(null);

  const chatWindowRef = useRef(null);

  useEffect(() => {
    getCarloIp();
  }, []);

  const getCarloIp = async () => {
    const carloIp = await getCarloIpFromBff();
    setCarloIp(carloIp);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    const userMessage = { text: newMessage.trim(), sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage('');

    setIsTyping(true);

    try {
      const response = await getChatBotBackendResponse({
        newMessage: userMessage.text,
        prevMessages: messages,
      });
      handleResponse(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsTyping(false);
    }
  };

  const getChatBotBackendResponse = async (requestParams) => {
    const url = GetAiGenResponsetUrl(carloIp);
    const userCredentials = GetLoginCredentialsAsJsonObject();
    const payload = {
      concerto_user_credentials: userCredentials,
      command_to_execute: { commands: ['get_chatbot_response'] },
      command_args: {},
      command_args_as_str_base64: Buffer.from(JSON.stringify(requestParams)).toString('base64'),
    };
    return await axios.post(url, payload);
  };

  const handleResponse = (response) => {
    if (!response) return;

    const { result_type, result_data, ui_visualization_type, ui_visualization_instructions, response_data } = response.data;
    const parsedResponseData = parseOrReturnJson(response_data);
    switch (result_type) {
      case CHATBOT_RESULT_TYPES.AI_RESPONSE:
        handleAiResponse(result_data.choices[0]?.message?.content);
        break;
      case CHATBOT_RESULT_TYPES.AWS_USAGE_COST:
        handleLinkMessage(
          CHATBOT_RESULT_TYPES.AWS_USAGE_COST,
          'Click here to view detailed cost information.',
          parsedResponseData,
          ui_visualization_type
        );
        break;
      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO:
        handleLinkMessage(CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO, 'Click here to view requested aws accounts', parsedResponseData);
        break;
      case CHATBOT_RESULT_TYPES.EC2_INSTANCE_DETAILS_LIST:
        handleLinkMessage(
          CHATBOT_RESULT_TYPES.EC2_INSTANCE_DETAILS_LIST,
          'Click here to view information about the instances.',
          parsedResponseData.ec2InstancesDataMapList
        );
        break;
      case CHATBOT_RESULT_TYPES.SECURITY_VIOLATIONS_LIST:
        handleLinkMessage(
          CHATBOT_RESULT_TYPES.SECURITY_VIOLATIONS_LIST,
          'Please click here to show the security violations for the requested accounts',
          {
            ...parsedResponseData,
          }
        );
        break;
      case CHATBOT_RESULT_TYPES.RECOMMENDATIONS:
        handleLinkMessage(
          CHATBOT_RESULT_TYPES.RECOMMENDATIONS,
          'Please click here to show the recommendations for the requested accounts',
          parsedResponseData
        );
        break;
      case CHATBOT_RESULT_TYPES.EC2_INSTANCE_ACTIONS.some((type) => type === result_type):
        handleLinkMessage(
          result_type,
          'Provided action taken on given instances. Please check it in the given list',
          parsedResponseData.statuses_list.map((status) => ({
            accountId: parsedResponseData.aws_account_id,
            instanceId: status['InstanceId'],
            prevStatus: status['PreviousState.Name'],
            currStatus: status['CurrentState.Name'],
          }))
        );
        break;
      case CHATBOT_RESULT_TYPES.LIST_ALL_SCHEDULES:
        handleLinkMessage(CHATBOT_RESULT_TYPES.LIST_ALL_SCHEDULES, 'Please click here to show the list of schedules');
        break;
      case CHATBOT_RESULT_TYPES.GET_CPU_METRICS:
        handleLinkMessage(result_type, 'Please click here to view CPU Metrics', ui_visualization_instructions.ui_params);
        break;

      case CHATBOT_RESULT_TYPES.AWS_TERRAFORM_SCRIPT_CREATE: {
        const responseText = ui_visualization_instructions.ui_params?.terraform_script_snippet?.split(/```hcl\n[\s\S]*?\n```/)[0].trim();

        handleLinkMessage(result_type, `${responseText} \n\nPlease click here to view Terraform Script`, ui_visualization_instructions.ui_params);
        break;
      }
      case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_CREATE:
      case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_DELETE:
      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_UPDATE:
      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_DELETE:
        handleComponentMessage(result_type, ui_visualization_instructions.ui_actions[0].action_params, result_type);
        break;

      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_CREATE_TAG:
      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_DELETE_TAG:
        handleComponentMessage(result_type, ui_visualization_instructions.ui_params, result_type);
        break;

      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO_ADD:
      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO_DELETE:
        handleComponentMessage(result_type, ui_visualization_instructions.action_params, result_type);
        break;
      case CHATBOT_RESULT_TYPES.CHATBOT_HELP_AND_USAGE: {
        let processedData = { ...response_data };
        if (ui_visualization_instructions.help_type === 'command_specific') {
          processedData = {
            [response_data.command_identifier]: { ...response_data },
          };
        }
        handleLinkMessage(CHATBOT_RESULT_TYPES.CHATBOT_HELP_AND_USAGE, 'Click here to show help on using chatbot commands', processedData);
        break;
      }
      default:
        break;
    }
  };

  const handleAiResponse = (aiMessage) => {
    console.log({ aiMessage });
    if (aiMessage) setMessages((prevMessages) => [...prevMessages, { text: aiMessage, sender: 'ai' }]);
  };

  const handleLinkMessage = (component, text, data) => {
    const message = {
      text,
      textType: 'link',
      textStyle: { fontSize: '12px', fontStyle: 'italic', cursor: 'pointer' },
      sender: 'ai',
      onClick: () => renderChatbotComponent(component, data),
    };
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const handleComponentMessage = (component, data, result_type) => {
    switch (result_type) {
      case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_CREATE:
        {
          const processedComponent = (
            <ScheduleConfirmation
              data={data}
              type={result_type}
              successCallback={() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule created successfully', sender: 'ai' }]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule creation failed', sender: 'ai' }]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      case CHATBOT_RESULT_TYPES.CONCERTO_SCHEDULE_DELETE:
        {
          const processedComponent = (
            <ScheduleConfirmation
              data={data}
              type={result_type}
              successCallback={() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule deleted successfully', sender: 'ai' }]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule deletion failed', sender: 'ai' }]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_UPDATE:
        {
          const processedComponent = (
            <AttachSchedulePreview
              data={data}
              type={result_type}
              successCallback={() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule attached successfully', sender: 'ai' }]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule attach failed', sender: 'ai' }]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_DELETE:
        {
          const processedComponent = (
            <AttachSchedulePreview
              data={data}
              type={result_type}
              successCallback={() => {
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule removed successfully', sender: 'ai' }]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [...prevMessages, { text: 'Schedule remove failed', sender: 'ai' }]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_CREATE_TAG:
      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_DELETE_TAG:
        {
          const type = result_type === CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_CREATE_TAG ? 'create' : 'delete';
          const processedComponent = (
            <AddUpdateTagPreview
              data={data}
              type={type}
              successCallback={() => {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: `Tag ${type === 'create' ? 'created' : 'deleted'} successfully`,
                    sender: 'ai',
                  },
                ]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: `Tag ${type === 'create' ? 'creation' : 'deletion'} failed`,
                    sender: 'ai',
                  },
                ]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO_ADD:
      case CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO_DELETE:
        {
          const type = result_type === CHATBOT_RESULT_TYPES.AWS_ACCOUNTS_INFO_ADD ? 'create' : 'delete';
          const processedComponent = (
            <AddDeleteAwsAccount
              data={data}
              type={type}
              successCallback={() => {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: `Account ${type === 'create' ? 'created' : 'deleted'} successfully`,
                    sender: 'ai',
                  },
                ]);
                setIsModalOpen(false);
                setModalComponent(null);
              }}
              errorCallback={() => {
                setIsModalOpen(false);
                setModalComponent(null);
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    text: `Account ${type === 'create' ? 'creation' : 'deletion'} failed`,
                    sender: 'ai',
                  },
                ]);
              }}
            />
          );
          setIsModalOpen(true);
          setModalComponent(processedComponent);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <MessageOutlined className={classes.iconStyle} onClick={() => setIsChatOpen((prev) => !prev)} />
      <Modal open={isModalOpen} footer={false} closable={false}>
        {modalComponent}
      </Modal>
      {isChatOpen && (
        <ResizableBox
          resizeHandles={['sw', 'nw', 'se', 'ne']}
          className={classes.chatWindow}
          width={400}
          height={400}
          minConstraints={[320, 380]}
          maxConstraints={[800, 500]}
        >
          <div className={classes.chatHeader}>Carlos AI</div>
          <div ref={chatWindowRef} className={classes.chatMessages}>
            {messages.map((message, index) => (
              <div
                onClick={message.textType === 'link' ? message.onClick : undefined}
                key={index}
                style={message.textStyle}
                className={message.sender === 'user' ? classes.userMessage : classes.aiMessage}
              >
                <span>{message.text}</span>
              </div>
            ))}
            {isTyping && <TypingAnimation />}
          </div>
          <div className={classes.chatInputContainer}>
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              placeholder='Type a message...'
              className={classes.chatTextArea}
            />
            <button onClick={sendMessage} className={classes.sendButton}>
              Send
            </button>
          </div>
        </ResizableBox>
      )}
    </div>
  );
};

export default Chatbot;
