import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  font-family: 'Jost', sans-serif;
  align-items: center;
  ${({ isNavExpanded }) =>
    isNavExpanded &&
    `
    background-color: #100A41;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    padding: 1.8vw 5vw;
    align-items: start;
  `}
`;

export const LogoWithText = styled.div`
  cursor: pointer;
  z-index: 3;
`;

export const LogoImg = styled.img`
  width: 12.13vw;
  @media (max-width: 575px) {
    position: absolute;
    left: -5vw;
    top: 1vw;
    height: 2rem;
    width: auto;
    ${({ isNavExpanded }) =>
      isNavExpanded &&
      `
      top: 2.5vw;
      left: 5vw;
    `}
  }
`;

export const HamburgerCon = styled.div`
  display: none;
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: -5vw;
  width: 1.5rem;
  height: 0.95rem;
  ${({ isNavExpanded }) =>
    isNavExpanded &&
    `
    top: calc(12px + 1.5vw);
    right: 5vw;
  `}
  .hamburger {
    position: absolute;
    height: 0.15rem;
    width: 1.5rem;
    background-color: white;
    transition: 0.5s;
    &.ham1 {
      top: 0;
      ${({ isNavExpanded }) =>
        isNavExpanded &&
        `
        width: 100%;
        transform: rotate(45deg);
        top: 0.475rem;
      `}
    }
    &.ham2 {
      top: 0.4rem;
      width: 75%;
      ${({ isNavExpanded }) =>
        isNavExpanded &&
        `
        width: 100%;
        display: none;
      `}
    }
    &.ham3 {
      top: 0.8rem;
      width: 50%;
      ${({ isNavExpanded }) =>
        isNavExpanded &&
        `
        width: 100%;
        transform: rotate(-45deg);
        top: 0.475rem;
      `}
    }
  }
  @media (max-width: 575px) {
    display: block;
  }
`;

export const MenuCon = styled.div`
  @media (max-width: 575px) {
    display: ${({ isNavExpanded }) => (isNavExpanded ? 'block' : 'none')};
    width: 100%;
  }
`;

export const LinksAndButtons = styled.div`
  display: flex;
  column-gap: 1.9vw;
  align-items: center;
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    margin-top: 3.5rem;
  }
`;

export const Link = styled.div`
  font-size: 0.83vw;
  font-weight: 500;
  cursor: pointer;
  @media (max-width: 575px) {
    width: 100%;
    font-size: 1.25rem;
    border-top: 1px solid #46455e;
    padding: 1.25rem;
    &.last-menu-item {
      border-bottom: 1px solid #46455e;
    }
  }
`;

export const CTAButtonCon = styled.div`
  display: flex;
  column-gap: 0.6vw;
  align-items: center;
  margin-left: 1vw;
  @media (max-width: 575px) {
    position: fixed;
    bottom: 0;
    flex-direction: column;
    align-items: start;
    row-gap: 1rem;
    width: calc(100% - 10vw);
    padding: 2rem 0;
  }
`;

export const ButtonCon = styled.div`
  width: 8vw;
  height: 2vw;
  @media (max-width: 575px) {
    width: 100%;
    height: 100%;
  }
`;

const Button = styled.div`
  border-radius: 5px;
  font-size: 0.93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  height: 100%;
  @media (max-width: 575px) {
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
  }
`;

export const PrimaryBtn = styled(Button)`
  background: transparent linear-gradient(180deg, #e7f7f8 0%, #5b9bd6 100%) 0% 0% no-repeat padding-box;
  color: #000000;
`;

export const SecondaryBtn = styled(Button)`
  background: transparent linear-gradient(180deg, #5d54fc 0%, #001182 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
`;
