import React from 'react';
import styles from './TerraformScript.module.css';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import TerraformIcon from '../../../images/terraform.svg';
import { Button } from 'antd';
import { DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import { copyString } from '../../../utils/stringHelper';

const TerraformScript = ({ data }) => {
  const scriptMatch = data?.terraform_script_snippet.match(/```hcl\n([\s\S]*?)\n```/);
  const terraformScript = scriptMatch ? scriptMatch[1] : 'No script found';

  const noteMatch = data?.terraform_script_snippet.match(/```\n\n([\s\S]*)/);
  const noteText = noteMatch ? noteMatch[1].replace(/`/g, '') : 'No note found';

  const resource_info_params = JSON.parse(data?.resource_info_params || '{}');
  const downloadScript = () => {
    const element = document.createElement('a');
    const file = new Blob([terraformScript], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'terraform_script.tf';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className={styles.cpuStatsContainer}>
      <div className={styles.cpuStatsChart}>
        <div style={{ padding: '1vw' }}>
          <h4>
            <span>
              <img style={{ height: '3vw', marginRight: '1vw' }} src={TerraformIcon} alt='' />
            </span>
            Terraform Template
          </h4>
          <div style={{ display: 'grid' }}>
            <div>
              <strong> AWS Account ID:</strong> {data?.aws_account_id}
            </div>
            <div>
              <strong> AWS Region Code:</strong> {data?.aws_region_code}
            </div>
            <div>
              <strong> Instance ID:</strong> {resource_info_params?.ec2_instances_ids_list?.[0]}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', marginLeft: '1vw' }}>
        <div style={{ flex: '0.65', position: 'relative' }}>
          <div style={{ position: 'absolute', top: '1vw', right: '1vw' }}>
            <div style={{ display: 'flex', gap: '1vw', alignItems: 'center' }}>
              <Button type='icon' onClick={() => copyString(terraformScript)} icon={<CopyOutlined />}></Button>
              <Button type='icon' onClick={downloadScript} icon={<DownloadOutlined />}></Button>
            </div>
          </div>
          <SyntaxHighlighter language='hcl' wrapLongLines customStyle={{ border: '1px solid lightgray', borderRadius: '3px' }} style={github}>
            {terraformScript}
          </SyntaxHighlighter>
        </div>
        <div style={{ flex: '0.35' }}>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <h4>Note</h4>
            <SyntaxHighlighter language='text' wrapLongLines customStyle={{ padding: 0, fontSize: '13px', background: 'transparent' }} style={github}>
              {noteText}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TerraformScript;
