import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styles from './CPUStatsChart.module.css'; // Import CSS module for styling
import moment from 'moment';
import { GetLoginCredentialsAsJsonObject } from '../../../components/UserCredentials';
import { GetAssetInfoUrl } from '../../../routes';
import { getPayload } from '../../../components/ProductConfiguration/Schedules/Schedules';
import axios from 'axios';
import { toast } from 'react-toastify';

const CPUStatsChart = ({ params }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    getCpuStats();
  }, [params]);

  const getCpuStats = async () => {
    let resourceLocator = {
      accountId: params.aws_account_id,
      awsRegion: params.aws_region_code,
      instanceId: params.ec2_instances_ids_list[0],
      samplingFrequencyIntervalInSeconds: params.samplingFrequencyIntervalInSeconds,
      startTime: params.startTimeStr,
      endTime: params.endTimeStr,
      timeZone: params.timeZoneStr,
      concertoUserId: '651',
    };
    let rsStr = JSON.stringify(resourceLocator);
    let userCredentials = GetLoginCredentialsAsJsonObject();
    const url = GetAssetInfoUrl();
    const payload = getPayload(userCredentials, ['get_ec2_cpu_metrics_info'], rsStr);
    const resp = await axios.post(url, payload);
    if (resp.data?.cpu_stats_info?.length) {
      setData(resp.data);
    } else {
      toast.error(resp.data);
    }
  };
  if (!data?.cpu_stats_info?.length) return <div></div>;

  const { start_time, end_time, sampling_frequency_in_seconds, time_zone, cpu_stats_info } = data;

  const series = cpu_stats_info.map((eachStat) => {
    return {
      name: eachStat.Label,
      data: eachStat.Timestamps.map((eachTimestamp, i) => ({
        x: moment(eachTimestamp).format('DD/MM/YY HH:mm:ss'),
        y: eachStat.Values[i],
      })),
    };
  });

  // ApexCharts options
  const options = {
    chart: {
      id: 'cpu-stats-chart',
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value?.toFixed(3);
        },
      },
    },
    xaxis: {
      labels: {
        offsetX: 10,
      },
    },
    stroke: {
      width: 2,
    },
  };

  return (
    <div className={styles.cpuStatsContainer}>
      <div className={styles.cpuStatsChart}>
        <div style={{ padding: '1vw' }}>
          <h2 className={styles.sectionTitle}>CPU Stats </h2>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <strong> Start Time:</strong> {start_time}
            </div>
            <div>
              <strong> End Time:</strong> {end_time}
            </div>
            <div>
              <strong> Sampling Frequency:</strong> {sampling_frequency_in_seconds}
            </div>
            <div>
              <strong> Timezone:</strong> {time_zone}
            </div>
          </div>
        </div>
        <Chart options={options} series={series} type='line' height={400} />
      </div>
    </div>
  );
};

export default CPUStatsChart;
