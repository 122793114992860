import { Table, Button } from 'antd';
import React from 'react';
import { toast } from 'react-toastify';
import { CHATBOT_RESULT_TYPES } from '../../../../newComponents/Chatbot/Chatbot';
import { UpdateSchedulesForEc2ResourceHelper } from '../../../../components/AwsContextBarHandlersImpl/Ec2SchedulesApiCalls';

const AttachSchedulePreview = ({ data, type, successCallback = () => {}, errorCallback = () => {} }) => {
  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const { action_type, aws_region_code, aws_account_id, ec2_instances_ids_list = [], schedule_name, resource_type } = data;

  const tableData = [
    { field: 'Schedule Name', value: schedule_name },
    { field: 'Action Type', value: action_type },
    { field: 'AWS Account ID', value: aws_account_id },
    { field: 'AWS Region Code', value: aws_region_code },
    { field: 'Instances', value: ec2_instances_ids_list.join(',') },
  ];

  let componentToRender;

  switch (type) {
    case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_UPDATE:
      componentToRender = (
        <>
          <h6>
            Take a moment to review the schedule details.
            <br />
            Are you sure want to attach the schedule?
          </h6>
          <Table size='small' indentSize={1} showHeader={false} columns={columns} dataSource={tableData} pagination={false} />
        </>
      );
      break;

    case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_DELETE:
      componentToRender = (
        <>
          <h6>Are you sure want to remove the schedule {data?.name_of_schedules_list?.join(',')}?</h6>
        </>
      );
      break;

    default:
      break;
  }

  const handleAttach = async () => {
    const resp = await UpdateSchedulesForEc2ResourceHelper(
      aws_account_id,
      aws_region_code,
      resource_type,
      ec2_instances_ids_list[0],
      schedule_name,
      action_type
    );

    if (resp.status === 200) {
      toast.success('Schedule attached successfully');
      return successCallback();
    } else {
      toast.error('Schedule attach failed');
      return errorCallback();
    }
  };

  const handleDelete = async () => {
    const resp = await UpdateSchedulesForEc2ResourceHelper(
      aws_account_id,
      aws_region_code,
      resource_type,
      ec2_instances_ids_list[0],
      null,
      action_type
    );

    if (resp.status === 200) {
      toast.success('Schedule removed successfully');
      return successCallback();
    } else {
      toast.error('Schedule remove failed');
      return errorCallback();
    }
  };

  const onConfirm = () => {
    console.log({ type });
    switch (type) {
      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_UPDATE:
        handleAttach();
        break;

      case CHATBOT_RESULT_TYPES.AWS_EC2_INSTANCE_SCHEDULE_DELETE:
        handleDelete();
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '1vw' }}>
      {componentToRender}
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button style={{ marginRight: '10px' }} onClick={errorCallback}>
          Cancel
        </Button>
        <Button type='primary' onClick={onConfirm}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default AttachSchedulePreview;
