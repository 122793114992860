import React from 'react';
import { getPayload } from '../../../../components/ProductConfiguration/Schedules/Schedules';
import { GetLoginCredentialsAsJsonObject } from '../../../../components/UserCredentials';
import { GetAssetInfoUrl } from '../../../../routes';
import axios from 'axios';
import { Button } from 'antd';
import { toast } from 'react-toastify';

const AddUpdateTagPreview = ({ data, type, successCallback = () => {}, errorCallback = () => {} }) => {
  const { aws_account_id, aws_region_code, ec2_instances_ids_list, tags_key_value_pair_dict, tags_keys_list = [] } = data;

  let resourceLocator = {
    accountId: aws_account_id,
    instanceId: ec2_instances_ids_list[0],
    awsRegion: aws_region_code,
    concertoUserId: '651',
  };

  const handleCreate = () => {
    let allTagsList = [];

    for (const tmpTag in tags_key_value_pair_dict) {
      let v = [tmpTag, tags_key_value_pair_dict[tmpTag]];
      allTagsList.push(v);
    }

    resourceLocator.assetsInfoTags = allTagsList;
    let rsStr = JSON.stringify(resourceLocator);
    let userCredentials = GetLoginCredentialsAsJsonObject();
    const url = GetAssetInfoUrl();
    const payload = getPayload(userCredentials, ['update_ec2_asset_info'], rsStr);
    return axios.post(url, payload);
  };

  const handleDelete = () => {
    let allTagsList = [];
    tags_keys_list.forEach((tag) => allTagsList.push([tag, '']));

    resourceLocator.assetsInfoTags = allTagsList;
    let rsStr = JSON.stringify(resourceLocator);
    let userCredentials = GetLoginCredentialsAsJsonObject();
    const url = GetAssetInfoUrl();
    const payload = getPayload(userCredentials, ['delete_ec2_tag_info'], rsStr);
    return axios.post(url, payload);
  };

  const onConfirm = async () => {
    const resp = type === 'create' ? await handleCreate() : await handleDelete();
    if ([resp.data?.tags_update_status, resp.data?.tags_delete_status].includes('SUCCESS')) {
      toast.success(`Tag ${type === 'create' ? 'creation' : 'deletion'} success`);
      return successCallback();
    } else {
      toast.error(`Tag ${type === 'create' ? 'creation' : 'deletion'} failed`);
      return errorCallback();
    }
  };

  let componentToRender = (
    <div>
      <h6>Are you sure you want to {type} the following tags?</h6>
      <p>
        <strong>AWS Account ID:</strong> {aws_account_id}
      </p>
      <p>
        <strong> Instance ID:</strong> {ec2_instances_ids_list[0]}
      </p>
      <strong>Tags:</strong>
      {type === 'create' ? (
        <ul>
          {Object.keys(tags_key_value_pair_dict).map((tag) => (
            <li key={tag}>
              {tag}: {tags_key_value_pair_dict[tag]}
            </li>
          ))}
        </ul>
      ) : (
        <ul>
          {tags_keys_list.map((tag) => (
            <li key={tag}>{tag}</li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div style={{ marginTop: '1vw' }}>
      {componentToRender}
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button style={{ marginRight: '10px' }} onClick={errorCallback}>
          Cancel
        </Button>
        <Button type='primary' onClick={onConfirm}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default AddUpdateTagPreview;
