import React from "react";
import { Table } from "antd";
import "../../../../styles/DataTable.css";

const getStatusColor = (status) => {
  switch (status) {
    case "PASS":
      return "green";
    case "FAIL":
      return "red";
    case "MANUAL":
      return "darkblue";
    default:
      return "black";
  }
};

const getSeverityColor = (severity) => {
  switch (severity) {
    case "low":
      return "blue";
    case "medium":
      return "brown";
    case "high":
      return "red";
    case "critical":
      return "lightcoral";
    default:
      return "black";
  }
};

const columns = [
  {
    title: "Account ID",
    dataIndex: "AccountID",
    key: "accountId",
    className: "custom-header",
  },
  {
    title: "Region",
    dataIndex: "Region",
    key: "region",
    className: "custom-header",
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "service",
    className: "custom-header",
  },
  {
    title: "Check ID",
    dataIndex: "CheckID",
    key: "checkId",
    className: "custom-header",
  },
  {
    title: "Check Title",
    dataIndex: "CheckTitle",
    key: "checkTitle",
    className: "custom-header",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "status",
    className: "custom-header",
    render: (text) => (
      <span className={`status-text ${text.toLowerCase()}`}>{text}</span>
    ),
  },
  {
    title: "Status Details",
    dataIndex: "StatusDetails",
    key: "statusDetails",
    className: "custom-header",
  },
  {
    title: "Severity",
    dataIndex: "Severity",
    key: "severity",
    className: "custom-header",
    render: (text) => (
      <span
        className={`severity-text ${text.toLowerCase()}`}
        style={{ color: getSeverityColor(text) }}
      >
        {text}
      </span>
    ),
  },
];

const DataTable = ({ data }) => {
  return (
    <div className="dataTable-container">
      <Table
        dataSource={data}
        columns={columns}
        pagination={{ position: "topRight", pageSize: 20 }}
        className="custom-dataTable"
        scroll={{ x: "100%" }}
      />
    </div>
  );
};

export default DataTable;
