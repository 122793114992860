import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./BillingSummary.css";
import { GetCostManagerV2Url } from "../../../routes";
import {
  GetCognitoUserTokenCredentials,
  GetUserLoginCredentials,
  GetUserLoginToken,
  IsAuthTypeCognitoUserPoolEmailPasswordAuthType,
  IsAuthTypeFirebaseEmailPasswordAuthType,
} from "../../UserCredentials";
import { AUTH_PROVIDER_AWS_COGNITO_USER_POOL } from "../../CognitoUserPoolSignin";
import { AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD } from "../../Login";
import { Collapse, Tabs, Button } from "antd";
import ApexCharts from "react-apexcharts";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const { TabPane } = Tabs;

const BillingSummary = ({ selectedFilters }) => {
  const DailyTab = "DailyTab";
  const WeeklyTab = "WeeklyTab";
  const MonthlyTab = "MonthlyTab";
  const [loading, setLoading] = useState(true);
  const [costDetails, setCostDetails] = useState([]);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [forecastData, setForecastData] = useState({});
  const [dailyCosts, setDailyCosts] = useState([]);
  const [weeklyCosts, setWeeklyCosts] = useState([]);
  const [monthlyCosts, setMonthlyCosts] = useState([]);
  const [chartVisible, setChartVisible] = useState({});
  const [activeTab, setActiveTab] = useState("DailyTab");
  const accountIds = selectedFilters["AWS Account"] || [];

  const reportRef = useRef();

  const toggleChartVisibility = (accountId) => {
    setChartVisible((prevState) => ({
      ...prevState,
      [accountId]: !prevState[accountId], // Toggle visibility for this specific account
    }));
  };

  const GetLoginCredentials = () => {
    let userCreds = GetUserLoginCredentials()[0];
    let authProviderTypeStr = "";

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_AWS_COGNITO_USER_POOL;
    }
    if (IsAuthTypeFirebaseEmailPasswordAuthType()) {
      authProviderTypeStr = AUTH_PROVIDER_FIREBASE_EMAIL_PASSWORD;
    }

    let credJson = {
      displayName: userCreds.displayName,
      email: userCreds.email,
      phoneNumber: userCreds.phoneNumber,
      photoURL: userCreds.photoURL,
      providerId: userCreds.providerId,
      uid: userCreds.uid,
      user_oauth_token: GetUserLoginToken(),
      authProviderType: authProviderTypeStr,
    };

    if (IsAuthTypeCognitoUserPoolEmailPasswordAuthType()) {
      credJson.cognito_user_pool_email_password_info =
        GetCognitoUserTokenCredentials();
    }

    return credJson;
  };

  const GetCostDetailsSummaryPayload = (accountIds) => {
    return {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_aws_cost_summary_details",
      command_args: {
        AccountId: accountIds,
      },
    };
  };

  const GetCostDetailsSummaryData = async (accountIds) => {
    const url = GetCostManagerV2Url();
    const payload = GetCostDetailsSummaryPayload(accountIds);
    try {
      console.log("Sending payload:", payload); // Log payload
      const resp = await axios.post(url, payload);
      console.log("API Response:", resp);
      if (resp.status === 200) {
        const data = resp.data;
        console.log("data", data);
        return data;
      }
    } catch (err) {
      console.error("Failed to fetch cost details from the API", err);
      return null;
    }
  };

  const fetchAllCostDetails = async () => {
    setLoading(true);

    try {
      const allResults = await Promise.all(
        accountIds.map((id) => GetCostDetailsSummaryData([id]))
      );
      const details = [];
      const summary = {};
      const forecast = {};
      const dailyCostsList = {};
      const weeklyCostsList = {};
      const monthlyCostsList = {};

      allResults.forEach((result, index) => {
        const accountId = accountIds[index];

        if (result) {
          details.push(result);
          summary[accountId] =
            result?.get_aws_cost_summary_details?.total_cost_summary
              ?.total_and_avg[0] || {};
          forecast[accountId] =
            result?.get_aws_cost_summary_details?.total_cost_forecast_summary
              ?.forecast_aggregate_summary || {};
          dailyCostsList[accountId] =
            result?.get_aws_cost_summary_details?.daily_cost_summary
              ?.service_wise_daily_usage_and_fee_cost_list || [];
          weeklyCostsList[accountId] =
            result?.get_aws_cost_summary_details?.weekly_cost_summary
              ?.service_wise_weekly_usage_and_fee_cost_list || [];
          monthlyCostsList[accountId] =
            result?.get_aws_cost_summary_details?.monthly_cost_summary
              ?.service_wise_monthly_cost || [];
        }
      });

      setCostDetails(details);
      setSummaryData(summary);
      setForecastData(forecast);
      setDailyCosts(dailyCostsList);
      setWeeklyCosts(weeklyCostsList);
      setMonthlyCosts(monthlyCostsList);

      console.log("details", details);
      console.log("summary", summary);
      console.log("forecast", forecast);
      console.log("monthly", monthlyCostsList);
    } catch (error) {
      console.error("Failed to fetch all cost details:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCostDetails();
  }, [selectedFilters]);

  const calculateGlobalSummary = () => {
    let pastMonthCostSum = 0;
    let currentMonthCostSum = 0;
    let forecastCostSum = 0;
    let totalDailyAvg = 0;
    let accountCount = accountIds.length;

    accountIds.forEach((accountId) => {
      pastMonthCostSum += parseFloat(
        summaryData[accountId]?.total_cost_past_month || 0
      );
      currentMonthCostSum += parseFloat(
        summaryData[accountId]?.total_cost_current_month || 0
      );
      forecastCostSum += parseFloat(forecastData[accountId]?.total_cost || 0);
      totalDailyAvg += parseFloat(
        summaryData[accountId]?.average_daily_cost || 0
      );
    });

    return {
      pastMonthCost: pastMonthCostSum.toFixed(2),
      currentMonthCost: currentMonthCostSum.toFixed(2),
      forecastCost: forecastCostSum.toFixed(2),
      averageDailyCost: (totalDailyAvg / accountCount).toFixed(2),
    };
  };
  const awsLogoUrl =
    "https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png"; // Link to AWS logo

  const GlobalSummaryCards = () => {
    const getCostDisplay = (value) => {
      return value ? `$${parseFloat(value).toFixed(2)}` : "--";
    };

    const totalPastMonthCost = accountIds.reduce((sum, accountId) => {
      return (
        sum + (parseFloat(summaryData[accountId]?.total_cost_past_month) || 0)
      );
    }, 0);

    const totalCurrentMonthCost = accountIds.reduce((sum, accountId) => {
      return (
        sum +
        (parseFloat(summaryData[accountId]?.total_cost_current_month) || 0)
      );
    }, 0);

    const totalForecastCost = accountIds.reduce((sum, accountId) => {
      return sum + (parseFloat(forecastData[accountId]?.total_cost) || 0);
    }, 0);

    const totalAverageDailyCost = accountIds.reduce((sum, accountId) => {
      return (
        sum + (parseFloat(summaryData[accountId]?.average_daily_cost) || 0)
      );
    }, 0);

    const averageDailyCostPerAccount =
      accountIds.length > 0 ? totalAverageDailyCost / accountIds.length : 0;

    return (
      <>
        <div className="aggregate-title-container">
          <div className="aggregate-title">
            <img
              src={awsLogoUrl}
              alt="AWS Logo"
              className="aws-logo-aggregate"
            />
            Consolidated Overview for (
            <span className="aggregate-account-ids">
              {accountIds.join(", ")}
            </span>
            &nbsp;)
          </div>
        </div>
        <div className="global-billing-cards-container">
          <div className="global-billing-card">
            <h3>Past Month Cost</h3>
            <p>{getCostDisplay(totalPastMonthCost)}</p>
          </div>
          <div className="global-billing-card">
            <h3>Current Month Cost</h3>
            <p>{getCostDisplay(totalCurrentMonthCost)}</p>
          </div>
          <div className="global-billing-card">
            <h3>Forecast Cost</h3>
            <p>{getCostDisplay(totalForecastCost)}</p>
          </div>
          <div className="global-billing-card">
            <h3>Average Daily Cost</h3>
            <p>{getCostDisplay(averageDailyCostPerAccount)}</p>
          </div>
        </div>
      </>
    );
  };

  const getSummaryCards = (accountId) => {
    const getCostDisplay = (value) => {
      return value ? `$${parseFloat(value).toFixed(2)}` : "--";
    };

    const pastMonthCost = summaryData[accountId]?.total_cost_past_month || 0;
    const currentMonthCost =
      summaryData[accountId]?.total_cost_current_month || 0;
    const forecastCost = forecastData[accountId]?.total_cost || 0;
    const averageDailyCost = summaryData[accountId]?.average_daily_cost || 0;

    return (
      <div className="billing-cards-container">
        <div className="billing-card">
          <h3>Past Month Cost</h3>
          <p>{getCostDisplay(pastMonthCost)}</p>
        </div>
        <div className="billing-card">
          <h3>Current Month Cost</h3>
          <p>{getCostDisplay(currentMonthCost)}</p>
        </div>
        <div className="billing-card">
          <h3>
            Forecast Cost
            {/* Forecast Cost ({forecastData[accountId]?.start_date || 'N/A'} to
              {forecastData[accountId]?.end_date || 'N/A'}) */}
          </h3>
          <p>{getCostDisplay(forecastCost)}</p>
        </div>
        <div className="billing-card">
          <h3>Daily Average</h3>
          <p>{getCostDisplay(averageDailyCost)}</p>
        </div>
      </div>
    );
  };

  const GetDailyDataTable = ({ dailyCosts }) => {
    if (!dailyCosts || !Array.isArray(dailyCosts) || dailyCosts.length === 0) {
      console.error("Invalid or empty dailyCosts data:", dailyCosts);
      return <p>No daily cost data available.</p>;
    }

    const today = new Date();
    const monthName = today.toLocaleString("default", { month: "short" });
    const daysInMonth = Array.from(
      {
        length: new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        ).getDate(),
      },
      (_, i) => `${monthName} ${i + 1}`
    );

    const dailyCostMap = {};

    dailyCosts.forEach((cost) => {
      if (!cost.product_product_name || !cost.usage_day || !cost.total_cost) {
        console.warn("Incomplete cost data:", cost);
        return; // Skip this entry
      }

      const usageDay = new Date(cost.usage_day);

      if (isNaN(usageDay.getTime())) {
        console.warn("Invalid date:", cost.usage_day);
        return; // Skip invalid date
      }

      const dayIndex = usageDay.getDate() - 1;
      if (dayIndex < 0 || dayIndex >= daysInMonth.length) {
        console.warn(
          "Invalid usage day index:",
          dayIndex,
          "for date:",
          cost.usage_day
        );
        return; // Skip out-of-range dates
      }

      if (!dailyCostMap[cost.product_product_name]) {
        dailyCostMap[cost.product_product_name] = Array(
          daysInMonth.length
        ).fill(null);
      }

      dailyCostMap[cost.product_product_name][dayIndex] = parseFloat(
        cost.total_cost
      ).toFixed(2);
    });

    return (
      <div className="billing-table-container">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              {daysInMonth.map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(dailyCostMap).map((service) => (
              <tr key={service}>
                <td className="left-align">{service}</td>
                {dailyCostMap[service].map((cost, dayIndex) => {
                  let cellClass = "cell-default";

                  // Handle empty cell data
                  if (cost === null || cost === "0.00") {
                    cost = "";
                    cellClass = "cell-zero"; // Optional: apply a specific class for empty cells
                  } else if (dayIndex > 0) {
                    const previousCost =
                      dailyCostMap[service][dayIndex - 1] || "0.00";
                    if (parseFloat(cost) > parseFloat(previousCost)) {
                      cellClass = "cell-increase";
                    } else if (parseFloat(cost) < parseFloat(previousCost)) {
                      cellClass = "cell-decrease";
                    }
                  }

                  return (
                    <td key={dayIndex} className={cellClass}>
                      {cost ? `$${cost}` : ""}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const GetWeeklyDataTable = ({ weeklyCosts }) => {
    if (
      !weeklyCosts ||
      !Array.isArray(weeklyCosts) ||
      weeklyCosts.length === 0
    ) {
      console.error("Invalid or empty weeklyCosts data:", weeklyCosts);
      return <p>No weekly cost data available.</p>;
    }

    const weeklyCostMap = {};
    const weekRanges = new Set();

    weeklyCosts.forEach((cost) => {
      const serviceName = cost.product_product_name;
      const startDate = new Date(cost.usage_week_start);
      const endDate = new Date(cost.usage_week_end);
      const formattedRange = `${startDate.getDate()} ${startDate.toLocaleString(
        "default",
        {
          month: "short",
        }
      )} to ${endDate.getDate()} ${endDate.toLocaleString("default", {
        month: "short",
      })}`;

      if (!weeklyCostMap[serviceName]) {
        weeklyCostMap[serviceName] = [];
      }

      weeklyCostMap[serviceName].push({
        range: formattedRange,
        total_cost: parseFloat(cost.total_cost).toFixed(2),
      });

      weekRanges.add(formattedRange);
    });

    const uniqueRanges = Array.from(weekRanges);

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              {uniqueRanges.map((range, index) => (
                <th key={index}>
                  {range.split(" to ").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(weeklyCostMap).map((service) => (
              <tr key={service}>
                <td className="left-align">{service}</td>
                {uniqueRanges.map((range, weekIndex) => {
                  let cost = "";
                  const weekData = weeklyCostMap[service].find(
                    (data) => data.range === range
                  );
                  if (weekData) {
                    cost = weekData.total_cost;
                  }
                  let cellClass = "cell-default";

                  if (cost === "") {
                    cellClass = "cell-zero";
                  } else if (weekIndex > 0) {
                    const previousCost =
                      weeklyCostMap[service].find(
                        (data) => data.range === uniqueRanges[weekIndex - 1]
                      )?.total_cost || "";
                    if (parseFloat(cost) > parseFloat(previousCost)) {
                      cellClass = "cell-increase";
                    } else if (parseFloat(cost) < parseFloat(previousCost)) {
                      cellClass = "cell-decrease";
                    }
                  }

                  return (
                    <td key={weekIndex} className={cellClass}>
                      {cost ? `$${cost}` : ""}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const GetMonthlyDataTable = ({ monthlyCosts }) => {
    if (
      !monthlyCosts ||
      !Array.isArray(monthlyCosts) ||
      monthlyCosts.length === 0
    ) {
      console.error("Invalid or empty monthlyCosts data:", monthlyCosts);
      return <p>No monthly cost data available.</p>;
    }

    const monthlyCostMap = {};

    monthlyCosts.forEach((cost) => {
      if (!cost.product_product_name || !cost.total_cost) {
        console.warn("Incomplete cost data:", cost);
        return;
      }
      monthlyCostMap[cost.product_product_name] = parseFloat(
        cost.total_cost
      ).toFixed(2);
    });

    const monthYearLabel = new Date().toLocaleString("default", {
      month: "long",
      year: "numeric",
    });

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>{monthYearLabel}</th>{" "}
            </tr>
          </thead>
          <tbody>
            {Object.keys(monthlyCostMap).length > 0 ? (
              Object.keys(monthlyCostMap).map((service) => (
                <tr key={service}>
                  <td className="left-align">{service}</td>
                  <td
                    className={
                      monthlyCostMap[service] === "0.00"
                        ? "cell-zero"
                        : "cell-default"
                    }
                  >{`$${monthlyCostMap[service]}`}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const getChartData = ({
    dailyCosts = [],
    weeklyCosts = [],
    monthlyCosts = [],
  } = {}) => {
    let categories = [];
    let seriesData = {};

    if (activeTab === DailyTab) {
      const today = new Date();
      categories = Array.from(
        { length: today.getDate() },
        (_, i) => `${i + 1}`
      );
      dailyCosts.forEach((cost) => {
        const day = new Date(cost.usage_day).getDate();
        if (!seriesData[cost.product_product_name]) {
          seriesData[cost.product_product_name] = Array(today.getDate()).fill(
            0
          );
        }
        seriesData[cost.product_product_name][day - 1] += parseFloat(
          cost.total_cost
        );
      });
    } else if (activeTab === WeeklyTab) {
      const weeks = Array.from(
        new Set(
          weeklyCosts.map((cost) => {
            const startDate = new Date(cost.usage_week_start);
            const endDate = new Date(cost.usage_week_end);
            return `${startDate.getDate()} ${startDate.toLocaleString(
              "default",
              {
                month: "short",
              }
            )} to ${endDate.getDate()} ${endDate.toLocaleString("default", {
              month: "short",
            })}`;
          })
        )
      );
      categories = weeks;
      weeklyCosts.forEach((cost) => {
        const week = weeks.findIndex((w) =>
          w.includes(`${new Date(cost.usage_week_start).getDate()}`)
        );
        if (!seriesData[cost.product_product_name]) {
          seriesData[cost.product_product_name] = Array(weeks.length).fill(0);
        }
        seriesData[cost.product_product_name][week] += parseFloat(
          cost.total_cost
        );
      });
    } else if (activeTab === MonthlyTab) {
      const months = Array.from(
        new Set(
          monthlyCosts.map((cost) =>
            new Date(cost.usage_month).toLocaleString("default", {
              month: "short",
            })
          )
        )
      );
      categories = months;
      monthlyCosts.forEach((cost) => {
        const monthIndex = months.indexOf(
          new Date(cost.usage_month).toLocaleString("default", {
            month: "short",
          })
        );
        if (!seriesData[cost.product_product_name]) {
          seriesData[cost.product_product_name] = Array(months.length).fill(0);
        }
        seriesData[cost.product_product_name][monthIndex] += parseFloat(
          cost.total_cost
        );
      });
    }

    // Calculate total costs for each service across all categories
    const totalCosts = Object.keys(seriesData).map((service) => ({
      service,
      totalCost: seriesData[service].reduce((acc, val) => acc + val, 0),
    }));

    // Sort services by total cost in descending order
    totalCosts.sort((a, b) => b.totalCost - a.totalCost);

    // Sort seriesData based on the sorted order of services
    const sortedSeries = totalCosts.map((item) => ({
      name: item.service,
      data: seriesData[item.service].map((value) =>
        parseFloat(value).toFixed(2)
      ),
    }));

    return { categories, series: sortedSeries };
  };

  const renderChart = (accountId) => {
    const chartData = getChartData({
      dailyCosts: dailyCosts[accountId] || [],
      weeklyCosts: weeklyCosts[accountId] || [],
      monthlyCosts: monthlyCosts[accountId] || [],
    });
    const { categories, series } = chartData;

    const barWidth = 40; // Set the width of the bars in pixels

    const options = {
      chart: {
        type: "bar",
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: `${barWidth}px`,
          gap: "10px",
          dataLabels: {
            position: "top",
          },
        },
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "Cost ($)",
        },
      },
      tooltip: {
        y: {
          formatter: (val) => `$${parseFloat(val).toFixed(2)}`,
        },
      },
      legend: {
        position: "top",
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 10,
          left: 10,
        },
      },
    };

    return (
      <ApexCharts options={options} series={series} type="bar" height={350} />
    );
  };

  const downloadPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const content = reportRef.current;

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("BillingSummary.pdf");
    });
  };

  const exportToExcel = ({
    dailyCosts = [],
    weeklyCosts = [],
    monthlyCosts = [],
  } = {}) => {
    const dailyData = dailyCosts.map((cost) => ({
      Service: cost.product_product_name,
      Day: new Date(cost.usage_day).getDate(),
      Cost: parseFloat(cost.total_cost).toFixed(2),
    }));

    const weeklyData = weeklyCosts.map((cost) => ({
      Service: cost.product_product_name,
      Week_Start: new Date(cost.usage_week_start).toLocaleDateString(),
      Week_End: new Date(cost.usage_week_end).toLocaleDateString(),
      Cost: parseFloat(cost.total_cost).toFixed(2),
    }));

    const monthlyData = monthlyCosts.map((cost) => ({
      Service: cost.product_product_name,
      Month: new Date(cost.usage_month).toLocaleString("default", {
        month: "short",
        year: "numeric",
      }),
      Cost: parseFloat(cost.total_cost).toFixed(2),
    }));

    const wb = XLSX.utils.book_new();

    const wsDaily = XLSX.utils.json_to_sheet(dailyData);
    XLSX.utils.book_append_sheet(wb, wsDaily, "Daily Costs");

    const wsWeekly = XLSX.utils.json_to_sheet(weeklyData);
    XLSX.utils.book_append_sheet(wb, wsWeekly, "Weekly Costs");

    const wsMonthly = XLSX.utils.json_to_sheet(monthlyData);
    XLSX.utils.book_append_sheet(wb, wsMonthly, "Monthly Costs");

    XLSX.writeFile(wb, "BillingSummary.xlsx");
  };

  const renderContent = (accountId) => {
    if (
      !costDetails.length ||
      (!dailyCosts[accountId]?.length &&
        !weeklyCosts[accountId]?.length &&
        !monthlyCosts[accountId]?.length)
    ) {
      return <p>No Data Available</p>;
    }

    return (
      <>
        {getSummaryCards(accountId)}
        <div className="graph-btns">
          <div className="left-section">
            <button
              className={`graph-button ${
                chartVisible[accountId] ? "hide" : "show"
              }`}
              onClick={() => toggleChartVisibility(accountId)}
            >
              {chartVisible[accountId] ? "Hide Graph" : "Show Graph"}
            </button>
          </div>
          <div className="right-section">
            <Button
              onClick={() =>
                exportToExcel({
                  dailyCosts: dailyCosts[accountId] || [],
                  weeklyCosts: weeklyCosts[accountId] || [],
                  monthlyCosts: monthlyCosts[accountId] || [],
                })
              }
              className="excel-button"
              size="large"
            >
              Export to Excel
            </Button>
            {/*<Button onClick={downloadPDF} className="pdf-button" size="large">*/}
            {/*    Download PDF*/}
            {/*</Button>*/}
          </div>
        </div>

        {chartVisible[accountId] && renderChart(accountId)}
        <div className="tab-container">
          <Tabs
            defaultActiveKey="1"
            onChange={(key) => setActiveTab(key)}
            className="billing-custom-tabs"
          >
            <TabPane
              tab={<span className="tab-button">Daily Costs</span>}
              key="DailyTab"
            >
              <GetDailyDataTable dailyCosts={dailyCosts[accountId] || []} />
            </TabPane>
            <TabPane
              tab={<span className="tab-button">Weekly Costs</span>}
              key="WeeklyTab"
            >
              <GetWeeklyDataTable weeklyCosts={weeklyCosts[accountId] || []} />
            </TabPane>
            <TabPane
              tab={<span className="tab-button">Monthly Costs</span>}
              key="MonthlyTab"
            >
              <GetMonthlyDataTable
                monthlyCosts={monthlyCosts[accountId] || []}
              />
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div>
      <GlobalSummaryCards />
      <Collapse accordion>
        {costDetails.length === 0 ? (
          <Collapse.Panel header="No Data" key="1">
            <p>No data available</p>
          </Collapse.Panel>
        ) : (
          costDetails.map((detail, index) => {
            const accountId = accountIds[index];
            return (
              <Collapse.Panel
                header={
                  <div className="accordion-header">
                    <img src={awsLogoUrl} alt="AWS Logo" className="aws-logo" />
                    {`Account ID: ${accountId}`}
                  </div>
                }
                key={index}
              >
                {renderContent(accountId)}
              </Collapse.Panel>
            );
          })
        )}
      </Collapse>
    </div>
  );
};

export default BillingSummary;

// <img style={{ height: '2.3vw' }} src='https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png' alt='' />{' '}
