import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../Table/DataTable";
import { GetSecurityScanUrl } from "../../../../routes";
import { GetLoginCredentials } from "../../../../components/CommonFunctions";
import { GetLoginCredentialsAsJsonObject } from "../../../../components/UserCredentials";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ListView = ({ data, onBackClick }) => {
  const [findingsData, setFindingsData] = useState([]);
  const { status = "", severity = "", dashboardData = {} } = data || {};
  console.log({ dashboardData });
  const getSecurityScanDetailsPayload = () => {
    console.log("called");
    let filters_args = {
      accounts: [dashboardData.aws_account_id],

      finding_status: ["FAIL", "MANUAL", "PASS"],

      severity_type: ["low", "medium", "critical", "high"],

      region_type: ["us-east-1", "ap-south-1"],
    };

    return {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      command_type: "get_scan_details",
      command_args: {
        AccountId: dashboardData.aws_account_id,
        ExpectedScanId: dashboardData.scan_id,
        filters: filters_args,
      },
    };
  };

  const handleGetScanDetails = async (event) => {
    const url = GetSecurityScanUrl();
    const payload = getSecurityScanDetailsPayload();

    try {
      const resp = await axios.post(url, payload);
      console.log(resp);
      if (resp.status === 200) {
        console.log("Scan details data", resp.data);
      }
      return resp;
    } catch (err) {
      console.log("handleStartSecurityScan to " + url + " failed");
    }
  };

  const getFindings = async () => {
    console.log("Status:", status);
    console.log("Severity:", severity);
    const resp = await handleGetScanDetails();
    const findings = resp?.data?.findings || [];
    console.log("findings", findings);
    let processedFindings = [...findings];
    if (status) {
      processedFindings = processedFindings.filter(
        (item) => item.Status === status
      );
    }
    if (severity) {
      processedFindings = processedFindings.filter(
        (item) => item.Severity === severity
      );
    }
    setFindingsData(processedFindings);
    console.log("Processed findings:", processedFindings);
  };

  useEffect(() => {
    getFindings();
  }, [status, severity, dashboardData]);

  return (
    <div>
      <Button
        className="back-btn"
        onClick={onBackClick}
        icon={<ArrowLeftOutlined />}
      >
        Back
      </Button>
      <DataTable data={findingsData} />
    </div>
  );
};

export default ListView;
