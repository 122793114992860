// App.js

import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';

import './styles/global.css';
import './styles/ResourceMaker.css';
import './styles/CostManager.css';

import React, { useEffect }  from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Approutes from './Approutes';
import { ConfirmationModalProvider } from './context/ConfirmationModalContext';
import PostHogPageviewTracker  from "./PostHogPageviewTracker";

const App = () => {
  return (
    <div className='App'>
      <ToastContainer />
      <ConfirmationModalProvider>
        <Router>
            <PostHogPageviewTracker/>
          <Approutes />
        </Router>
      </ConfirmationModalProvider>
    </div>
  );
};

export default App;

//
