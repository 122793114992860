import '../styles/global.css';

import { Dropdown, Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import User from '../images/user.png';
import { USER_LOGOUT } from './statemanagement/ActionsList';
import { DispatchAction } from './statemanagement/store';

function UserProfile() {
  const history = useHistory();

  const handleLogout = () => {
    DispatchAction(USER_LOGOUT, {
      what: USER_LOGOUT,
    });
    history.push('/home');
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleLogout()} className='nav-link'>
        Signout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='profile-details' style={{ marginLeft: 'auto', width: 'fit-content', marginRight: '1vw' }}>
      <Dropdown overlay={menu} trigger={['click']}>
        <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
          <img style={{ height: '1.5vw' }} alt='' src={User} />
        </a>
      </Dropdown>
    </div>
  );
}

export default UserProfile;
