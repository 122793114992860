import React from 'react';
import { Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { copyString } from '../../utils/stringHelper';

const CopyIcon = ({ title, text, iconOnly }) => {
  return iconOnly ? (
    <Tooltip title={'Copy' || title}>
      <CopyOutlined onClick={() => copyString(text)} />
    </Tooltip>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <span>{text}</span>
      <Tooltip title={'Copy' || title}>
        <CopyOutlined onClick={() => copyString(text)} />
      </Tooltip>
    </div>
  );
};

export default CopyIcon;
