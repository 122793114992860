import React from 'react';
import Chart from 'react-apexcharts';

const ApexPieChart = ({ data, options = {}, height }) => {
  const sortedData = [...data].sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
  const chartOptions = {
    labels: sortedData.map((item) => item.label),
    series: sortedData.map((item) => parseFloat(item.value)),
    legend: {
      position: 'right',
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      fillSeriesColor: false,
      followCursor: true,
      style: {
        fontSize: '14px',
      },
      y: {
        formatter: function (value) {
          return '$' + value;
        },
      },
    },
    ...options,
  };
  return <Chart options={chartOptions} series={chartOptions.series} type='pie' width={'100%'} height={height || 350} />;
};

export default ApexPieChart;
