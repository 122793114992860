import React, { useEffect, useState } from "react";
import axios from "axios";
import { Select, DatePicker, Spin, Button, Tabs, Collapse } from "antd";
import { GetAllAccountsURL, GetCostManagerV2Url } from "../../../routes";
import {
  GetLoginCredentialsAsJsonObject,
  GetLoginCredentials,
} from "../../UserCredentials";
import "./DetailedView.css";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const DetailedView = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [costData, setCostData] = useState({
    daily: {},
    weekly: {},
    monthly: {},
  });
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [submitted, setSubmitted] = useState(false);
  const awsLogoUrl =
    "https://bfw-concerto-prod-ui-assets.s3.ap-south-1.amazonaws.com/cloud/aws/aws.png";

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccountIds.length > 0)
      GetCostDetailsData(selectedAccountIds, activeTab);
  }, [activeTab]);

  const fetchAllAccounts = async () => {
    const url = GetAllAccountsURL();
    const payload = getAllAccountsRequestPayload();

    try {
      const resp = await axios.post(url, payload);
      if (
        resp.status === 200 &&
        typeof resp.data === "object" &&
        resp.data.message !== "Internal server error"
      ) {
        const accountOptions = resp.data.account_info_details_list.map(
          (account) => ({
            value: account.AccountIdStr,
            label: `${account.AccountIdStr} ${
              account.FriendlyName || ""
            }`.trim(), // Concatenate AccountIdStr and FriendlyName
          })
        );
        setAccounts(accountOptions);
      }
    } catch (err) {
      console.log("Failed to fetch account data from " + url, err);
    }
  };

  const getAllAccountsRequestPayload = () => {
    return {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(),
      command_to_execute: "get_account_ids",
    };
  };

  const handleAccountSelection = (selectedValues) => {
    const selectedIds = selectedValues || [];
    setSelectedAccountIds(selectedIds);
  };

  const handleGoClick = () => {
    if (selectedAccountIds.length > 0) {
      GetCostDetailsData(selectedAccountIds, activeTab);
      setSubmitted(true);
    }
  };

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start, end]);
    } else {
      setDateRange([null, null]);
    }
  };

  const GetCostDetailsPayload = (accountIdsList, interval) => {
    const payload = {
      concerto_user_credentials: GetLoginCredentials(),
      command_type: "get_aws_cost_breakup_details",
      command_args: {
        AccountId: accountIdsList,
        Interval: interval,
      },
    };

    if (dateRange[0] && dateRange[1]) {
      payload.command_args.fromDate = dateRange[0].toISOString().split("T")[0];
      payload.command_args.toDate = dateRange[1].toISOString().split("T")[0];
    }

    return payload;
  };

  const fetchCostDetailsForAccount = async (accountId, interval) => {
    const url = GetCostManagerV2Url();
    const payload = GetCostDetailsPayload([accountId], interval);
    console.log("payload", payload);
    try {
      const resp = await axios.post(url, payload);

      console.log("API Response:", resp.data);

      if (resp.status === 200) {
        const {
          data: {
            get_aws_cost_breakup_details: { cost_breakup_details = {} } = {},
          } = {},
        } = resp;

        return {
          accountId,
          data:
            interval === "daily"
              ? cost_breakup_details?.[`grouped_${interval}_cost_data`] || []
              : cost_breakup_details?.[`grouped_cost_${interval}_data`],
        };
      }
    } catch (err) {
      console.error(
        `Failed to fetch cost details for account ${accountId}`,
        err
      );
      return { accountId, data: [] };
    }
  };

  const GetCostDetailsData = async (accountIdsList, interval) => {
    setLoading(true);

    const promises = accountIdsList.map((accountId) =>
      fetchCostDetailsForAccount(accountId, interval)
    );

    try {
      const results = await Promise.all(promises);

      const aggregatedData = results.reduce((acc, { accountId, data }) => {
        if (Array.isArray(data)) {
          acc[accountId] = data;
        } else {
          console.warn(`Unexpected data format for account ${accountId}`, data);
        }
        return acc;
      }, {});

      console.log("Aggregated Data:", aggregatedData);

      setCostData((prevData) => ({
        ...prevData,
        [interval]: aggregatedData,
      }));
    } catch (err) {
      console.error("Failed to fetch cost details", err);
    } finally {
      setLoading(false);
    }
  };

  const renderCostData = () => {
    const accountIds = Object.keys(costData.daily);

    if (accountIds.length === 0) return null;

    return accountIds.map((accountId) => (
      <Collapse.Panel
        header={
          <div className="accordion-header">
            <img src={awsLogoUrl} alt="AWS Logo" className="aws-logo" />
            {`Account ID: ${accountId}`}
          </div>
        }
        key={accountId}
      >
        <Tabs
          defaultActiveKey="daily"
          tabPosition="top"
          className="detailed-view-tabs"
          onChange={(tab) => setActiveTab(tab)}
        >
          <TabPane tab="Daily" key="daily" className="detailed-view-tab-pane">
            {costData.daily[accountId] &&
            costData.daily[accountId].length > 0 ? (
              <CostDailyBreakupTable data={costData.daily[accountId]} />
            ) : (
              <div>No data available for Daily</div>
            )}
          </TabPane>
          <TabPane tab="Weekly" key="weekly">
            {costData.weekly[accountId] &&
            costData.weekly[accountId].length > 0 ? (
              <CostWeeklyBreakupTable data={costData.weekly[accountId]} />
            ) : (
              <div>No data available for Weekly</div>
            )}
          </TabPane>
          <TabPane tab="Monthly" key="monthly">
            {costData.monthly[accountId] &&
            costData.monthly[accountId].length > 0 ? (
              <CostMonthlyBreakupTable data={costData.monthly[accountId]} />
            ) : (
              <div>No data available for Monthly</div>
            )}
          </TabPane>
        </Tabs>
      </Collapse.Panel>
    ));
  };

  return (
    <div className="detailed-view-container">
      <div className="accounts-selection-container">
        <div className="filter-item">
          <label className="filter-label">Select Account</label>
          <Select
            mode="multiple"
            options={accounts}
            onChange={handleAccountSelection}
            className="filter-select"
            placeholder="Select accounts"
          >
            {accounts.map((account) => (
              <Option key={account.value} value={account.value}>
                {account.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="date-selection-container">
          <label htmlFor="dateRange" className="date-label">
            Date Range
          </label>
          <RangePicker
            id="dateRange"
            value={dateRange}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            placeholder={["Start date", "End date"]}
            className="detailed-view-date-picker"
          />
        </div>

        <Button type="primary" className="go-button" onClick={handleGoClick}>
          Submit
        </Button>
      </div>
      <Spin spinning={loading}>
        {submitted && (
          <>
            <div className="aggregate-title-container">
              <div className="aggregate-title">
                <img
                  src={awsLogoUrl}
                  alt="AWS Logo"
                  className="aws-logo-aggregate"
                />
                Detailed Breakup View for (
                <span className="aggregate-account-ids">
                  {selectedAccountIds.join(", ")}
                </span>
                &nbsp;)
              </div>
            </div>

            <div className="detailed-view-tabs-container">
              <Collapse accordion>
                {Object.keys(costData.daily).length === 0 ? (
                  <Collapse.Panel header="No Data" key="1">
                    <p>No data available</p>
                  </Collapse.Panel>
                ) : (
                  renderCostData()
                )}
              </Collapse>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default DetailedView;

const CostDailyBreakupTable = ({ data }) => {
  const [expandedProductNames, setExpandedProductNames] = useState({});
  const [expandedUsageTypes, setExpandedUsageTypes] = useState({});
  const [expandedItemTypes, setExpandedItemTypes] = useState({});

  const toggleExpandProduct = (productName) => {
    setExpandedProductNames((prevState) => ({
      ...prevState,
      [productName]: !prevState[productName],
    }));
  };

  const toggleExpandUsageType = (productName, usageType) => {
    setExpandedUsageTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}`]:
        !prevState[`${productName}-${usageType}`],
    }));
  };

  const toggleExpandItemType = (productName, usageType, itemType) => {
    setExpandedItemTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}-${itemType}`]:
        !prevState[`${productName}-${usageType}-${itemType}`],
    }));
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const uniqueDates = Array.from(new Set(data.map((item) => item.usage_date)));

  const groupedByProductName = groupBy(data, "product_product_name");

  return (
    <div className="cost-daily-table">
      <table>
        <thead>
          <tr>
            <th>Product Name</th>
            {uniqueDates.map((date) => (
              <th key={date} className="date-header">
                {date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedByProductName).map((productName) => (
            <React.Fragment key={productName}>
              <tr className="product-row">
                <td colSpan={uniqueDates.length + 1} className="product-header">
                  <button
                    onClick={() => toggleExpandProduct(productName)}
                    className="expand-button"
                  >
                    {expandedProductNames[productName] ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronRight />
                    )}
                  </button>
                  <strong>{productName}</strong>
                </td>
              </tr>
              {expandedProductNames[productName] &&
                Object.entries(
                  groupBy(
                    groupedByProductName[productName],
                    "line_item_usage_type"
                  )
                ).map(([usageType, usageItems]) => (
                  <React.Fragment key={usageType}>
                    <tr className="usage-type-row">
                      <td>
                        <button
                          onClick={() =>
                            toggleExpandUsageType(productName, usageType)
                          }
                          className="expand-button"
                        >
                          {expandedUsageTypes[`${productName}-${usageType}`] ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </button>
                        <strong>{usageType}</strong>
                      </td>
                      {uniqueDates.map((date) => {
                        const costData = usageItems.find(
                          (item) => item.usage_date === date
                        );
                        const roundedCost = costData
                          ? parseFloat(costData.total_unblended_cost).toFixed(2)
                          : "0.00";

                        return roundedCost !== "0.00" ? (
                          <td key={date} className="cost-cell">
                            {roundedCost}
                          </td>
                        ) : null;
                      })}
                    </tr>
                    {expandedUsageTypes[`${productName}-${usageType}`] &&
                      Object.entries(
                        groupBy(usageItems, "line_item_line_item_type")
                      ).map(([itemType, itemTypeItems]) => (
                        <React.Fragment key={itemType}>
                          <tr className="item-type-row">
                            <td style={{ paddingLeft: "40px" }}>
                              <strong>{itemType}</strong>
                            </td>
                            {uniqueDates.map((date) => {
                              const costData = itemTypeItems.find(
                                (item) => item.usage_date === date
                              );
                              const roundedCost = costData
                                ? parseFloat(
                                    costData.total_unblended_cost
                                  ).toFixed(2)
                                : "0.00";

                              return roundedCost !== "0.00" ? (
                                <td key={date} className="cost-cell">
                                  {roundedCost}
                                </td>
                              ) : null;
                            })}
                          </tr>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CostWeeklyBreakupTable = ({ data }) => {
  const [expandedProductNames, setExpandedProductNames] = useState({});
  const [expandedUsageTypes, setExpandedUsageTypes] = useState({});
  const [expandedItemTypes, setExpandedItemTypes] = useState({});

  const toggleExpandProduct = (productName) => {
    setExpandedProductNames((prevState) => ({
      ...prevState,
      [productName]: !prevState[productName],
    }));
  };

  const toggleExpandUsageType = (productName, usageType) => {
    setExpandedUsageTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}`]:
        !prevState[`${productName}-${usageType}`],
    }));
  };

  const toggleExpandItemType = (productName, usageType, itemType) => {
    setExpandedItemTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}-${itemType}`]:
        !prevState[`${productName}-${usageType}-${itemType}`],
    }));
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const uniqueWeeks = Array.from(
    new Set(
      data.map((item) => `${item.usage_week_start} to ${item.usage_week_end}`)
    )
  );

  const groupedByProductName = groupBy(data, "product_product_name");

  return (
    <div className="cost-weekly-table">
      <table className="cost-weekly-table">
        <thead>
          <tr>
            <th>Product Name</th>
            {uniqueWeeks.map((week) => (
              <th key={week} className="week-header">
                {week}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedByProductName).map((productName) => (
            <React.Fragment key={productName}>
              <tr className="product-row">
                <td colSpan={uniqueWeeks.length + 1} className="product-header">
                  <button
                    onClick={() => toggleExpandProduct(productName)}
                    className="expand-button"
                  >
                    {expandedProductNames[productName] ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronRight />
                    )}
                  </button>
                  <strong>{productName}</strong>
                </td>
              </tr>
              {expandedProductNames[productName] &&
                Object.entries(
                  groupBy(
                    groupedByProductName[productName],
                    "line_item_usage_type"
                  )
                ).map(([usageType, usageItems]) => (
                  <React.Fragment key={usageType}>
                    <tr className="usage-type-row">
                      <td className="usage-type-name">
                        <button
                          onClick={() =>
                            toggleExpandUsageType(productName, usageType)
                          }
                          className="expand-button"
                        >
                          {expandedUsageTypes[`${productName}-${usageType}`] ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </button>
                        <strong>{usageType}</strong>
                      </td>
                      {uniqueWeeks.map((week) => {
                        const costData = usageItems.find(
                          (item) =>
                            item.usage_week_start === week.split(" to ")[0] &&
                            item.usage_week_end === week.split(" to ")[1]
                        );
                        const roundedCost = costData
                          ? parseFloat(costData.total_unblended_cost).toFixed(2)
                          : "0.00";

                        return roundedCost !== "0.00" ? (
                          <td key={week} className="cost-cell">
                            {roundedCost}
                          </td>
                        ) : null;
                      })}
                    </tr>
                    {expandedUsageTypes[`${productName}-${usageType}`] &&
                      Object.entries(
                        groupBy(usageItems, "line_item_line_item_type")
                      ).map(([itemType, itemTypeItems]) => (
                        <React.Fragment key={itemType}>
                          <tr className="item-type-row">
                            <td className="item-type-name">
                              <strong>{itemType}</strong>
                            </td>
                            {uniqueWeeks.map((week) => {
                              const costData = itemTypeItems.find(
                                (item) =>
                                  item.usage_week_start ===
                                    week.split(" to ")[0] &&
                                  item.usage_week_end === week.split(" to ")[1]
                              );
                              const roundedCost = costData
                                ? parseFloat(
                                    costData.total_unblended_cost
                                  ).toFixed(2)
                                : "0.00";

                              return roundedCost !== "0.00" ? (
                                <td key={week} className="cost-cell">
                                  {roundedCost}
                                </td>
                              ) : null;
                            })}
                          </tr>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CostMonthlyBreakupTable = ({ data }) => {
  const [expandedProductNames, setExpandedProductNames] = useState({});
  const [expandedUsageTypes, setExpandedUsageTypes] = useState({});
  const [expandedItemTypes, setExpandedItemTypes] = useState({});

  const toggleExpandProduct = (productName) => {
    setExpandedProductNames((prevState) => ({
      ...prevState,
      [productName]: !prevState[productName],
    }));
  };

  const toggleExpandUsageType = (productName, usageType) => {
    setExpandedUsageTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}`]:
        !prevState[`${productName}-${usageType}`],
    }));
  };

  const toggleExpandItemType = (productName, usageType, itemType) => {
    setExpandedItemTypes((prevState) => ({
      ...prevState,
      [`${productName}-${usageType}-${itemType}`]:
        !prevState[`${productName}-${usageType}-${itemType}`],
    }));
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const uniqueMonths = Array.from(
    new Set(data.map((item) => item.usage_month))
  );

  const groupedByProductName = groupBy(data, "product_product_name");

  return (
    <div className="cost-weekly-table">
      <table className="cost-weekly-table">
        <thead>
          <tr>
            <th>Product Name</th>
            {uniqueMonths.map((month) => (
              <th key={month} className="week-header">
                {month}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedByProductName).map((productName) => (
            <React.Fragment key={productName}>
              <tr className="product-row">
                <td
                  colSpan={uniqueMonths.length + 1}
                  className="product-header"
                >
                  <button
                    onClick={() => toggleExpandProduct(productName)}
                    className="expand-button"
                  >
                    {expandedProductNames[productName] ? (
                      <FaChevronDown />
                    ) : (
                      <FaChevronRight />
                    )}
                  </button>
                  <strong>{productName}</strong>
                </td>
              </tr>
              {expandedProductNames[productName] &&
                Object.entries(
                  groupBy(
                    groupedByProductName[productName],
                    "line_item_usage_type"
                  )
                ).map(([usageType, usageItems]) => (
                  <React.Fragment key={usageType}>
                    <tr className="usage-type-row">
                      <td className="usage-type-name">
                        <button
                          onClick={() =>
                            toggleExpandUsageType(productName, usageType)
                          }
                          className="expand-button"
                        >
                          {expandedUsageTypes[`${productName}-${usageType}`] ? (
                            <FaChevronDown />
                          ) : (
                            <FaChevronRight />
                          )}
                        </button>
                        <strong>{usageType}</strong>
                      </td>
                      {uniqueMonths.map((month) => {
                        const costData = usageItems.find(
                          (item) => item.usage_month === month
                        );
                        const roundedCost = costData
                          ? parseFloat(costData.total_unblended_cost).toFixed(2)
                          : "0.00";

                        return roundedCost !== "0.00" ? (
                          <td key={month} className="cost-cell">
                            {roundedCost}
                          </td>
                        ) : null;
                      })}
                    </tr>
                    {expandedUsageTypes[`${productName}-${usageType}`] &&
                      Object.entries(
                        groupBy(usageItems, "line_item_line_item_type")
                      ).map(([itemType, itemTypeItems]) => (
                        <React.Fragment key={itemType}>
                          <tr className="item-type-row">
                            <td className="item-type-name">
                              <strong>{itemType}</strong>
                            </td>
                            {uniqueMonths.map((month) => {
                              const costData = itemTypeItems.find(
                                (item) => item.usage_month === month
                              );
                              const roundedCost = costData
                                ? parseFloat(
                                    costData.total_unblended_cost
                                  ).toFixed(2)
                                : "0.00";

                              return roundedCost !== "0.00" ? (
                                <td key={month} className="cost-cell">
                                  {roundedCost}
                                </td>
                              ) : null;
                            })}
                          </tr>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};
