import React from 'react';
import { Table } from 'antd';
import CopyIcon from '../../../components/CopyIcon/CopyIcon';

const Ec2InstanceStatuses = ({ data }) => {
  const columns = [
    {
      title: 'Account ID',
      dataIndex: 'accountId',
      key: 'accountId',
      render: (text) => <CopyIcon text={text} title={'Copy Account ID'} />,
    },
    {
      title: 'Instance ID',
      dataIndex: 'instanceId',
      key: 'instanceId',
      render: (text) => <CopyIcon text={text} title={'Copy Instance ID'} />,
    },
    {
      title: 'Previous Status',
      dataIndex: 'prevStatus',
      key: 'prevStatus',
    },
    {
      title: 'Current Status',
      dataIndex: 'currStatus',
      key: 'currStatus',
    },
  ];

  return (
    <div style={{ width: '60%' }}>
      <h2>EC2 Instance Statuses</h2>
      <div style={{ margin: '1vw', marginTop: 0, position: 'relative' }} className='table-bf'>
        <Table pagination={false} dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default Ec2InstanceStatuses;
