import React, { useState } from 'react';
import { Table, message, Collapse, Button } from 'antd';
import { PoweroffOutlined, PlayCircleOutlined, SyncOutlined } from '@ant-design/icons';
import CopyIcon from '../../../components/CopyIcon/CopyIcon';
import { GetLoginCredentialsAsJsonObject } from '../../../components/UserCredentials';
import axios from 'axios';
import { GetSetEc2InstanceStateUrl } from '../../../routes';
import { aws_icon_url } from '../../Recommendations/Recommendations';

const ActionButtons = ({ instanceId, accountId, stateName, region, onActionClick }) => {
  const buttonStyle = {
    margin: '0 4px',
    display: 'flex',
    alignItems: 'center',
  };
  const handleActionClick = (action) => {
    onActionClick({ instanceId, accountId, action, region });
  };

  if (stateName === 'running') {
    return (
      <div style={{ display: 'flex' }}>
        <Button type='primary' danger onClick={() => handleActionClick('stop')} style={buttonStyle}>
          <PoweroffOutlined />
          Stop
        </Button>
        <Button type='primary' onClick={() => handleActionClick('reboot')} style={buttonStyle}>
          <SyncOutlined />
          Reboot
        </Button>
      </div>
    );
  } else if (stateName === 'stopped') {
    return (
      <Button type='primary' onClick={() => handleActionClick('start')} style={buttonStyle}>
        <PlayCircleOutlined />
        Start
      </Button>
    );
  } else {
    return null;
  }
};

const EC2InstanceInfo = ({ ec2InstancesDataMapList = [] }) => {
  const formattedData = {};

  ec2InstancesDataMapList.forEach((item) => {
    const accountId = item.aws_account_id;
    const region = item.aws_region_code;
    const processedData =
      item.resource_info_data?.map((eachInstance) => ({
        accountId,
        region: region,
        InstanceId: eachInstance.InstanceId,
        InstanceType: eachInstance.InstanceType,
        StateName: eachInstance.StateName,
        VpcId: eachInstance.VpcId,
      })) || [];

    if (!formattedData[accountId]) formattedData[accountId] = [];
    formattedData[accountId] = [...formattedData?.[accountId], ...processedData];
  });

  console.log({ formattedData });
  const [instanceData, setInstanceData] = useState(formattedData);
  const getActionPayload = (instanceId, accountId, action, region) => {
    return {
      concerto_user_credentials: GetLoginCredentialsAsJsonObject(), // GetLoginCredentials(),
      command_to_execute: 'set_ec2_instance_state',
      command_args: {
        aws_account_id: accountId,
        region,
        ec2_instances_ids_list: [instanceId],
        ec2_instances_action: `ec2_instance_${action}_action`,
      },
    };
  };

  const handleActionClick = async ({ instanceId, accountId, action, region }) => {
    const response = await axios.post(GetSetEc2InstanceStateUrl(), getActionPayload(instanceId, accountId, action, region));
    if (response.status === 200) {
      const filteredInstanceData = {
        ...instanceData,
        [accountId]: instanceData[accountId].map((eachInstance) => {
          console.log({ eachInstance, instanceId, isSame: eachInstance.instanceId === instanceId });
          return eachInstance.InstanceId === instanceId
            ? {
                ...eachInstance,
                StateName: action + 'ing',
              }
            : eachInstance;
        }),
      };
      setInstanceData(filteredInstanceData);
      message.success(`Action '${action}' triggered for instance '${instanceId}'`);
    } else {
      message.error(`Failed to ${action} instance '${instanceId}'`);
    }
  };

  const columns = [
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'Instance ID',
      dataIndex: 'InstanceId',
      key: 'InstanceId',
      render: (text) => <CopyIcon text={text} title={'Copy Instance Id'} />,
    },
    {
      title: 'Instance Type',
      dataIndex: 'InstanceType',
      key: 'InstanceType',
    },
    {
      title: 'State Name',
      dataIndex: 'StateName',
      key: 'StateName',
    },
    {
      title: 'VpcId',
      dataIndex: 'VpcId',
      key: 'VpcId',
      render: (text) => <CopyIcon text={text} title={'Copy Vpc Id'} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <ActionButtons
          region={record.region}
          instanceId={record.InstanceId}
          accountId={record.accountId}
          stateName={record.StateName}
          onActionClick={handleActionClick}
        />
      ),
    },
  ];

  console.log({ ni: instanceData });
  return (
    <div>
      <h2>Instances, VMs and Compute</h2>
      {ec2InstancesDataMapList?.length ? (
        <Collapse>
          {Object.entries(instanceData).map(([accountId, instances]) => (
            <Collapse.Panel
              header={
                <div className='recommendations-title-container'>
                  <div className='parent-accordion'>
                    <div className='leaf-node-icon'>{<img style={{ height: '3.2vw' }} src={aws_icon_url} alt='' />}</div>
                  </div>
                  <div>
                    <CopyIcon text={`AWS Account ID: ${accountId}`} title={'Copy Account ID'} />
                  </div>
                  <div></div>
                </div>
              }
              key={accountId}
            >
              <div style={{ marginTop: 0, position: 'relative' }} className='table-bf'>
                <Table size='small' className='ant-recommedations-table' bordered pagination={false} dataSource={instances} columns={columns} />
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        <div>No Accounts Found</div>
      )}
    </div>
  );
};

export default EC2InstanceInfo;
