import { message } from 'antd';
export const copyString = (text) => {
  navigator.clipboard.writeText(text);
  message.success('Data copied to clipboard');
};

export const parseOrReturnJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return str || {};
  }
};
